import { https } from './apiMatricula';

export default class {
    constructor() {
        this.instance = https('reports')
    }

    getReports = (data) => {
        return this.instance({
            method: 'POST',
            url: '/getPDF',            
            data
        });
    }
}