<template>
  <div>
    <Navbar />
    <div class="columns">
      <ValidationObserver ref="observer">
        <section class="container">
          <vs-row class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'laptop']" />
              <h2 class="tituloAtendimento">Sala de Aula Virtual</h2>
            </div>
          </vs-row>

          <div class="con-select-example">
            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <div v-if="courses[0]">
                    <legend>Curso <b style="color: red">*</b></legend>
                    <ValidationProvider name="Curso" rules="required">
                      <vs-select
                        v-if="courses"
                        class="selectExample"
                        placeholder="Selecione um Curso"
                        v-model="courseSelected"
                        @change="searchSubjects()"
                      >
                        <vs-select-item
                          :key="index"
                          :value="item.CODCURSO"
                          :text="'(' + item.STATUS + ') - ' + item.CURSO"
                          v-for="(item, index) in courses"
                        />
                      </vs-select>
                    </ValidationProvider>
                  </div>

                  <div v-else>
                    <div class="error-info">
                      <b>
                        <font-awesome-icon
                          style="color: red; margin-right: 3.5px"
                          :icon="['fas', 'exclamation-triangle']"
                        />
                        Nenhum curso encontrado
                      </b>
                    </div>
                  </div>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t" v-if="classrooms != null">
              <div v-if="classrooms == ''">
                <div class="error-info">
                  <b>
                    <font-awesome-icon
                      style="color: red; margin-right: 3.5px"
                      :icon="['fas', 'exclamation-triangle']"
                    />
                    Nenhuma informação a ser mostrada
                  </b>
                </div>
              </div>

              <vs-col v-else>
                <div>
                  <strong>{{ nameMatter }} - {{ initialDate }}</strong>
                  <vs-divider />
                  <p>
                    Link Classroom:
                    <a :href="linkClassroom" target="_blank">{{
                      linkClassroom
                    }}</a>
                  </p>
                  <p>
                    Link Aula Remota:
                    <a :href="linkRemoteClass" target="_blank">{{
                      linkRemoteClass
                    }}</a>
                  </p>
                </div>
              </vs-col>
            </vs-row>
          </div>
        </section>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import Student from "../../services/students";
export default {
  name: "SalaDeAulaVirtual",
  data: () => ({
    courses: [],
    courseSelected: null,
    classrooms: null,
    nameMatter: null,
    initialDate: null,
    linkClassroom: null,
    linkRemoteClass: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Student()
      .getCoursesByRA()
      .then(({ data }) => {
        this.courses = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    searchSubjects(tr) {
      this.$vs.loading({ text: "Carregando..." });
      new Student()
        .getVirtualClassroom(this.courseSelected)
        .then(({ data }) => {
          this.classrooms = Array.isArray(data) ? data : [data];

          this.nameMatter = tr.DISCIPLINA;
          this.initialDate = tr.DTINICIAL;
          this.linkClassroom = tr.LINKCLASSROOM;
          this.linkRemoteClass = tr.LINKAULAREMOTA;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
  },
};
</script>

<style scoped>
legend {
  font-size: 1.05em;
}
</style>