<template>
  <div>        
    <div class="parentx">      
      <vs-sidebar hidden-background static-position color="primary" class="sidebarx">
        <vs-sidebar-group open v-for="(item, index) in menu" :key="index" :title="item.header">
          <div class="vs-group">
            <vs-sidebar-item v-for="(submenu, indexSubMenu) in item.submenu" :key="indexSubMenu" :index="submenu.index" class="vs-item">
              <a :href="submenu.url" :target="submenu.target">
                <font-awesome-icon style="width: 18.5px; margin-right: 7.5px" :icon="submenu.icon"/>
                {{submenu.title}}
              </a>
            </vs-sidebar-item>
          </div>
        </vs-sidebar-group>
      </vs-sidebar>
    </div>     
  </div>    
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import navBarMenu from "../models/menus/menuNavBar"
import Person from "../services/person";
import {
  logoffUserAndEraseData,
  isLogged as isLoggedHelper,
} from "../helpers/storage";

export default {  
  name: "SideBar",
  data: () => ({
    menu: [],   
  }),

  mounted() {
    this.menu = new navBarMenu().getData();

    new Person()
    .getPersonalData()
    .then(({ data }) => {
      const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];
    })
    .catch(({ response }) => {
      if (response && response.data) {
        this.error = response.data;
        console.log(response);
      }
    });
  },

  methods: {
    logout() {
      logoffUserAndEraseData();
      this.isLogged = isLoggedHelper();
      this.steps = [];
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: black;
}

.progress-bar {
  width: 100%;
  height: 30px;
  margin-top: 10px;
  background-color: #EEE;
  border: 2.5px solid;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background-color: #369b0e;
  width: 0;
  transition: width 0.5s ease;
}

.progress-text {
  margin-left: 145px;
  padding-top: 5px;
  font-weight: bold;
  color: #2C2C2D;
}

.parentx {
  z-index: 3;
  margin: 0 25px 0 20px;
  width: 270px !important;
  height: 86.5%;
  background-color: white;
  padding: 10px;
  border-radius: 20px;
  border: 1.5px solid #b5b5b59a;
  box-shadow: 0.5px 5px 10px #8888889a;
  position: fixed;
  top: 83.5px;
}

.sidebarx {
  height: 100%;
}

.vs-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.vuesax-app-is-ltr .vs-sidebar-primary .vs-sidebar-item-active {
  border-right: 0px solid rgba(var(--vs-primary), 1) !important;
}

.vs-item {
  margin: -13px;
  margin-left: -35px;
}
  
.header-sidebar {
  display: flex;
  align-items: center;
  flex-direction: column;
}
  
h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.itens {
  margin: 1.5px 0 0 7.5px;
}

@media screen and (max-height: 680px) {
  .parentx {
    height: 82.5% !important;
  }
}

@media screen and (max-height: 585px) {
  .parentx {
    height: 80% !important;
  }
}

@media screen and (max-height: 490px) {
  .parentx {
    height: 77.5% !important;
  }
}
</style>