import { https } from './apiIntranet';
import { getLoginDataFromStorage } from "../helpers/storage";

export default class {
    constructor() {
        this.instance = https('portal/students')
        const { ...user } = getLoginDataFromStorage()
        this.codUser = user.coduser
    }

    // PORTAL.01
    getCoursesByRA = () => {
        return this.instance ({ 
            method: 'GET',
            url: `/courses`,
            timeout: 30000
        });
    }

    // PORTAL.09
    getMatterByRA = () => {
        return this.instance ({ 
            method: 'GET',
            url: `/matter/${this.codUser}`,
            timeout: 30000
        });
    }

    // PORTAL.13
    getVirtualClassroom = (idCourse) => {
        return this.instance ({ 
            method: 'GET',
            url: `/virtualClassroom/${idCourse}`,
            timeout: 30000
        });
    }

    // PORTAL.15
    getApprovedMatter = (idCourse) => {
        return this.instance ({ 
            method: 'GET',
            url: `/approvedMatter/${idCourse}`,
            timeout: 30000
        });
    }

    // PORTAL.35
    getDisapprovedMatter = (idCourse) => {
        return this.instance ({ 
            method: 'GET',
            url: `/disapprovedMatter/${idCourse}`,
            timeout: 30000
        });
    }

    // PORTAL.16
    getLockedMatter = (idCourse) => {
        return this.instance ({ 
            method: 'GET',
            url: `/lockedMatter/${idCourse}`,
            timeout: 30000
        });
    }

    // PORTAL.02
    getCourseHistory = (idCourse, idGrid, idLicence, idPeriod) => {
        return this.instance ({
            method: 'GET',
            url: `/courseHistory/${idCourse}/${idGrid}/${idLicence}/${idPeriod}`,
            timeout: 30000
        });
    }

    // PORTAL.33
    getDashboardHistory = (idCourse, idGrid, idLicence, idPeriod) => {
        return this.instance ({
            method: 'GET',
            url: `/dashboardHistory/${idCourse}/${idGrid}/${idLicence}/${idPeriod}`,
            timeout: 30000
        });
    }

    // PORTAL.31
    getNote = (idTurmaDisc, ra) => {
        return this.instance ({
            method: 'GET',
            url: `/note/${idTurmaDisc}/${ra}`,
            timeout: 30000
        });
    }

    // COM.WSENDFILIAL
    getPoloAddress = (coligateId, filialId) => {
        return this.instance ({
            method: 'GET',
            url: `/poloAddress/${coligateId}/${filialId}`,
            timeout: 30000
        });
    }
}