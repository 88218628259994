<template>
  <div class="nav">
    <div class="parentx">
      <div>
        <vs-button @click="active = !active" type="filled" class="button">
          <div>
            <font-awesome-icon
              style="width: 25px"
              :icon="['fas', 'bars']"
              size="2x"
            />
          </div>
        </vs-button>
      </div>

      <vs-sidebar
        hidden-background
        color="primary"
        class="sidebarx"
        spacer
        index
        v-model="active"
      >
        <vs-sidebar-group
          open
          v-for="(item, index) in menu"
          :key="index"
          :title="item.header"
          v-show="item.rule ? $acl.check(item.rule) : true"
        >
          <div class="vs-group">
            <vs-sidebar-item
              v-for="(submenu, indexSubMenu) in item.submenu"
              :key="indexSubMenu"
              :index="submenu.index"
              class="vs-item"
              v-show="submenu.rule ? $acl.check(submenu.rule) : true"
            >
              <a :href="submenu.url" :target="submenu.target">
                <font-awesome-icon
                  style="width: 18.5px; margin-right: 7.5px"
                  :icon="submenu.icon"
                />
                {{ submenu.title }}
              </a>
            </vs-sidebar-item>
          </div>
        </vs-sidebar-group>
      </vs-sidebar>
    </div>

    <div class="title">
      <a href="/home">
        <img
          style="height: 55px"
          src="../assets/qi-logo-branco-1024x717.png"
          alt="Logo QI"
        />
      </a>
    </div>

    <div style="margin-top: 8.5px; margin-right: 28.5px">
      <a
        href="https://qi.edu.br/qi-atinge-nota-maxima-em-avaliacao-do-mec/"
        target="_blank"
      >
        <img style="height: 70px" src="../assets/logo_mec.png" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import navBarMenu from "../models/menus/menuNavBar";
import Person from "../services/person";
import {
  logoffUserAndEraseData,
  isLogged as isLoggedHelper,
} from "../helpers/storage";

export default {
  name: "NavBar",
  data: () => ({
    active: false,
    menu: [],
  }),

  mounted() {
    this.menu = new navBarMenu().getData();

    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      });
  },

  methods: {
    logout() {
      logoffUserAndEraseData();
      this.isLogged = isLoggedHelper();
      this.steps = [];
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: black;
}

.progress-bar {
  width: 100%;
  height: 30px;
  margin-top: 10px;
  background-color: #eee;
  border: 2.5px solid;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background-color: #369b0e;
  width: 0;
  transition: width 0.5s ease;
}

.progress-text {
  margin-left: 145px;
  padding-top: 5px;
  font-weight: bold;
  color: #2c2c2d;
}

.parentx {
  width: 15% !important;
  display: none;
}

.sidebarx {
  z-index: 3;
  height: calc(100vh - 68.15px) !important;
  width: 265px;
  top: 68.15px;
  position: fixed;
  overflow-y: auto;
}

.nav {
  z-index: 99999;
  height: 4.25em;
  width: 100%;
  background-color: #9b0e1c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
}

.button {
  margin-left: 1em;
}

.title {
  width: 100%;
  margin-left: 20px;
}

.vs-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.vuesax-app-is-ltr .vs-sidebar-primary .vs-sidebar-item-active {
  border-right: 0px solid rgba(var(--vs-primary), 1) !important;
}

.vs-item {
  margin: -13px;
  margin-left: -28.5px;
}

.header-sidebar {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.logout {
  margin: 1.5px 0 0 7.5px;
}

@media screen and (max-width: 845px) {
  .parentx {
    display: flex;
  }

  .title {
    margin-left: -25px;
  }

  .nav {
    top: 0;
  }
}

@media screen and (max-width: 640px) {
  .title {
    margin-left: 0;
  }
}

@media screen and (max-width: 510px) {
  .title {
    margin-left: 30px;
  }
}
</style>