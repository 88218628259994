<template>
  <div>
    <Navbar />
    <div class="columns">
      <SideBar class="menu" />
      <section class="container">
        <Header class="header" />
        <div class="card-sup">
          <vs-col>
            <div class="card-info">
              <h3>
                <font-awesome-icon
                  style="margin-right: 3.5px"
                  :icon="['fas', 'newspaper']"
                />
                Acontece aQI
              </h3>
              <vs-divider />
              <TimeLine />
            </div>
          </vs-col>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import TimeLine from "../../components/TimeLine.vue";

export default {
  components: {
    TimeLine,
  },
};
</script>

<style scoped>
.card-sup {
  margin-left: 8.5px;
  margin-right: 8.5px;
  margin-bottom: 8.5px;
}
</style>
