<template>
  <div>
    <vs-row class="pd-table">
      <vs-col>
        <vs-table
          class="table_timeline"
          max-items="5"
          hoverFlat
          pagination
          :data="timeLine"
        >
          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <div
                  class="timeline-description"
                  @click="handleClickDetails(data[indextr])"
                >
                  <img
                    class="timeline-image"
                    :src="data[indextr].NOMEIMAGEM"
                    alt="Imagem da notícia"
                  />
                  <div class="text-card">
                    <span class="timeline-title">{{data[indextr].TITULO}}</span>
                    <span class="timeline-creator">
                      <font-awesome-icon :icon="['fas', 'calendar-day']"/>
                      {{ data[indextr].DATA_NOTICIA | formatDate }}
                    </span>
                    <p v-html="limitCaracter(data[indextr].TEXTO)" class="timeline-text"></p>
                  </div>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-col>
    </vs-row>

    <vs-popup
      style="z-index: 999999 !important"
      title="Acontece aQI"
      :active.sync="popupGallery"
      v-if="selectedEvent"
    >
      <vs-card outlined>
        <h3 class="title">{{ selectedEvent.TITULO }}</h3>
        <img class="image" :src="selectedEvent.NOMEIMAGEM" />
        <p v-html="selectedEvent.TEXTO" class="description"></p>
      </vs-card>
    </vs-popup>
  </div>
</template>

<script>
import Vue from "vue";
import Events from "../services/events";
import moment from "moment";

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

export default {
  name: "TimeLine",
  data: () => ({
    timeLine: [],
    error: null,
    selectedEvent: null,
    popupGallery: false,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando momentos incríveis para você 🌟" });
    new Events()
      .getTimeLinePortal()
      .then(({ data }) => {
        this.timeLine = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    limitCaracter(texto) {
      if (texto.length < 600) {
        return texto;
      } else {
        return texto.slice(0, 600) + "...";
      }
    },

    handleClickDetails(selectedEvent) {
      this.selectedEvent = selectedEvent;
      this.popupGallery = true;
    },
  },
};
</script>

<style scoped>
.table_timeline {
  cursor: pointer;
}

td {
  border: none !important;
  border-bottom: 1px solid #888888 !important;
}

tr:nth-child(even) {
  background-color: white;
}

.title {
  font-size: 1.45em;
}

h3 {
  text-align: center;
}

.image {
  margin: 5% 20%;
  height: 60%;
  width: 60%;
}

.text-card {
  width: 80%;
  margin: 1.5em;
  display: flex;
  flex-direction: column;
}

.timeline-title {
  font-weight: bold;
  font-size: 1.25em;
}

.timeline-description {
  display: flex;
}

.timeline-creator {
  margin: 0.75% 0;
  font-size: 1.15em;
  font-weight: bold;
  color: #9B0E1C;
}

.timeline-image {
  margin-left: 0;
  width: 250px;
  height: 200px;
  object-fit: contain;
}

.timeline-text {
  font-size: 0.925em;
  margin-top: 1%;
  text-align: justify;
}

@media screen and (max-width: 900px) {
  .timeline-description {
    display: grid;
    grid-template-columns: none;
  }

  .text-card {
    width: 100%;
    margin: 2.5em 0 0 0;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }

  .timeline-image {
    margin: auto;
  }

  .timeline-text {
    width: 100%;
    margin-top: 2.5%;
  }

  .timeline-title,
  .timeline-creator {
    text-align: center;
  }
}

@media screen and (max-width: 430px) {
  .image {
    margin: 5% 12.5%;
    height: 75%;
    width: 75%;
  }
}
</style>

