<template>
  <div>
    <vs-divider />
    <div class="card-sup">
      <vs-col>
        <div class="card-info">
          <h3>
            <font-awesome-icon
              style="margin-right: 3.5px"
              :icon="['fas', 'user-circle']"
            />
            Alteração de Foto
          </h3>
          <vs-divider />
          <ValidationObserver ref="observer">
            <div class="con-select-example">
              <vs-row>
                <vs-col>
                  <Message
                    :msg="idAttendance"
                    :error="errorIdAttendance"
                    :errorDuplicate="idAttendanceDuplicate"
                    v-if="
                      idAttendance || errorIdAttendance || idAttendanceDuplicate
                    "
                  />
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col>
                  <fieldset>
                    <legend>Foto de Perfil <b style="color: red">*</b></legend>
                    <div
                      style="
                        display: flex;
                        justify-content: space-around;
                        margin-bottom: 20px;
                      "
                    >
                      <img
                        :src="`data:image/png;base64,${this.personalData[0].FOTO}`"
                        class="image-detail"
                        style="border: 3.5px solid red; border-radius: 100px"
                        v-if="this.personalData[0].FOTO"
                      />
                      <img
                        src="@/assets/perfil.jpg"
                        class="image-detail"
                        style="border: 3.5px solid red; border-radius: 100px"
                        v-else
                      />
                      <font-awesome-icon
                        class="icon-image"
                        :icon="['fas', 'arrow-alt-circle-right']"
                      />
                      <img
                        :src="`data:image/png;base64,${this.base64Image}`"
                        class="image-detail"
                        style="border: 3.5px solid green; border-radius: 100px"
                        v-if="base64Image"
                      />
                      <img
                        src="@/assets/perfil.jpg"
                        class="image-detail"
                        style="border: 3.5px solid green; border-radius: 100px"
                        v-else
                      />
                    </div>
                    <fieldset>
                      <vs-col>
                        <ValidationProvider
                          name="Foto de Perfil"
                          ref="provider"
                          rules="required|image"
                        >
                          <input
                            class="selectExample"
                            ref="image"
                            type="file"
                            accept="image/*"
                            @change="onfilesUpload"
                          />
                        </ValidationProvider>
                      </vs-col>
                    </fieldset>
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col style="text-align: center">
                  <vs-button
                    class="button-protocol"
                    color="success"
                    type="gradient"
                    @click="createAttendance()"
                    :disabled="!visibleButton || idAttendanceDuplicate"
                  >
                    <font-awesome-icon
                      style="margin-right: 3.5px"
                      :icon="['fas', 'check-circle']"
                    />
                    Gerar
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
          </ValidationObserver>
        </div>
      </vs-col>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import Person from "../../../services/person";
import Management from "../../../services/management";
import ProtocolModel from "../../../models/menus/protocols/protocolTypes";
import Attendance from "../../../services/attendance";

export default {
  name: "AlteracaoDados",
  props: {
    course: Object,
    filial: String,
  },
  data: () => ({
    idAttendance: null,
    personalData: [],
    email: null,
    telephone: null,
    telephone2: null,
    base64Image: null,
    editEmail: false,
    newEmail: null,
    editPhone: false,
    newPhone: null,
    editPhone2: false,
    visibleButton: false,
    newPhone2: null,
    emailFinancialResponsible: null,
    errorIdAttendance: null,
    idAttendanceDuplicate: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando Dados Acadêmicos 🪪" });
    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];

        this.nome = this.personalData[0].NOME;
        this.ra = this.personalData[0].RA;
        this.email =
          this.personalData[0].EMAIL == null ? "-" : this.personalData[0].EMAIL;
        this.emailFinancialResponsible =
          this.personalData[0].EMAILRESPONSAVELFINANCEIRO == null
            ? "-"
            : this.personalData[0].EMAILRESPONSAVELFINANCEIRO;
        this.telephone =
          this.personalData[0].TELEFONE1 == null
            ? "-"
            : `(${this.personalData[0].TELEFONE1.slice(
                0,
                2
              )}) ${this.personalData[0].TELEFONE1.slice(2, 12)}`;
        this.telephone2 =
          this.personalData[0].TELEFONE2 == null
            ? "-"
            : `(${this.personalData[0].TELEFONE2.slice(
                0,
                2
              )}) ${this.personalData[0].TELEFONE2.slice(2, 12)}`;
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });

    new Management()
      .getProtocolInProgress()
      .then(({ data }) => {
        this.protocolInProgress = (Array.isArray(data) ? data : [data]).filter(
          (a) => a.ASSUNTOOC == "ALTERAÇÃO DE FOTO - PORTAL DO ALUNO"
        );

        if (this.protocolInProgress.length != 0) {
          this.idAttendanceDuplicate =
            this.protocolInProgress[0].CODATENDIMENTO;
          this.$vs.loading.close();
        }
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });
  },

  methods: {
    emailEdit() {
      this.editEmail = true;
      this.newEmail = null;
    },

    emailActual() {
      this.editEmail = false;
    },

    phoneEdit() {
      this.editPhone = true;
      this.newPhone = null;
    },

    phoneActual() {
      this.editPhone = false;
    },

    phone2Edit() {
      this.editPhone2 = true;
      this.newPhone2 = null;
    },

    phone2Actual() {
      this.editPhone2 = false;
    },

    lengthEmail(email) {
      if (email.length < 60) {
        return true;
      } else {
        return false;
      }
    },

    lengthPhone(phone) {
      if (phone.length == 14) {
        return true;
      } else {
        return false;
      }
    },

    async onfilesUpload(event) {
      this.visibleButton = false;
      this.base64Image = null;
      this.files = event.target.files[0];

      const { valid } = await this.$refs.provider.validate(this.files);

      if (valid) {
        this.createBase64Image(this.files);
        this.visibleButton = true;
      }
    },

    createBase64Image(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;

        this.base64Image = window.btoa(bynaryData);
      };
      reader.readAsBinaryString(file);
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      this.idAttendanceDuplicate = null;

      const studentName = this.nome;
      const studentRA = this.ra;

      /* INFORMAÇÕES EXTERNAS */
      const courseSolicitacion = this.course.CURSO;
      const filialSolicitacion = this.filial;

      const payload = new ProtocolModel().getDataByAttendanceId("P30");
      payload.data.locationId = this.course.POLO;
      payload.data.ra = studentRA;
      payload.data.attachment = this.base64Image;
      payload.data.requestText = `RA: ${studentRA}\nALUNO: ${studentName}\nE-MAIL DO RESPONSÁVEL FINANCEIRO: ${this.emailFinancialResponsible}\nCURSO: ${courseSolicitacion}\nESCOLA: ${filialSolicitacion}`;

      this.$vs.loading({ text: "Gerando Atendimento 🏷️" });
      new Management()
        .getProtocolInProgress()
        .then(({ data }) => {
          this.protocolInProgress = (
            Array.isArray(data) ? data : [data]
          ).filter((a) => a.ASSUNTOOC == payload.data.subject);

          if (this.protocolInProgress.length != 0) {
            this.idAttendanceDuplicate =
              this.protocolInProgress[0].CODATENDIMENTO;
            this.$vs.loading.close();
          } else {
            new Attendance()
              .createAttendance(payload.data)
              .then(({ data }) => {
                this.idAttendance = data;
                this.$vs.loading.close();
              })
              .catch(({ response }) => {
                if (response && response.data) {
                  this.errorIdAttendance = response.data;
                  console.log(response);
                }
                this.$vs.loading.close();
              });
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
          }
        });

      this.resetForm();
    },

    resetForm() {
      this.editEmail = false;
      this.editPhone = false;
      this.editPhone2 = false;
    },
  },
};
</script>

<style scoped>
.card-sup {
  margin: 20px 8.5px 8.5px 8.5px;
}

.error-data {
  color: red;
  font-size: 0.75em;
}

.image-detail {
  width: 200px;
  height: 200px;
}

.icon-image {
  margin-top: 65px;
  font-size: 80px;
}

.edit-email,
.edit-phone,
.edit-phone2 {
  padding-top: 5px;
  padding-left: 7.5px;
}

legend {
  font-size: 1.05em;
}

@media (max-width: 980px) {
  .image-detail {
    width: 150px;
    height: 150px;
  }

  .icon-image {
    margin-top: 45px;
    font-size: 70px;
  }
}

@media (max-width: 580px) {
  .image-detail {
    width: 120px;
    height: 120px;
  }

  .icon-image {
    margin-top: 35px;
    font-size: 60px;
  }
}

@media (max-width: 460px) {
  .image-detail {
    width: 90px;
    height: 90px;
  }

  .icon-image {
    margin-top: 20px;
    font-size: 50px;
  }
}

@media (max-width: 380px) {
  .image-detail {
    width: 70px;
    height: 70px;
  }

  .icon-image {
    margin-top: 18.5px;
    font-size: 35px;
  }
}

@media (max-width: 325px) {
  .image-detail {
    width: 60px;
    height: 60px;
  }

  .icon-image {
    margin-top: 15px;
    font-size: 30px;
  }
}
</style>