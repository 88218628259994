import { https } from './apiIntranet';

export default class {
    constructor() {
        this.instance = https('auth')
    }

    access = (data) => {
        return this.instance({
            method: 'POST',
            url: '/access',
            data,
        });
    }
}