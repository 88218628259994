<template>
  <div>
    <Navbar />
    <div class="columns">
      <SideBar class="menu" />
      <section class="container">
        <Header class="header" />
        <div class="card-sup">
          <vs-col>
            <div class="card-info">
              <h3>
                <font-awesome-icon
                  style="margin-right: 3.5px"
                  :icon="['fas', 'folder-plus']"
                />
                Solicitação
              </h3>
              <vs-divider />
              <vs-row class="pd-t">
                <vs-col>
                  <div class="alert alert-info">
                    <h3>
                      <font-awesome-icon
                        style="margin-right: 1.5px"
                        :icon="['fas', 'radiation']"
                      />
                      ATENÇÃO
                    </h3>
                    <p style="margin-top: 5px">
                      O retorno dos protocolos aqui abertos serão dados por
                      e-mail ao estudante e para o responsável pelo contrato.
                      <br />
                      Observe seus dados sempre atualizados
                      <a style="color: black" href="/perfil">clicando aqui</a>.
                    </p>
                  </div>
                  <vs-divider />
                  <fieldset v-if="courses[0]">
                    <legend>Curso <b style="color: red">*</b></legend>
                    <vs-select
                      class="selectExample"
                      v-model="courseSelected"
                      placeholder="Selecione seu Curso"
                      @change="getPolo()"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item"
                        :text="'(' + item.STATUS + ') - ' + item.CURSO"
                        v-for="(item, index) in courses"
                      />
                    </vs-select>
                  </fieldset>
                  <div v-else class="error-info">
                    <b>
                      <font-awesome-icon
                        style="color: red; margin-right: 3.5px"
                        :icon="['fas', 'exclamation-triangle']"
                      />
                      Nenhum curso encontrado
                    </b>
                  </div>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t" v-if="courseSelected">
                <vs-col>
                  <fieldset>
                    <legend>Escola</legend>
                    <vs-input
                      class="selectExample"
                      :placeholder="polo"
                      disabled
                    />
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row
                class="pd-t"
                v-if="courseSelected && courseSelected.CODMODALIDADECURSO != 6"
              >
                <vs-col>
                  <fieldset>
                    <legend>Solicitação <b style="color: red">*</b></legend>
                    <vs-select
                      class="selectExample"
                      autocomplete
                      v-model="selectedTypes"
                      placeholder="Selecione uma Solicitação"
                    >
                      <vs-select-item
                        :key="item.title"
                        :value="item.title"
                        :text="item.title"
                        v-for="item in protocol"
                      />
                    </vs-select>
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row
                class="pd-t"
                v-if="courseSelected && courseSelected.CODMODALIDADECURSO == 6"
              >
                <vs-col>
                  <fieldset>
                    <div class="error-info">
                      <b>
                        <font-awesome-icon
                          style="color: red; margin-right: 3.5px"
                          :icon="['fas', 'exclamation-triangle']"
                        />
                        Nenhuma solicitação disponível para esse curso
                      </b>
                    </div>
                  </fieldset>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </div>
        <Certificado
          v-if="selectedTypes == 'Certificado'"
          :course="courseSelected"
          :filial="polo"
        />
        <Historico
          v-if="selectedTypes == 'Histórico'"
          :course="courseSelected"
          :filial="polo"
        />
        <Diploma
          v-if="selectedTypes == 'Diploma'"
          :course="courseSelected"
          :filial="polo"
        />
        <NotaFiscal
          v-if="selectedTypes == 'Nota Fiscal'"
          :course="courseSelected"
          :filial="polo"
        />
        <TermoEstagio
          v-if="selectedTypes == 'Termo de Estágio'"
          :course="courseSelected"
          :filial="polo"
        />
        <Formatura
          v-if="selectedTypes == 'Formatura'"
          :course="courseSelected"
          :filial="polo"
        />
        <OutrasSolicitacoes
          v-if="selectedTypes == 'Outras Solicitações'"
          :course="courseSelected"
          :filial="polo"
        />
        <TransferenciaFilialPolo
          v-if="selectedTypes == 'Transferência de Filial/Polo'"
          :course="courseSelected"
          :filial="polo"
        />
        <TransferenciaTurno
          v-if="selectedTypes == 'Transferência de Turno e Turma'"
          :course="courseSelected"
          :filial="polo"
        />
        <NegociacaoParcela
          v-if="selectedTypes == 'Negociação de Parcelas em Atraso'"
          :course="courseSelected"
          :filial="polo"
        />
        <TrocaDeCurso
          v-if="selectedTypes == 'Troca de Curso'"
          :course="courseSelected"
          :filial="polo"
        />
        <Cancelamento
          v-if="selectedTypes == 'Cancelamento'"
          :course="courseSelected"
        />
        <Trancamento
          v-if="selectedTypes == 'Trancamento'"
          :course="courseSelected"
          :filial="polo"
        />
        <RetornoTrancamento
          v-if="selectedTypes == 'Retorno de Trancamento'"
          :course="courseSelected"
          :filial="polo"
        />
        <SolicitacoesNADD
          v-if="selectedTypes == 'Solicitações NADD'"
          :course="courseSelected"
          :filial="polo"
        />
        <AproveitamentoEstudos
          v-if="selectedTypes == 'Aproveitamento de Estudos'"
          :course="courseSelected"
          :filial="polo"
        />
        <VerificacaoFinanceira
          v-if="selectedTypes == 'Verificação Financeira'"
          :course="courseSelected"
          :filial="polo"
        />
        <PrazoProvasAtividades
          v-if="selectedTypes == 'Prazo de Provas e Atividades (Moodle)'"
          :course="courseSelected"
          :filial="polo"
        />
        <Ementa
          v-if="selectedTypes == 'Ementa'"
          :course="courseSelected"
          :filial="polo"
        />
        <VerificacaoDeNotas
          v-if="selectedTypes == 'Verificação de Notas'"
          :course="courseSelected"
          :filial="polo"
        />
        <TutoriaDisciplina
          v-if="selectedTypes == 'Tutoria de Disciplina'"
          :course="courseSelected"
          :filial="polo"
        />
        <Diploma2Via
          v-if="selectedTypes == 'Diploma (2ª via)'"
          :course="courseSelected"
          :filial="polo"
        />
        <AtividadeComplementar
          v-if="selectedTypes == 'Atividade Complementar'"
          :course="courseSelected"
          :filial="polo"
        />
        <AgendamentoExame
          v-if="selectedTypes == 'Agendamento de Exame'"
          :course="courseSelected"
          :filial="polo"
        />
        <Rematricula
          v-if="selectedTypes == 'Rematrícula'"
          :course="courseSelected"
          :filial="polo"
        />
        <SolicitacoesNETeP
          v-if="
            selectedTypes ==
            'NETeP - Suporte ao Ambiente de Aprendizagem (Moodle)'
          "
          :course="courseSelected"
          :filial="polo"
        />
        <AlteracaoDados
          v-if="selectedTypes == 'Alteração de Dados'"
          :course="courseSelected"
          :filial="polo"
        />
        <AlteracaoFoto
          v-if="selectedTypes == 'Alteração de Foto'"
          :course="courseSelected"
          :filial="polo"
        />
      </section>
    </div>
  </div>
</template>

<script>
import Student from "../../services/students";
import Certificado from "./formSolicitacao/Certificado.vue";
import Historico from "./formSolicitacao/Historico.vue";
import Diploma from "./formSolicitacao/Diploma.vue";
import NotaFiscal from "./formSolicitacao/NotaFiscal.vue";
import TermoEstagio from "./formSolicitacao/TermoEstagio.vue";
import Formatura from "./formSolicitacao/Formatura.vue";
import OutrasSolicitacoes from "./formSolicitacao/OutrasSolicitacoes.vue";
import TransferenciaFilialPolo from "./formSolicitacao/TransferenciaFilialPolo.vue";
import TransferenciaTurno from "./formSolicitacao/TransferenciaTurmaTurno.vue";
import NegociacaoParcela from "./formSolicitacao/NegociacaoParcelaAtraso.vue";
import TrocaDeCurso from "./formSolicitacao/TrocaDeCurso.vue";
import Cancelamento from "./formSolicitacao/Cancelamento.vue";
import Trancamento from "./formSolicitacao/Trancamento.vue";
import RetornoTrancamento from "./formSolicitacao/RetornoTrancamento.vue";
import SolicitacoesNADD from "./formSolicitacao/SolicitacoesNADD.vue";
import { searchNamePolo } from "../../helpers/storage";
import ProtocolModel from "../../models/menus/protocols/protocolTypes";
import AproveitamentoEstudos from "./formSolicitacao/AproveitamentoEstudos.vue";
import VerificacaoFinanceira from "./formSolicitacao/VerificacaoFinanceira.vue";
import PrazoProvasAtividades from "./formSolicitacao/PrazoProvasAtividades.vue";
import Ementa from "./formSolicitacao/Ementa.vue";
import VerificacaoDeNotas from "./formSolicitacao/VerificacaoDeNotas.vue";
import AgendamentoExame from "./formSolicitacao/AgendamentoExame.vue";
import TutoriaDisciplina from "./formSolicitacao/TutoriaDisciplina.vue";
import Diploma2Via from "./formSolicitacao/Diploma2Via.vue";
import AtividadeComplementar from "./formSolicitacao/AtividadeComplementar.vue";
import Rematricula from "./formSolicitacao/Rematricula.vue";
import SolicitacoesNETeP from "./formSolicitacao/SolicitacoesNETeP.vue";
import AlteracaoDados from "./formSolicitacao/AlteracaoDados.vue";
import AlteracaoFoto from "./formSolicitacao/AlteracaoFoto.vue";

export default {
  name: "Solicitacao",
  components: {
    Certificado,
    Historico,
    Diploma,
    NotaFiscal,
    TermoEstagio,
    Formatura,
    OutrasSolicitacoes,
    TransferenciaFilialPolo,
    TransferenciaTurno,
    NegociacaoParcela,
    TrocaDeCurso,
    Cancelamento,
    Trancamento,
    RetornoTrancamento,
    SolicitacoesNADD,
    VerificacaoFinanceira,
    AproveitamentoEstudos,
    PrazoProvasAtividades,
    Ementa,
    VerificacaoDeNotas,
    TutoriaDisciplina,
    Diploma2Via,
    AtividadeComplementar,
    AgendamentoExame,
    Rematricula,
    SolicitacoesNETeP,
    AlteracaoDados,
    AlteracaoFoto
  },
  data: () => ({
    selectedTypes: null,
    protocol: [],
    courses: [],
    courseSelected: null,
    error: null,
    polo: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Procurando Cursos 📚" });
    new Student()
      .getCoursesByRA()
      .then(({ data }) => {
        this.courses = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    getPolo() {
      this.protocol = new ProtocolModel()
        .getProtocolByRules(
          this.courseSelected.CODMODALIDADECURSO,
          this.courseSelected.CURSO_CONCLUIDO
        )
        .sort((a, b) => a.title.localeCompare(b.title));

      this.selectedTypes = "";
      this.polo = searchNamePolo(this.courseSelected.CODCURSO, this.courses);
    },
  },
};
</script>

<style scoped>
legend {
  font-size: 1.05em;
}

.card-sup {
  margin-left: 8.5px;
  margin-right: 8.5px;
  margin-bottom: 8.5px;
}
</style>

