<template>
  <div>
    <Navbar />
    <div class="columns">
      <SideBar class="menu" />
      <section class="container">
        <Header class="header" />
        <div class="card-sup">
          <vs-col>
            <div class="card-info">
              <h3>
                <font-awesome-icon
                  style="margin-right: 3.5px"
                  :icon="['fas', 'print']"
                />
                Extrato Financeiro
              </h3>
              <vs-divider />
              <div
                style="text-align: justify !important"
                v-if="Object.keys(groupedParcels).length"
              >
                <b style="font-size: 0.815em"
                  >*Valor sujeito à atualização de desconto adimplência, juros,
                  multa, IGPM e IPCA conforme contrato.</b
                >
                <vs-row style="margin-top: 25px">
                  <vs-col
                    v-for="(parcels, status) in groupedParcels"
                    :key="status"
                  >
                    <fieldset style="margin-bottom: 30px">
                      <legend
                        style="
                          color: black;
                          width: 100%;
                          text-align: center;
                          border: 1px solid;
                          border-radius: 50px;
                          padding: 5px;
                        "
                      >
                        {{ status.toUpperCase() }}
                      </legend>
                      <vs-table
                        :data="parcels"
                        hoverFlat
                        search
                        max-items="10"
                        pagination
                      >
                        <template slot="thead">
                          <vs-th width="15%" sort-key="DATAVENCIMENTOO"
                            >VENCIMENTO</vs-th
                          >
                          <vs-th width="40%" sort-key="CURSO">CURSO</vs-th>
                          <vs-th width="20%" sort-key="VALORLIQUIDO"
                            >LÍQUIDO NO VENCIMENTO</vs-th
                          >
                          <vs-th width="15%" sort-key="VALORBAIXADO">VALOR PAGO</vs-th>
                          <vs-th></vs-th>
                        </template>

                        <template slot-scope="{ data }">
                          <vs-tr v-for="(tr, index) in data" :key="index">
                            <vs-td>
                              <p>
                                {{ tr.DATAVENCIMENTOO }}
                              </p>
                            </vs-td>
                            <vs-td>
                              <b :class="moneyFormate(tr.STATUSLAN)">{{
                                tr.CURSO
                              }}</b>
                              <p style="margin-top: 1.5px" v-if="tr.DATABAIXAA">
                                <b>Pago em:</b> {{ tr.DATABAIXAA }}
                              </p>
                            </vs-td>
                            <vs-td>
                              <div v-if="tr.ATUALIZALIQUIDO == 'S'">
                                <div v-if="tr.financeValue != undefined">
                                  R$ {{ tr.financeValue }}
                                </div>
                                <div v-else class="spinner-container">
                                  <div class="spinner"></div>
                                </div>
                              </div>
                              <div v-else>
                                <b v-if="tr.IDBAIXA == '0'">*</b>R$
                                {{
                                  tr.IDBAIXA != "0"
                                    ? tr.VALORBAIXADO
                                    : tr.VALORLIQUIDO
                                }}
                              </div>
                            </vs-td>
                            <vs-td>{{
                              tr.VALORBAIXADO == "0.00"
                                ? "-"
                                : "R$ " + tr.VALORBAIXADO
                            }}</vs-td>
                            <vs-td>
                              <vs-button
                                class="button-table"
                                @click="handleClickDetails(tr)"
                              >
                                <font-awesome-icon :icon="['fas', 'plus']" />
                              </vs-button>
                              <vs-button
                                v-if="
                                  tr.STATUSLAN == 'Em Aberto' ||
                                  tr.STATUSLAN == 'Parcialmente Baixado' ||
                                  tr.STATUSLAN == 'Vencido'
                                "
                                class="button-print"
                                color="#139B0E"
                                target="_blank"
                                @click="payment(tr.CODCOLIGADA, tr.IDLAN)"
                              >
                                <font-awesome-icon
                                  style="margin-right: 3.5px"
                                  :icon="['fas', 'coins']"
                                />
                                Pagar
                              </vs-button>
                            </vs-td>
                          </vs-tr>
                        </template>
                      </vs-table>
                    </fieldset>
                  </vs-col>
                </vs-row>
              </div>

              <div v-else>
                <div class="error-info">
                  <b>
                    <font-awesome-icon
                      style="color: red; margin-right: 3.5px"
                      :icon="['fas', 'exclamation-triangle']"
                    />
                    Nenhuma parcela encontrada
                  </b>
                </div>
              </div>

              <vs-popup
                style="z-index: 999999"
                :title="service"
                :active.sync="popupExtract"
              >
                <div>
                  <vs-row>
                    <vs-col vs-lg="6" vs-sm="6" vs-xs="12">
                      <vs-list>
                        <vs-list-item
                          title="Responsável Financeiro"
                          :subtitle="financialResponsible"
                        />
                        <vs-list-item
                          title="Forma de Pagamento"
                          :subtitle="formPayment"
                        />
                        <vs-list-item title="Vencimento" :subtitle="dueDate" />
                        <vs-list-item
                          title="Desconto Adimplência"
                          :subtitle="deductiblesDiscount"
                        />
                        <vs-list-item
                          title="Valor Bruto"
                          :subtitle="grossValue"
                        />
                      </vs-list>
                    </vs-col>

                    <vs-col vs-lg="6" vs-sm="6" vs-xs="12">
                      <vs-list>
                        <vs-list-item
                          title="Desconto Negociação"
                          :subtitle="tradingDiscount"
                        />
                        <vs-list-item title="Multa" :subtitle="trafficTicket" />
                        <vs-list-item title="Juros" :subtitle="fees" />
                        <vs-list-item title="Bolsa" :subtitle="handbag" />
                      </vs-list>
                    </vs-col>
                  </vs-row>

                  <vs-row style="justify-content: space-around">
                    <vs-col v-if="idBaixa != '0'" vs-w="5.85" vs-xs="12">
                      <vs-button
                        class="button-print"
                        @click="printInvoice(codColigada, idLan)"
                      >
                        <font-awesome-icon
                          style="margin-right: 3.5px"
                          :icon="['fas', 'receipt']"
                        />
                        Imprimir Nota Fiscal
                      </vs-button>
                    </vs-col>
                  </vs-row>
                </div>
              </vs-popup>

              <vs-popup
                style="z-index: 999999"
                :title="'Nota Fiscal - ' + this.idLan"
                :active.sync="popupInvoice"
              >
                <div>
                  <vs-row>
                    <vs-col>
                      <vs-table :data="invoicesFinance" hoverFlat>
                        <template slot-scope="{ data }">
                          <vs-tr
                            style="background-color: white !important"
                            v-for="(tr, index) in data"
                            :key="index"
                          >
                            <vs-td style="border: none !important">
                              <div class="parcel parcel-info">
                                <h3>
                                  <font-awesome-icon
                                    style="margin-right: 1.5px"
                                    :icon="['fas', 'info-circle']"
                                  />
                                  {{ data[index].TIPO }}
                                </h3>
                                <p
                                  style="margin-top: 5px"
                                  v-html="
                                    data[index].DADOS.replaceAll('\n', '<br />')
                                  "
                                ></p>
                              </div>
                            </vs-td>
                          </vs-tr>
                        </template>
                      </vs-table>
                    </vs-col>
                    <vs-divider />
                    <b class="alert-disponible">
                      Disponível em até 48 horas após o baixa do lançamento.
                    </b>
                  </vs-row>
                </div>
              </vs-popup>
            </div>
          </vs-col>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Finance from "../../services/finance";
import Fatura from "../../services/fatura";
import Management from "../../services/management";
export default {
  name: "ExtratoFinanceiro",
  data: () => ({
    popupExtract: false,
    popupInvoice: false,
    codColigada: null,
    financialStatement: [],
    invoicesFinance: [],
    financialResponsible: null,
    formPayment: null,
    dueDate: null,
    deductiblesDiscount: null,
    service: null,
    trafficTicket: null,
    fees: null,
    paid: null,
    handbag: null,
    tradingDiscount: null,
    grossValue: null,
    idBaixa: null,
    idLan: null,
  }),

  computed: {
    groupedParcels() {
      const statusOrder = {
        Vencido: 1,
        "Em Aberto": 2,
        "Parcialmente Baixado": 3,
        Pago: 4,
        "Baixado por Acordo": 5,
      };

      const grouped = this.financialStatement.reduce((acc, fatura) => {
        const status = fatura.STATUSLAN;
        if (!acc[status]) acc[status] = [];
        acc[status].push(fatura);
        return acc;
      }, {});

      const sortedGrouped = Object.keys(grouped)
        .sort((a, b) => statusOrder[a] - statusOrder[b])
        .reduce((acc, key) => {
          acc[key] = grouped[key];
          return acc;
        }, {});

      return sortedGrouped;
    },
  },

  mounted() {
    this.$vs.loading({ text: "Carregando seus Dados Financeiros 🪙" });
    new Management()
      .getFinancialStatement()
      .then(({ data }) => {
        this.financialStatement = Array.isArray(data) ? data : [data];
        this.populateFinanceValues();
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    async populateFinanceValues() {
      for (let i = 0; i < this.financialStatement.length; i++) {
        const statement = this.financialStatement[i];
        if (statement.ATUALIZALIQUIDO == "S") {
          const hashParam = await new Finance().getHash(
            statement.CODCOLIGADA,
            statement.IDLAN
          );
          const financeValue = await this.getFinanceValue(hashParam.data.hash);
          this.$set(statement, "financeValue", financeValue);
        }
      }
    },

    async getFinanceValue(hash) {
      try {
        const { data } = await new Fatura().getValue(hash);
        return Number(data.VALOR).toFixed(2);
      } catch (error) {
        if (error.response && error.response.data) {
          this.error = error.response.data;
        }
        throw error;
      } finally {
        this.$vs.loading.close();
      }
    },

    handleClickDetails(tr) {
      this.popupExtract = true;

      this.codColigada = tr.CODCOLIGADA;

      this.financialResponsible = tr.NOMEFANTASIA;
      this.formPayment = tr.DESCFORMAPAGTO;
      this.dueDate = tr.DATAVENCIMENTOO;
      this.deductiblesDiscount = "R$ " + tr.DESCONTOADIMPLENCIA;
      this.service = tr.NOME;
      this.paid = tr.STATUSLAN;
      this.trafficTicket = "R$ " + tr.VALORMULTABX;
      this.fees = "R$ " + tr.VALORJUROSBX;
      this.handbag = "R$ " + tr.BOLSA;
      this.tradingDiscount = "R$ " + tr.DESCONTOACORDO;
      this.grossValue = "R$ " + tr.VALORBRUTO;

      this.idLan = tr.IDLAN;

      this.idBaixa = tr.IDBAIXA;
    },

    payment(coligada, lan) {
      this.$vs.loading({ text: "Encaminhando para Pagamento 🪙" });
      new Finance()
        .getHash(coligada, lan)
        .then(({ data }) => {
          setTimeout(() => {
            window.open(
              "https://faturas.qi.edu.br/?hash=" +
                data.hash +
                "&origem=portal-do-aluno",
              "_blank"
            );
            this.$vs.loading.close();
          }, 1500);
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
            console.log(response);
          }
          this.$vs.loading.close();
        });
    },

    moneyFormate(situacion) {
      switch (situacion) {
        case "Pago":
        case "Baixado por Acordo":
          return "pago";
        case "Em Aberto":
        case "Parcialmente Baixado":
          return "aberto";
        case "Vencido":
        case "Cancelado":
          return "cancelado";
      }
    },

    printInvoice(coligada, lan) {
      this.popupExtract = false;

      this.$vs.loading({ text: "Imprimindo a Nota Fiscal 📑" });
      new Management()
        .getInvoicesFinance(coligada, lan)
        .then(({ data }) => {
          this.invoicesFinance = Array.isArray(data) ? data : [data];
          this.popupInvoice = true;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
  },
};
</script>

<style scoped>
tr:nth-child(even) {
  background-color: #d4d4d4;
}

legend {
  font-size: 1.05em;
}

.late-invoice {
  border: 1px solid;
  padding: 3.5px;
  border-radius: 30px;
  color: red;
}

.alert-disponible {
  margin: auto;
}

.spinner-container {
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
  width: 100%;
}

.spinner {
  border: 2.5px solid rgba(0, 0, 0, 0.1);
  border-left-color: #9b0e1c;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.parcel-info {
  text-align: initial;
}

.card-sup {
  margin-left: 8.5px;
  margin-right: 8.5px;
  margin-bottom: 10px;
}

.pago {
  color: #008000;
}

.aberto {
  color: #ff8800;
}

.cancelado {
  color: #ff0000;
}

.button-print {
  margin-top: 10px;
  width: 100% !important;
}

@media (max-width: 600px) {
  .parcel-info > p {
    font-size: 0.85em;
  }
}

@media (max-width: 460px) {
  .parcel-info > h3 {
    font-size: 1.05em;
  }

  .parcel-info > p {
    font-size: 0.75em;
  }

  .alert-disponible {
    font-size: 0.85em;
  }
}

@media (max-width: 405px) {
  .parcel-info > p {
    font-size: 0.65em;
  }
}

@media (max-width: 385px) {
  .parcel-info > h3 {
    font-size: 0.95em;
  }

  .parcel-info {
    text-align: justify;
  }

  .alert-disponible {
    font-size: 0.75em;
  }
}

@media (max-width: 365px) {
  .parcel-info > p {
    font-size: 0.615em;
  }
}

@media (max-width: 345px) {
  .alert-disponible {
    font-size: 0.65em;
  }
}
</style>