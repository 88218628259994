<template>
  <div>
    <Navbar />
    <div class="columns">
      <SideBar class="menu" />
      <section class="container">
        <Header class="header" />
        <div class="card-sup">
          <vs-col>
            <div class="card-info">
              <h3>
                <font-awesome-icon
                  style="margin-right: 3.5px"
                  :icon="['fas', 'file-contract']"
                />
                Meus Contratos
              </h3>
              <vs-divider />
              <div v-if="contract[0]">
                <vs-row>
                  <vs-col>
                    <vs-table
                      :data="contract"
                      hoverFlat
                      search
                      max-items="5"
                      pagination
                    >
                      <template slot="thead">
                        <vs-th>DESCRIÇÃO</vs-th>
                        <vs-th width="15%">CONTRATO</vs-th>
                      </template>

                      <template slot-scope="{ data }">
                        <vs-tr v-for="(tr, index) in data" :key="index">
                          <vs-td>
                            <p>
                              {{ tr.CURSO }}
                            </p>
                            <p style="margin-top: 8.5px">
                              <font-awesome-icon
                                style="margin-right: 1.5px"
                                :icon="['fas', 'calendar-day']"
                              />
                              {{ tr.DATAASSINATURA }}
                            </p>
                            <p style="margin-top: 1.5px">
                              <font-awesome-icon
                                style="margin-right: 1.5px"
                                :icon="['fas', 'folder']"
                              />
                              {{ tr.CODCONTRATO }}
                            </p>
                          </vs-td>
                          <vs-td>
                            <vs-button
                              class="button-table"
                              @click="openLink(tr.URLCONTRATO)"
                            >
                              <font-awesome-icon
                                color="white"
                                style="margin-right: 3.5px"
                                :icon="['fas', 'arrow-alt-circle-down']"
                              />
                              .PDF
                            </vs-button>
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </vs-col>
                </vs-row>
              </div>

              <div v-else>
                <div class="error-info">
                  <b>
                    <font-awesome-icon
                      style="color: red; margin-right: 3.5px"
                      :icon="['fas', 'exclamation-triangle']"
                    />
                    Nenhum contrato encontrado
                  </b>
                </div>
              </div>
            </div>
          </vs-col>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Management from "../../services/management";
export default {
  name: "MeusContratos",
  data: () => ({
    contract: [],
  }),

  mounted() {
    this.$vs.loading({ text: "Buscando Contratos 🪙" });
    new Management()
      .getContract()
      .then(({ data }) => {
        this.contract = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    openLink(link) {
      this.$vs.loading({ text: "Gerando Contrato 📑" });
      setTimeout(() => {
        this.$vs.loading.close();
        window.open(link, "_blank");
      }, 2500);
    },
  },
};
</script>

<style scoped>
tr:nth-child(even) {
  background-color: #d4d4d4;
}

legend {
  font-size: 1.05em;
}

.late-invoice {
  border: 1px solid;
  padding: 3.5px;
  border-radius: 30px;
  color: red;
}

.alert-disponible {
  margin: auto;
}

.spinner-container {
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
  width: 100%;
}

.spinner {
  border: 2.5px solid rgba(0, 0, 0, 0.1);
  border-left-color: #9b0e1c;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.parcel-info {
  text-align: initial;
}

.card-sup {
  margin-left: 8.5px;
  margin-right: 8.5px;
  margin-bottom: 10px;
}

.pago {
  color: #008000;
}

.aberto {
  color: #ff8800;
}

.cancelado {
  color: #ff0000;
}

.button-print {
  margin-top: 10px;
  width: 100% !important;
}

@media (max-width: 600px) {
  .parcel-info > p {
    font-size: 0.85em;
  }
}

@media (max-width: 460px) {
  .parcel-info > h3 {
    font-size: 1.05em;
  }

  .parcel-info > p {
    font-size: 0.75em;
  }

  .alert-disponible {
    font-size: 0.85em;
  }
}

@media (max-width: 405px) {
  .parcel-info > p {
    font-size: 0.65em;
  }
}

@media (max-width: 385px) {
  .parcel-info > h3 {
    font-size: 0.95em;
  }

  .parcel-info {
    text-align: justify;
  }

  .alert-disponible {
    font-size: 0.75em;
  }
}

@media (max-width: 365px) {
  .parcel-info > p {
    font-size: 0.615em;
  }
}

@media (max-width: 345px) {
  .alert-disponible {
    font-size: 0.65em;
  }
}
</style>