<template>
  <div>
    <Navbar />
    <div class="columns">
      <SideBar class="menu" />
      <section class="container">
        <Header class="header" />
        <AcessoRapido class="fast-access" />

        <div class="card-sup">
          <vs-col vs-w="6.65" vs-sm="12">
            <div v-if="banner.length" class="card-info">
              <h3>
                <font-awesome-icon
                  style="margin-right: 3.5px"
                  :icon="['fas', 'bell']"
                />
                Fique Atento!
              </h3>
              <vs-divider />
              <Slider :infoBanner="banner" />
            </div>
            <div v-else class="spinner-container">
              <div class="spinner"></div>
            </div>
          </vs-col>

          <vs-col vs-w="5" vs-sm="12">
            <div class="card-info">
              <h3>
                <font-awesome-icon
                  style="margin-right: 3.5px"
                  :icon="['fas', 'medal']"
                />
                Desempenho Acadêmico
              </h3>
              <vs-divider />
              <vs-select
                v-if="courses[0]"
                style="width: 100%; margin-bottom: 7.5px"
                placeholder="Selecione um Curso"
                v-model="courseSelected"
                @change="getObject()"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="'(' + item.STATUS + ') - ' + item.CURSO"
                  v-for="(item, index) in courses"
                />
              </vs-select>
              <div v-else class="error-info">
                <b>
                  <font-awesome-icon
                    style="color: red; margin-right: 3.5px"
                    :icon="['fas', 'exclamation-triangle']"
                  />
                  Nenhum curso encontrado
                </b>
              </div>
              <pie-chart
                v-if="dashboardHistory"
                style="height: 320px"
                :data="dashboardHistory"
              >
              </pie-chart>
              <pie-chart v-else style="height: 320px" :data="[['-', 10]]">
              </pie-chart>
            </div>
          </vs-col>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import Events from "../services/events";
import Student from "../services/students";

Vue.use(Chartkick.use(Chart));

export default {
  name: "Home",
  data: () => ({
    banner: [],
    courses: [],
    dashboardHistory: null,
    courseSelected: null,
  }),

  mounted() {
    new Events()
      .getBanner()
      .then(({ data }) => {
        this.banner = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      });

    new Student()
      .getCoursesByRA()
      .then(({ data }) => {
        this.courses = Array.isArray(data) ? data : [data];

        this.courseSelected = this.courses[0];
        this.getObject();
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      });
  },

  methods: {
    getObject() {
      this.idCourse = this.courseSelected.CODCURSO;
      this.idGrid = this.courseSelected.CODGRADE;
      this.idLicence = this.courseSelected.CODHABILITACAO;
      this.idPeriod = this.courseSelected.CODPERIODO;

      this.$vs.loading({ text: "Buscando Desempenho Acadêmico 🎓" });
      new Student()
        .getDashboardHistory(
          this.idCourse,
          this.idGrid,
          this.idLicence,
          this.idPeriod
        )
        .then(({ data }) => {
          const items = Array.isArray(data) ? data : [data];

          this.dashboardHistory = items.map((item) => [
            item.CLASSIFICACAO || "-",
            item.QTD || 10,
          ]);

          Chartkick.options = {
            colors: items.map((item) => {
              if (item.CLASSIFICACAO === "CURSADA") return "#008000";
              if (item.CLASSIFICACAO === "PENDENTE") return "#C92127";
              if (item.CLASSIFICACAO === "EQUIVALENTE") return "#22B14C";
              return "#007EB5";
            }),
          };
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
  },
};
</script>

<style scoped>
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.spinner {
  border: 2.5px solid rgba(0, 0, 0, 0.1);
  border-left-color: #9b0e1c;
  margin-top: 23.5%;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.error-info > b {
  font-size: 1em;
}

.card-info {
  height: 100%;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

b {
  font-size: 1.2em;
}

@media screen and (max-width: 1030px) {
  .card-info > h3 {
    font-size: 1em;
  }
}

@media screen and (max-width: 960px) {
  .card-info > h3 {
    font-size: 0.85em;
  }

  .error-info > b {
    font-size: 0.85em;
  }
}

@media screen and (max-width: 900px) {
  .card-sup {
    display: block;
    margin-left: 8.5px;
    margin-right: 8.5px;
    margin-bottom: 8.5px;
    justify-content: center;
  }

  .spinner {
    margin-top: 15%;
    margin-bottom: 100px;
  }

  .card-info {
    margin-bottom: 20px;
  }

  .card-info > h3 {
    font-size: 1.15em;
  }
}

@media screen and (max-width: 790px) {
  .columns {
    grid-template-columns: none;
  }

  .menu {
    display: none;
  }
}

@media screen and (max-width: 430px) {
  .spinner {
    margin-top: 25%;
    margin-bottom: 100px;
  }
}
</style>
  