import './assets/css/global.css'
import './assets/css/globalCssForms.css'

import Vue from 'vue'
import App from './App.vue'
import Vuesax from 'vuesax'
import router from './router'
import acl from "./acl/acl"
import VueMask from 'v-mask'


import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


import 'vuesax/dist/vuesax.css'
import 'material-design-icons/iconfont/material-icons.css'

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import pt from "vee-validate/dist/locale/pt_BR.json";
import * as rules from "vee-validate/dist/rules";

import NavBar from "./components/NavBar.vue";
import SideBar from "./components/SideBar.vue";
import Header from "./components/Header.vue";
import AcessoRapido from "./components/AcessoRapido.vue";

import Slider from "./components/Slider.vue";

import Message from "./components/Message.vue";
import Message2 from "./components/Message2.vue";
import Message3 from "./components/Message3.vue";
import Message4 from "./components/Message4.vue";

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("pt", pt);

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("Navbar", NavBar);
Vue.component("SideBar", SideBar);
Vue.component("Header", Header);
Vue.component("AcessoRapido", AcessoRapido);
Vue.component("Slider", Slider);
Vue.component("Message", Message);
Vue.component("Message2", Message2);
Vue.component("Message3", Message3);
Vue.component("Message4", Message4);

library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(Vuesax, {
  theme: {
    colors: {
      primary: '#9B0E1C',
    } 
  }
})

Vue.use(VueMask);

Vue.config.productionTip = false
export const bus = new Vue()

new Vue({
  router,
  acl,
  render: h => h(App),
}).$mount('#app')