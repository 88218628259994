<template>
  <div>
    <vs-divider />
    <div class="card-sup">
      <vs-col>
        <div class="card-info">
          <h3>
            <font-awesome-icon
              style="margin-right: 3.5px"
              :icon="['fas', 'handshake']"
            />
            Termo de Estágio
          </h3>
          <vs-divider />
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <div class="con-select-example">
              <vs-row>
                <vs-col>
                  <Message3
                    :msg="idAttendance"
                    :error="errorIdAttendance"
                    :errorDuplicate="idAttendanceDuplicate"
                    v-if="
                      idAttendance || errorIdAttendance || idAttendanceDuplicate
                    "
                  />
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                  <ValidationProvider name="Nome da Empresa" rules="required">
                    <fieldset>
                      <legend>
                        Nome da Empresa <b style="color: red">*</b>
                      </legend>
                      <vs-input
                        class="selectExampleDouble"
                        placeholder="Digite o Nome da Empresa"
                        v-model="companyName"
                      />
                    </fieldset>
                  </ValidationProvider>
                </vs-col>

                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                  <ValidationProvider
                    name="CNPJ da Empresa"
                    rules="required|min:18"
                  >
                    <fieldset>
                      <legend>
                        CNPJ da Empresa <b style="color: red">*</b>
                      </legend>
                      <vs-input
                        class="selectExampleDouble"
                        placeholder="Digite o CNPJ da Empresa"
                        v-model="companyCNPJ"
                        v-mask="'##.###.###/####-##'"
                      />
                    </fieldset>
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                  <ValidationProvider name="Função" rules="required">
                    <fieldset>
                      <legend>Função <b style="color: red">*</b></legend>
                      <vs-input
                        class="selectExampleDouble"
                        placeholder="Digite a Função"
                        v-model="occupation"
                      />
                    </fieldset>
                  </ValidationProvider>
                </vs-col>

                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                  <ValidationProvider
                    name="Nome da Integradora"
                    rules="required"
                  >
                    <fieldset>
                      <legend>
                        Nome da Integradora <b style="color: red">*</b>
                      </legend>
                      <vs-input
                        class="selectExampleDouble"
                        placeholder="Digite o Nome da Integradora"
                        v-model="integratorName"
                      />
                    </fieldset>
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                  <ValidationProvider name="Início Estágio" rules="required">
                    <fieldset>
                      <legend>
                        Início do Estágio <b style="color: red">*</b>
                      </legend>
                      <vs-input
                        class="selectExampleDouble"
                        type="date"
                        v-model="startInternship"
                      />
                    </fieldset>
                  </ValidationProvider>
                </vs-col>

                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                  <ValidationProvider name="Término Estágio" rules="required">
                    <fieldset>
                      <legend>
                        Término do Estágio <b style="color: red">*</b>
                      </legend>
                      <vs-input
                        class="selectExampleDouble"
                        type="date"
                        v-model="endInternship"
                      />
                    </fieldset>
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-row
                v-if="
                  startInternship &&
                  endInternship &&
                  endInternship <= startInternship
                "
              >
                <div class="error-info" style="margin-bottom: 15px">
                  <b>
                    <font-awesome-icon
                      style="color: red; margin-right: 3.5px"
                      :icon="['fas', 'exclamation-triangle']"
                    />
                    O término do estágio deve ser após o início
                  </b>
                </div>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                  <ValidationProvider
                    name="Responsável Direto"
                    rules="required"
                  >
                    <fieldset>
                      <legend>
                        Responsável Direto <b style="color: red">*</b>
                      </legend>
                      <vs-input
                        class="selectExampleDouble"
                        placeholder="Digite o Responsável Direto"
                        v-model="directResponsible"
                      />
                    </fieldset>
                  </ValidationProvider>
                </vs-col>

                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                  <ValidationProvider
                    name="Email Responsável Direto"
                    rules="required"
                  >
                    <fieldset>
                      <legend>
                        Email do Resp. Direto <b style="color: red">*</b>
                      </legend>
                      <vs-input
                        class="selectExampleDouble"
                        placeholder="Digite o Email do Responsável Direto"
                        v-model="directResponsibleEmail"
                      />
                    </fieldset>
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col vs-w="12">
                  <fieldset>
                    <legend>Endereço da Empresa</legend>
                    <vs-col
                      style="margin-top: 5px"
                      vs-w="3"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <legend class="legendBancario">
                        CEP <b style="color: red">*</b>
                      </legend>
                      <ValidationProvider name="CEP" rules="required|min:9">
                        <vs-input
                          v-mask="'#####-###'"
                          v-model="companyAddressCep"
                          placeholder="Digite o CEP"
                          class="selectExampleDouble"
                        />
                      </ValidationProvider>
                      <vs-divider class="divider" />
                    </vs-col>

                    <vs-col
                      style="margin-top: 5px"
                      vs-w="7"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <legend class="legendBancario">
                        Endereço <b style="color: red">*</b>
                      </legend>
                      <ValidationProvider name="Endereço" rules="required">
                        <vs-input
                          v-model="companyAddress"
                          placeholder="Digite o Endereço"
                          class="selectExampleDouble"
                        />
                      </ValidationProvider>
                    </vs-col>

                    <vs-col
                      style="margin-top: 5px"
                      vs-w="2"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <legend class="legendBancario">
                        Número <b style="color: red">*</b>
                      </legend>
                      <ValidationProvider name="Número" rules="required">
                        <vs-input
                          type="number"
                          v-model="companyAddressNumber"
                          placeholder="Digite o Número"
                          class="selectExampleDouble"
                        />
                      </ValidationProvider>
                    </vs-col>

                    <vs-col style="margin-top: 10px" vs-w="6" vs-xs="12">
                      <legend class="legendBancario">
                        Estado (UF) <b style="color: red">*</b>
                      </legend>
                      <ValidationProvider name="Estado" rules="required">
                        <vs-select
                          class="selectExampleDouble"
                          autocomplete
                          placeholder="Selecione o Estado (UF)"
                          v-model="companyAddressState"
                          @change="getCity(companyAddressState)"
                        >
                          <vs-select-item
                            :key="index"
                            :value="item.value"
                            :text="item.state"
                            v-for="(item, index) in stateAll"
                          />
                        </vs-select>
                      </ValidationProvider>
                    </vs-col>

                    <vs-col style="margin-top: 10px" vs-w="6" vs-xs="12">
                      <legend class="legendBancario">
                        Cidade <b style="color: red">*</b>
                      </legend>
                      <ValidationProvider name="Cidade" rules="required">
                        <vs-select
                          class="selectExampleDouble"
                          autocomplete
                          :disabled="!companyAddressState"
                          placeholder="Selecione a Cidade"
                          v-model="companyAddressCity"
                        >
                          <vs-select-item
                            :key="index"
                            :value="item.NOME"
                            :text="item.NOME"
                            v-for="(item, index) in cities"
                          />
                        </vs-select>
                      </ValidationProvider>
                    </vs-col>
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col>
                  <ValidationProvider
                    name="Telefone da Empresa"
                    rules="required"
                  >
                    <fieldset>
                      <legend>
                        Telefone da Empresa <b style="color: red">*</b>
                      </legend>
                      <vs-input
                        class="selectExample"
                        type="number"
                        placeholder="Digite o Telefone da Empresa"
                        v-model="companyPhone"
                      />
                    </fieldset>
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col style="text-align: center">
                  <vs-button
                    color="danger"
                    type="gradient"
                    class="button-protocol"
                    @click="resetForm()"
                    :disabled="
                      companyName == null &&
                      companyCNPJ == null &&
                      occupation == null &&
                      directResponsible == null &&
                      !startInternship &&
                      !endInternship &&
                      integratorName == null &&
                      directResponsibleEmail == null &&
                      companyAddress == null &&
                      companyAddressNumber == null &&
                      companyAddressCep == null &&
                      companyAddressCity == null &&
                      companyAddressState == null &&
                      companyPhone == null
                    "
                  >
                    <font-awesome-icon
                      style="margin-right: 3.5px"
                      :icon="['fas', 'times-circle']"
                    />
                    Cancelar
                  </vs-button>

                  <vs-button
                    class="button-protocol"
                    color="success"
                    type="gradient"
                    @click="createAttendance()"
                    :disabled="
                      invalid ||
                      endInternship < startInternship ||
                      idAttendanceDuplicate
                    "
                  >
                    <font-awesome-icon
                      style="margin-right: 3.5px"
                      :icon="['fas', 'check-circle']"
                    />
                    Gerar
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
          </ValidationObserver>
        </div>
      </vs-col>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import Person from "../../../services/person";
import Management from "../../../services/management";
import ProtocolModel from "../../../models/menus/protocols/protocolTypes";
import { convertDate } from "../../../helpers/storage.js";
import Attendance from "../../../services/attendance";

export default {
  name: "TermoEstagio",
  props: {
    course: Object,
    filial: String,
  },
  data: () => ({
    idAttendance: null,
    errorIdAttendance: null,
    idAttendanceDuplicate: null,
    companyName: null,
    companyCNPJ: null,
    occupation: null,
    startInternship: null,
    endInternship: null,
    directResponsible: null,
    integratorName: null,
    directResponsibleEmail: null,
    companyAddress: null,
    companyAddressNumber: null,
    companyAddressCep: null,
    companyAddressCity: null,
    companyAddressState: null,
    stateAll: [
      { state: "Acre (AC)", value: "AC" },
      { state: "Alagoas (AL)", value: "AL" },
      { state: "Amapá (AP)", value: "AP" },
      { state: "Amazonas (AM)", value: "AM" },
      { state: "Bahia (BA)", value: "BA" },
      { state: "Ceará (CE)", value: "CE" },
      { state: "Distrito Federal (DF)", value: "DF" },
      { state: "Espírito Santo (ES)", value: "ES" },
      { state: "Goiás (GO)", value: "GO" },
      { state: "Maranhão (MA)", value: "MA" },
      { state: "Mato Grosso (MT)", value: "MT" },
      { state: "Mato Grosso do Sul (MS)", value: "MS" },
      { state: "Minas Gerais (MG)", value: "MG" },
      { state: "Pará (PA)", value: "PA" },
      { state: "Paraíba (PB)", value: "PB" },
      { state: "Paraná (PR)", value: "PR" },
      { state: "Pernambuco (PE)", value: "PE" },
      { state: "Piauí (PI)", value: "PI" },
      { state: "Rio de Janeiro (RJ)", value: "RJ" },
      { state: "Rio Grande do Norte (RN)", value: "RN" },
      { state: "Rio Grande do Sul (RS)", value: "RS" },
      { state: "Rondônia (RO)", value: "RO" },
      { state: "Roraima (RR)", value: "RR" },
      { state: "Santa Catarina (SC)", value: "SC" },
      { state: "São Paulo (SP)", value: "SP" },
      { state: "Sergipe (SE)", value: "SE" },
      { state: "Tocantins (TO)", value: "TO" },
    ],
    cities: [],
    companyPhone: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando Dados Acadêmicos 🪪" });
    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];
        
        this.nome = this.personalData[0].NOME;
        this.ra = this.personalData[0].RA;
        this.email =
          this.personalData[0].EMAIL == null ? "-" : this.personalData[0].EMAIL;
        this.emailFinancialResponsible =
          this.personalData[0].EMAILRESPONSAVELFINANCEIRO == null
            ? "-"
            : this.personalData[0].EMAILRESPONSAVELFINANCEIRO;
        this.telephone =
          this.personalData[0].TELEFONE1 == null
            ? "-"
            : `(${this.personalData[0].TELEFONE1.slice(
                0,
                2
              )}) ${this.personalData[0].TELEFONE1.slice(2, 12)}`;
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });

    new Management()
      .getProtocolInProgress()
      .then(({ data }) => {
        this.protocolInProgress = (Array.isArray(data) ? data : [data]).filter(
          (a) =>
            a.ASSUNTOOC == "TERMO DE ESTÁGIO - PORTAL DO ALUNO" &&
            a.CURSO == `(${this.course.CODCURSO}) ${this.course.CURSO}`
        );

        if (this.protocolInProgress.length != 0) {
          this.idAttendanceDuplicate =
            this.protocolInProgress[0].CODATENDIMENTO;
          this.$vs.loading.close();
        }
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });
  },

  methods: {
    getCity(state) {
      new Management()
        .getCities(state)
        .then(({ data }) => {
          this.cities = Array.isArray(data) ? data : [data];
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
            console.log(response);
          }
        });
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      this.idAttendanceDuplicate = null;

      const studentName = this.nome;
      const studentRA = this.ra;

      /* INFORMAÇÕES EXTERNAS */
      const courseInternship = this.course.CURSO;
      const filialInternship = this.filial;

      const payload = new ProtocolModel().getDataByAttendanceId("P7");
      payload.data.locationId = this.course.POLO;
      payload.data.ra = studentRA;
      payload.data.companyName = this.companyName;
      payload.data.nameFunction = this.occupation;
      payload.data.startDate = convertDate(this.startInternship);
      payload.data.endDate = convertDate(this.endInternship);
      payload.data.nameResponsible = this.directResponsible;
      payload.data.integratorName = this.integratorName;
      payload.data.responsibleEmail = this.directResponsibleEmail;
      payload.data.companyPhone = this.companyPhone;
      payload.data.nameCourse = `(${this.course.CODCURSO}) ${this.course.CURSO}`;
      payload.data.requestText = `RA: ${studentRA}\nALUNO: ${studentName}\nE-MAIL DO ALUNO: ${
        this.email
      }\nE-MAIL DO RESPONSÁVEL FINANCEIRO: ${
        this.emailFinancialResponsible
      }\nTELEFONE: ${
        this.telephone
      }\nCURSO: ${courseInternship}\nESCOLA: ${filialInternship}\n\nNOME DA INTEGRADORA: ${
        this.integratorName
      }\nFUNÇÃO: ${this.occupation}\nDURAÇÃO: ${
        convertDate(this.startInternship) +
        " - " +
        convertDate(this.endInternship)
      }\n\n------------- INFORMAÇÕES DA EMPRESA -------------\nNOME: ${
        this.companyName
      }\nTELEFONE: ${this.companyPhone}\nENDEREÇO: ${
        "(" +
        this.companyAddressCep +
        ") " +
        this.companyAddress +
        ", " +
        this.companyAddressNumber +
        " - " +
        this.companyAddressCity +
        "/" +
        this.companyAddressState
      }\nCNPJ: ${this.companyCNPJ}\n\nRESPONSÁVEL DIRETO: ${
        this.directResponsible
      }\nE-MAIL DO RESPONSÁVEL DIRETO: ${this.directResponsibleEmail}`;

      this.$vs.loading({ text: "Gerando Atendimento 🏷️" });
      new Management()
        .getProtocolInProgress()
        .then(({ data }) => {
          this.protocolInProgress = (
            Array.isArray(data) ? data : [data]
          ).filter(
            (a) =>
              a.ASSUNTOOC == payload.data.subject &&
              a.CURSO == payload.data.nameCourse
          );

          if (this.protocolInProgress.length != 0) {
            this.idAttendanceDuplicate =
              this.protocolInProgress[0].CODATENDIMENTO;
            this.$vs.loading.close();
          } else {
            new Attendance()
              .createAttendance(payload.data)
              .then(({ data }) => {
                this.idAttendance = data;
                this.$vs.loading.close();
              })
              .catch(({ response }) => {
                if (response && response.data) {
                  this.errorIdAttendance = response.data;
                  console.log(response);
                }
                this.$vs.loading.close();
              });
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
          }
        });

      this.resetForm();
    },

    resetForm() {
      this.companyName = null;
      this.companyCNPJ = null;
      this.occupation = null;
      this.startInternship = null;
      this.endInternship = null;
      this.directResponsible = null;
      this.integratorName = null;
      this.directResponsibleEmail = null;
      this.companyAddress = null;
      this.companyAddressNumber = null;
      this.companyAddressCep = null;
      this.companyAddressCity = null;
      this.companyAddressState = null;
      this.companyPhone = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>

<style scoped>
.card-sup {
  margin: 20px 8.5px 8.5px 8.5px;
}

.divider {
  display: none;
}

legend {
  font-size: 1.05em;
}

.legendBancario {
  padding-left: 0.25em;
}

@media screen and (max-width: 600px) {
  .divider {
    display: block;
    margin-bottom: 7.5px;
  }
}
</style>