import { https } from './apiIntranet';

export default class {
    constructor() {
        this.instance = https('portal/events')
    }

    getTopPortal = () => {
        return this.instance({
            method: 'GET',
            url: '/top',
            timeout: 30000           
        });
    }

    getTimeLinePortal = () => {
        return this.instance({
            method: 'GET',
            url: `/timeline`,
            timeout: 30000           
        });
    }

    // PORTAL.32
    getBanner = () => {
        return this.instance({
            method: 'GET',
            url: '/banner',
            timeout: 30000           
        });
    }
}