<template>
  <div>
    <vs-divider />
    <div class="card-sup">
      <vs-col>
        <div class="card-info">
          <h3>
            <font-awesome-icon
              style="margin-right: 3.5px"
              :icon="['fas', 'receipt']"
            />
            Nota Fiscal
          </h3>
          <vs-divider />
          <ValidationObserver ref="observer">
            <div class="con-select-example">
              <vs-row>
                <vs-col>
                  <Message
                    :msg="idAttendance"
                    :error="errorIdAttendance"
                    :errorDuplicate="idAttendanceDuplicate"
                    v-if="
                      idAttendance || errorIdAttendance || idAttendanceDuplicate
                    "
                  />
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col>
                  <fieldset>
                    <legend>Email do Resp. Financeiro</legend>
                    <vs-input
                      class="selectExampleDouble"
                      :placeholder="emailFinancialResponsible"
                      disabled
                    />
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col>
                  <ValidationProvider name="Datas" rules="required">
                    <fieldset>
                      <legend>
                        Faturas Pagas ({{ this.year }})
                        <b style="color: red">*</b>
                      </legend>
                      <div class="parcel parcel-info">
                        <div
                          style="padding: 5px"
                          v-if="installmentsPayable == ''"
                        >
                          <span
                            style="
                              border: none !important;
                              background-color: #e8f6fc;
                            "
                          >
                            <font-awesome-icon
                              style="color: red; margin-right: 6.5px"
                              :icon="['fas', 'times']"
                            />
                            <b>Nenhuma fatura encontrada</b>
                          </span>
                        </div>
                        <div v-else>
                          <span
                            v-for="(i, index) in installmentsPayable"
                            :key="index"
                            class="search"
                          >
                            <input
                              type="checkbox"
                              class="checkboxTurn"
                              v-model="selectedMonths"
                              :value="
                                titleCase(installmentsPayable[index].MES) +
                                ' (' +
                                installmentsPayable[index].IDLAN +
                                ')'
                              "
                            />
                            {{ titleCase(installmentsPayable[index].MES) }}
                            - R${{
                              installmentsPayable[index].VALOR_LIQUIDO * 1
                            }}
                          </span>
                        </div>
                      </div>
                    </fieldset>
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col style="text-align: center">
                  <vs-button
                    class="button-protocol"
                    color="success"
                    type="gradient"
                    @click="createAttendance()"
                    :disabled="selectedMonths == '' || idAttendanceDuplicate"
                  >
                    <font-awesome-icon
                      style="margin-right: 3.5px"
                      :icon="['fas', 'check-circle']"
                    />
                    Gerar
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
          </ValidationObserver>
        </div>
      </vs-col>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import Management from "../../../services/management";
import Person from "../../../services/person";
import ProtocolModel from "../../../models/menus/protocols/protocolTypes";
import Attendance from "../../../services/attendance";

export default {
  name: "NotaFiscal",
  props: {
    course: Object,
    filial: String,
  },
  data: () => ({
    idAttendance: null,
    errorIdAttendance: null,
    idAttendanceDuplicate: null,
    installmentsPayable: [],
    personalData: [],
    selectedMonths: [],
    year: null,
    emailFinancialResponsible: null,
  }),

  mounted() {
    this.date = new Date();
    this.year = this.date.getFullYear();

    this.$vs.loading({ text: "Carregando seus Dados Financeiros 🪙" });
    new Management()
      .getInstallmentsPayable(this.course.CODCURSO)
      .then(({ data }) => {
        this.installmentsPayable = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });

    new Management()
      .getProtocolInProgress()
      .then(({ data }) => {
        this.protocolInProgress = (Array.isArray(data) ? data : [data]).filter(
          (a) =>
            a.ASSUNTOOC == "NOTA FISCAL - PORTAL DO ALUNO" &&
            a.CURSO == `(${this.course.CODCURSO}) ${this.course.CURSO}`
        );

        if (this.protocolInProgress.length != 0) {
          this.idAttendanceDuplicate =
            this.protocolInProgress[0].CODATENDIMENTO;
          this.$vs.loading.close();
        }
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });

    this.$vs.loading({ text: "Carregando seus Dados Financeiros 🪙" });
    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];
        
        this.nome = this.personalData[0].NOME;
        this.ra = this.personalData[0].RA;
        this.email =
          this.personalData[0].EMAIL == null ? "-" : this.personalData[0].EMAIL;
        this.emailFinancialResponsible =
          this.personalData[0].EMAILRESPONSAVELFINANCEIRO == null
            ? "-"
            : this.personalData[0].EMAILRESPONSAVELFINANCEIRO;
        this.telephone =
          this.personalData[0].TELEFONE1 == null
            ? "-"
            : `(${this.personalData[0].TELEFONE1.slice(
                0,
                2
              )}) ${this.personalData[0].TELEFONE1.slice(2, 12)}`;
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    titleCase(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((word) => {
          return word[0].toUpperCase() + word.slice(1);
        })
        .join(" ");
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      this.idAttendanceDuplicate = null;

      const studentName = this.nome;
      const studentRA = this.ra;

      /* INFORMAÇÕES EXTERNAS */
      const courseInvoice = this.course.CURSO;
      const filialInvoice = this.filial;

      const payload = new ProtocolModel().getDataByAttendanceId("P5");
      payload.data.locationId = this.course.POLO;
      payload.data.ra = studentRA;
      payload.data.requestText = `RA: ${studentRA}\nALUNO: ${studentName}\nE-MAIL DO ALUNO: ${this.email}\nE-MAIL DO RESPONSÁVEL FINANCEIRO: ${this.emailFinancialResponsible}\nTELEFONE: ${this.telephone}\nCURSO: ${courseInvoice}\nESCOLA: ${filialInvoice}\nPARCELAS (${this.year}): ${this.selectedMonths}`;

      this.$vs.loading({ text: "Gerando Atendimento 🏷️" });
      new Management()
        .getProtocolInProgress()
        .then(({ data }) => {
          this.protocolInProgress = (
            Array.isArray(data) ? data : [data]
          ).filter(
            (a) =>
              a.ASSUNTOOC == payload.data.subject &&
              a.CURSO == payload.data.nameCourse
          );

          if (this.protocolInProgress.length != 0) {
            this.idAttendanceDuplicate =
              this.protocolInProgress[0].CODATENDIMENTO;
            this.$vs.loading.close();
          } else {
            new Attendance()
              .createAttendance(payload.data)
              .then(({ data }) => {
                this.idAttendance = data;
                this.$vs.loading.close();
              })
              .catch(({ response }) => {
                if (response && response.data) {
                  this.errorIdAttendance = response.data;
                  console.log(response);
                }
                this.$vs.loading.close();
              });
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
          }
        });

      this.selectedMonths = [];
    },
  },
};
</script>

<style scoped>
.card-sup {
  margin: 20px 8.5px 8.5px 8.5px;
}

legend {
  font-size: 1.05em;
}

.boxTurn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>