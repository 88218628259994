<template>
  <div>
    <vs-divider />
    <div class="card-sup">
      <vs-col>
        <div class="card-info">
          <h3>
            <font-awesome-icon
              style="margin-right: 3.5px"
              :icon="['fas', 'sync-alt']"
            />
            Troca de Curso
          </h3>
          <vs-divider />
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <div class="con-select-example">
              <vs-row>
                <vs-col>
                  <Message
                    :msg="idAttendance"
                    :error="errorIdAttendance"
                    :errorDuplicate="idAttendanceDuplicate"
                    v-if="
                      idAttendance || errorIdAttendance || idAttendanceDuplicate
                    "
                  />
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                  <ValidationProvider name="Curso" rules="required">
                    <fieldset>
                      <legend>
                        Curso Desejado <b style="color: red">*</b>
                      </legend>
                      <vs-select
                        autocomplete
                        class="selectExample"
                        v-model="courseSubstitute"
                        placeholder="Selecione o Curso Desejado"
                      >
                        <vs-select-item
                          :key="item.CODCURSO"
                          :value="item.NOME"
                          :text="item.NOME"
                          v-for="item in removeDuplicates(courses, 'CURSO')"
                        />
                      </vs-select>
                    </fieldset>
                  </ValidationProvider>
                </vs-col>

                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend>Telefone</legend>
                    <vs-input
                      class="selectExampleDouble"
                      :placeholder="telephone"
                      disabled
                    />
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row>
                <vs-col>
                  <ValidationProvider name="Motivo" rules="required|max: 500">
                    <vs-textarea
                      rows="11"
                      label="Descreva o motivo *"
                      class="textArea"
                      height="15em"
                      counter="500"
                      v-model="solicitation"
                    />
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t" v-if="courseSubstitute == course.CURSO">
                <b class="error-info">
                  <font-awesome-icon
                    style="color: red; margin-right: 3.5px"
                    :icon="['fas', 'exclamation-triangle']"
                  />
                  O curso desejado precisa ser diferente do curso atual
                </b>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col style="text-align: center">
                  <vs-button
                    color="danger"
                    type="gradient"
                    class="button-protocol"
                    @click="resetForm()"
                    :disabled="courseSubstitute == null && solicitation == null"
                  >
                    <font-awesome-icon
                      style="margin-right: 3.5px"
                      :icon="['fas', 'times-circle']"
                    />
                    Cancelar
                  </vs-button>

                  <vs-button
                    class="button-protocol"
                    color="success"
                    type="gradient"
                    @click="createAttendance()"
                    :disabled="
                      invalid ||
                      courseSubstitute == course.CURSO ||
                      idAttendanceDuplicate
                    "
                  >
                    <font-awesome-icon
                      style="margin-right: 3.5px"
                      :icon="['fas', 'check-circle']"
                    />
                    Gerar
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
          </ValidationObserver>
        </div>
      </vs-col>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import Person from "../../../services/person";
import Management from "../../../services/management";
import Units from "../../../services/units";
import ProtocolModel from "../../../models/menus/protocols/protocolTypes";
import Attendance from "../../../services/attendance";

export default {
  name: "TrocaDeCurso",
  props: {
    course: Object,
    filial: String,
  },
  data: () => ({
    idAttendance: null,
    errorIdAttendance: null,
    idAttendanceDuplicate: null,
    personalData: [],
    courseSubstitute: null,
    courses: [],
    filialSelected: null,
    telephone: null,
    solicitation: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando Dados Acadêmicos 🪪" });
    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];
        
        this.nome = this.personalData[0].NOME;
        this.ra = this.personalData[0].RA;
        this.email =
          this.personalData[0].EMAIL == null ? "-" : this.personalData[0].EMAIL;
        this.emailFinancialResponsible =
          this.personalData[0].EMAILRESPONSAVELFINANCEIRO == null
            ? "-"
            : this.personalData[0].EMAILRESPONSAVELFINANCEIRO;
        this.telephone =
          this.personalData[0].TELEFONE1 == null
            ? "-"
            : `(${this.personalData[0].TELEFONE1.slice(
                0,
                2
              )}) ${this.personalData[0].TELEFONE1.slice(2, 12)}`;
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });

    new Management()
      .getProtocolInProgress()
      .then(({ data }) => {
        this.protocolInProgress = (Array.isArray(data) ? data : [data]).filter(
          (a) =>
            a.ASSUNTOOC == "TROCA DE CURSO - PORTAL DO ALUNO" &&
            a.CURSO == `(${this.course.CODCURSO}) ${this.course.CURSO}`
        );

        if (this.protocolInProgress.length != 0) {
          this.idAttendanceDuplicate =
            this.protocolInProgress[0].CODATENDIMENTO;
          this.$vs.loading.close();
        }
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });

    this.$vs.loading({ text: "Carregando Dados Acadêmicos 🪪" });
    new Units()
      .getAllCourses()
      .then(({ data }) => {
        this.courses = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    removeDuplicates(items, campo) {
      let noDuplicates = [];
      for (let i = 0; i < items.length; i++) {
        let duplicate = false;
        for (let j = 0; j < noDuplicates.length; j++) {
          if (campo == "CURSO" && items[i].NOME == noDuplicates[j].NOME) {
            duplicate = true;
          }
        }

        if (!duplicate) {
          noDuplicates.push(items[i]);
        }
      }

      noDuplicates.sort((a, b) => a.NOME.localeCompare(b.NOME));

      return noDuplicates;
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      this.idAttendanceDuplicate = null;

      const studentName = this.nome;
      const studentRA = this.ra;

      /* INFORMAÇÕES EXTERNAS */
      const courseDesired = this.course.CURSO;
      const filialDesired = this.filial;

      const payload = new ProtocolModel().getDataByAttendanceId("P14");
      payload.data.locationId = this.course.POLO;
      payload.data.ra = studentRA;
      payload.data.nameCourse = `(${this.course.CODCURSO}) ${this.course.CURSO}`;
      payload.data.requestText = `RA: ${studentRA}\nALUNO: ${studentName}\nE-MAIL DO ALUNO: ${this.email}\nE-MAIL DO RESPONSÁVEL FINANCEIRO: ${this.emailFinancialResponsible}\nTELEFONE: ${this.telephone}\nCURSO ATUAL: ${courseDesired}\nCURSO DESEJADO: ${this.courseSubstitute}\nESCOLA: ${filialDesired}\nSOLICITAÇÃO: ${this.solicitation}`;

      this.$vs.loading({ text: "Gerando Atendimento 🏷️" });
      new Management()
        .getProtocolInProgress()
        .then(({ data }) => {
          this.protocolInProgress = (
            Array.isArray(data) ? data : [data]
          ).filter(
            (a) =>
              a.ASSUNTOOC == payload.data.subject &&
              a.CURSO == payload.data.nameCourse
          );

          if (this.protocolInProgress.length != 0) {
            this.idAttendanceDuplicate =
              this.protocolInProgress[0].CODATENDIMENTO;
            this.$vs.loading.close();
          } else {
            new Attendance()
              .createAttendance(payload.data)
              .then(({ data }) => {
                this.idAttendance = data;
                this.$vs.loading.close();
              })
              .catch(({ response }) => {
                if (response && response.data) {
                  this.errorIdAttendance = response.data;
                  console.log(response);
                }
                this.$vs.loading.close();
              });
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
          }
        });

      this.resetForm();
    },

    resetForm() {
      this.courseSubstitute = null;
      this.solicitation = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>

<style scoped>
.card-sup {
  margin: 20px 8.5px 8.5px 8.5px;
}

legend {
  font-size: 1.05em;
}
</style>