<template>
  <div>
    <vs-divider />
    <div class="card-sup">
      <vs-col>
        <div class="card-info">
          <h3>
            <font-awesome-icon
              style="margin-right: 3.5px"
              :icon="['fas', 'history']"
            />
            Histórico
          </h3>
          <vs-divider />
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <div class="con-select-example">
              <vs-row>
                <vs-col>
                  <Message
                    :msg="idAttendance"
                    :error="errorIdAttendance"
                    :errorDuplicate="idAttendanceDuplicate"
                    v-if="idAttendance || errorIdAttendance || idAttendanceDuplicate"
                  />
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col
                  :vs-lg="emailFinancialResponsible != email ? 6 : 12"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <fieldset>
                    <legend>Email do Aluno</legend>
                    <vs-input
                      class="selectExampleDouble"
                      :placeholder="email"
                      disabled
                    />
                  </fieldset>
                </vs-col>

                <vs-col
                  v-if="emailFinancialResponsible != email"
                  class="pd-c"
                  vs-lg="6"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <fieldset>
                    <legend>Email do Resp. Financeiro</legend>
                    <vs-input
                      class="selectExampleDouble"
                      :placeholder="emailFinancialResponsible"
                      disabled
                    />
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col>
                  <fieldset>
                    <legend>Disciplinas Aprovadas</legend>
                    <div class="discipline discipline-info">
                      <vs-table :data="subjects" hoverFlat>
                        <template slot-scope="{ data }">
                          <vs-tr v-for="(tr, index) in data" :key="index">
                            <vs-td style="padding: 5px; border: none !important; background-color: #e8f6fc;">
                              <font-awesome-icon v-if="data[index].DISCIPLINA == null" style="color: red; margin-right: 3.5px;" :icon="['fas', 'times']" />
                              <font-awesome-icon v-else style="color: green; margin-right: 3.5px;" :icon="['fas', 'check-circle']" />
                              <b>
                                {{
                                  data[index].DISCIPLINA == null
                                  ? "Nenhuma disciplina aprovada"
                                  : data[index].DISCIPLINA
                                }}
                              </b>
                            </vs-td>
                          </vs-tr>
                        </template>
                      </vs-table>
                    </div>
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col style="text-align: center">
                  <vs-button
                    v-if="subjects == '' || idAttendanceDuplicate"
                    class="button-protocol"
                    color="success"
                    type="gradient"
                    disabled
                  >
                    <font-awesome-icon style="margin-right: 3.5px;" :icon="['fas', 'check-circle']" />
                    Gerar
                  </vs-button>

                  <vs-button
                    v-else
                    class="button-protocol"
                    color="success"
                    type="gradient"
                    @click="createAttendance()"
                    :disabled="invalid"
                  >
                    <font-awesome-icon style="margin-right: 3.5px;" :icon="['fas', 'check-circle']" />
                    Gerar
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
          </ValidationObserver>
        </div>
      </vs-col>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import Student from "../../../services/students";
import Person from "../../../services/person";
import Management from "../../../services/management";
import ProtocolModel from "../../../models/menus/protocols/protocolTypes";
import Attendance from "../../../services/attendance";

export default {
  name: "Historico",
  props: {
    course: Object,
    filial: String,
  },
  data: () => ({
    idAttendance: null,
    errorIdAttendance: null,
    idAttendanceDuplicate: null,
    personalData: [],
    subjects: [],
    email: null,
    emailFinancialResponsible: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando Dados Acadêmicos 🪪" });
    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];
        this.nome = this.personalData[0].NOME;
        this.ra = this.personalData[0].RA;
        this.email =
          this.personalData[0].EMAIL == null
            ? "-"
            : this.personalData[0].EMAIL;
        this.emailFinancialResponsible =
          this.personalData[0].EMAILRESPONSAVELFINANCEIRO == null
            ? "-"
            : this.personalData[0].EMAILRESPONSAVELFINANCEIRO;
        this.telephone =
          this.personalData[0].TELEFONE1 == null
            ? "-"
            : `(${this.personalData[0].TELEFONE1.slice(
                0,
                2
              )}) ${this.personalData[0].TELEFONE1.slice(2, 12)}`;
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });

      new Management()
      .getProtocolInProgress()
      .then(({ data }) => {
        this.protocolInProgress = (Array.isArray(data) ? data : [data]).filter(
          (a) =>
            a.ASSUNTOOC == "HISTÓRICO - PORTAL DO ALUNO" &&
            a.CURSO == `(${this.course.CODCURSO}) ${this.course.CURSO}`
        );

        if (this.protocolInProgress.length != 0) {
          this.idAttendanceDuplicate =
            this.protocolInProgress[0].CODATENDIMENTO;
          this.$vs.loading.close();
        }
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });

    this.$vs.loading({ text: "Carregando Dados Acadêmicos 🪪" });
    new Student()
      .getApprovedMatter(this.course.CODCURSO)
      .then(({ data }) => {
        this.subjects = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      this.idAttendanceDuplicate = null;

      const studentName = this.nome;
      const studentRA = this.ra;

      /* INFORMAÇÕES EXTERNAS */
      const courseHistoric = this.course.CURSO;
      const filialHistoric = this.filial;

      const payload = new ProtocolModel().getDataByAttendanceId("P3-1");
      payload.data.locationId = this.course.POLO;
      payload.data.ra = studentRA;
      payload.data.nameCourse = `(${this.course.CODCURSO}) ${this.course.CURSO}`;
      payload.data.requestText = `RA: ${studentRA}\nALUNO: ${studentName}\nE-MAIL DO ALUNO: ${this.email}\nE-MAIL DO RESPONSÁVEL FINANCEIRO: ${this.emailFinancialResponsible}\nTELEFONE: ${this.telephone}\nCURSO: ${courseHistoric}\nESCOLA: ${filialHistoric}`;

      if (
        this.course.CODMODALIDADECURSO == 3 ||
        this.course.CODMODALIDADECURSO == 5
      ) {
        payload.data.respAttendantId = "4288";
      } else {
        payload.data.respAttendantId = "4330";
      }
      
      this.$vs.loading({ text: "Gerando Atendimento 🏷️" });
      new Management()
        .getProtocolInProgress()
        .then(({ data }) => {
          this.protocolInProgress = (
            Array.isArray(data) ? data : [data]
          ).filter(
            (a) =>
              a.ASSUNTOOC == payload.data.subject &&
              a.CURSO == payload.data.nameCourse
          );

          if (this.protocolInProgress.length != 0) {
            this.idAttendanceDuplicate =
              this.protocolInProgress[0].CODATENDIMENTO;
            this.$vs.loading.close();
          } else {
            new Attendance()
              .createAttendance(payload.data)
              .then(({ data }) => {
                this.idAttendance = data;
                this.$vs.loading.close();
              })
              .catch(({ response }) => {
                if (response && response.data) {
                  this.errorIdAttendance = response.data;
                  console.log(response);
                }
                this.$vs.loading.close();
              });
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
          }
        });
    },
  },
};
</script>

<style scoped>
.card-sup {
  margin: 20px 8.5px 8.5px 8.5px;
}

legend {
  font-size: 1.05em;
}
</style>