import { https } from "./apiIntranet";

export default class {
    constructor() {
        this.instance = https('portal/units')
    }

    // PORTAL.30
    getPoles = () => {
        return this.instance({
            method: 'GET',
            url: '/poles',
            timeout: 30000
        })
    }
}