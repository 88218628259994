import { https } from "./apiIntranet";

export default class {
    constructor() {
        this.instance = https('units')
    }

    // INTRANET.49
    getAllCourses = () => {
        return this.instance({
            method: 'GET',
            url: '/allCourses',
            timeout: 30000
        })
    }

    // INTRANET.11
    getUnits = () => {
        return this.instance({
            method: 'GET',
            url: '/',
            timeout: 30000
        });
    }
}