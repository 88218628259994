import { https } from './apiIntranet';

export default class {
    constructor() {
        this.instance = https('email')
    }

    documentation = (data) => {
        return this.instance ({ 
            method: 'POST',
            url: `/documentation`,
            data
        });
    }

    temporaryPassword = (data) => {
        return this.instance({
            method: 'POST',
            url: '/temporaryPassword',
            data         
        });
    }
}