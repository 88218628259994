<template>
  <div>
    <vs-divider />
    <div class="card-sup">
      <vs-col>
        <div class="card-info">
          <h3>
            <font-awesome-icon
              style="margin-right: 3.5px"
              :icon="['fas', 'ban']"
            />
            Agendamento de Exame
          </h3>
          <vs-divider />
          <ValidationObserver ref="observer">
            <div class="con-select-example">
              <vs-row>
                <vs-col>
                  <Message
                    :msg="idAttendance"
                    :error="errorIdAttendance"
                    :errorDuplicate="idAttendanceDuplicate"
                    v-if="
                      idAttendance || errorIdAttendance || idAttendanceDuplicate
                    "
                  />
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col
                  :vs-lg="emailFinancialResponsible != email ? 6 : 12"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <fieldset>
                    <legend>Email do Aluno</legend>
                    <vs-input
                      class="selectExampleDouble"
                      :placeholder="email"
                      disabled
                    />
                  </fieldset>
                </vs-col>

                <vs-col
                  v-if="emailFinancialResponsible != email"
                  class="pd-c"
                  vs-lg="6"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <fieldset>
                    <legend>Email do Resp. Financeiro</legend>
                    <vs-input
                      class="selectExampleDouble"
                      :placeholder="emailFinancialResponsible"
                      disabled
                    />
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col>
                  <ValidationProvider
                    name="Disciplinas Reprovadas"
                    rules="required"
                  >
                    <fieldset>
                      <legend>
                        Disciplinas Reprovadas <b style="color: red">*</b>
                      </legend>
                      <div class="discipline discipline-info">
                        <div
                          style="padding: 5px"
                          v-if="mattersDisapproved == ''"
                        >
                          <span
                            style="
                              border: none !important;
                              background-color: #e8f6fc;
                            "
                          >
                            <font-awesome-icon
                              style="color: red; margin-right: 6.5px"
                              :icon="['fas', 'times']"
                            />
                            <b>Nenhuma disciplina reprovada</b>
                          </span>
                        </div>
                        <div v-else>
                          <b>Selecione até duas disciplinas reprovadas:</b>
                          <span
                            style="margin-top: 7.5px"
                            v-for="(i, index) in mattersDisapproved"
                            :key="index"
                            class="search"
                          >
                            <input
                              type="checkbox"
                              class="checkboxTurn"
                              v-model="selectedDisciplines"
                              :value="i.DISCIPLINA"
                              :disabled="
                                selectedDisciplines.length >= 2 &&
                                !selectedDisciplines.includes(i.DISCIPLINA)
                              "
                            />
                            {{ i.DISCIPLINA }}
                          </span>
                        </div>
                      </div>
                    </fieldset>
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend>Forma de Pagamento</legend>
                    <vs-input
                      class="selectExample"
                      placeholder="Boleto"
                      disabled
                    />
                  </fieldset>
                </vs-col>

                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend>
                      Data de Vencimento <b style="color: red">*</b>
                    </legend>
                    <vs-input
                      class="selectExampleDouble"
                      type="date"
                      v-model="dueDate"
                      :min="minDate"
                    />
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col>
                  <div class="alert alert-info">
                    <h3>
                      <font-awesome-icon
                        style="margin-right: 1.5px"
                        :icon="['fas', 'radiation']"
                      />
                      ATENÇÃO
                    </h3>
                    <p style="margin-top: 5px">
                      Para realização do exame, é cobrada uma taxa de
                      <b>R$200,00</b> por disciplina.
                    </p>
                  </div>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col style="text-align: center">
                  <vs-button
                    color="danger"
                    type="gradient"
                    class="button-protocol"
                    @click="resetForm()"
                    :disabled="selectedDisciplines == '' && !dueDate"
                  >
                    <font-awesome-icon
                      style="margin-right: 3.5px"
                      :icon="['fas', 'times-circle']"
                    />
                    Cancelar
                  </vs-button>

                  <vs-button
                    class="button-protocol"
                    color="success"
                    type="gradient"
                    @click="createAttendance()"
                    :disabled="
                      selectedDisciplines == '' ||
                      selectedDisciplines.length > 2 ||
                      !dueDate ||
                      idAttendanceDuplicate
                    "
                  >
                    <font-awesome-icon
                      style="margin-right: 3.5px"
                      :icon="['fas', 'check-circle']"
                    />
                    Gerar
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
          </ValidationObserver>
        </div>
      </vs-col>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import Person from "../../../services/person";
import Management from "../../../services/management";
import Student from "../../../services/students";
import ProtocolModel from "../../../models/menus/protocols/protocolTypes";
import { convertDate } from "../../../helpers/storage.js";
import Attendance from "../../../services/attendance";

export default {
  name: "AgendamentoExame",
  props: {
    course: Object,
    filial: String,
  },
  data: () => ({
    idAttendance: null,
    errorIdAttendance: null,
    idAttendanceDuplicate: null,
    selectedDisciplines: [],
    mattersDisapproved: [],
    dueDate: null,
    minDate: null,
    personalData: [],
    email: null,
    emailFinancialResponsible: null,
  }),

  mounted() {
    this.minDate = new Date().toISOString().split("T")[0];

    this.$vs.loading({ text: "Carregando Dados Acadêmicos 🪪" });
    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];
        
        this.nome = this.personalData[0].NOME;
        this.ra = this.personalData[0].RA;
        this.email =
          this.personalData[0].EMAIL == null ? "-" : this.personalData[0].EMAIL;
        this.emailFinancialResponsible =
          this.personalData[0].EMAILRESPONSAVELFINANCEIRO == null
            ? "-"
            : this.personalData[0].EMAILRESPONSAVELFINANCEIRO;
        this.telephone =
          this.personalData[0].TELEFONE1 == null
            ? "-"
            : `(${this.personalData[0].TELEFONE1.slice(
                0,
                2
              )}) ${this.personalData[0].TELEFONE1.slice(2, 12)}`;
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });

    new Management()
      .getProtocolInProgress()
      .then(({ data }) => {
        this.protocolInProgress = (Array.isArray(data) ? data : [data]).filter(
          (a) =>
            a.ASSUNTOOC == "AGENDAMENTO DE EXAME - PORTAL DO ALUNO" &&
            a.CURSO == `(${this.course.CODCURSO}) ${this.course.CURSO}`
        );

        if (this.protocolInProgress.length != 0) {
          this.idAttendanceDuplicate =
            this.protocolInProgress[0].CODATENDIMENTO;
          this.$vs.loading.close();
        }
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });

    this.$vs.loading({ text: "Carregando Dados Acadêmicos 🪪" });
    new Student()
      .getDisapprovedMatter(this.course.CODCURSO)
      .then(({ data }) => {
        this.mattersDisapproved = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      this.idAttendanceDuplicate = null;

      const studentName = this.nome;
      const studentRA = this.ra;

      /* INFORMAÇÕES EXTERNAS */
      const courseHistoric = this.course.CURSO;
      const filialHistoric = this.filial;

      const payload = new ProtocolModel().getDataByAttendanceId("P26");
      payload.data.locationId = this.course.POLO;
      payload.data.ra = studentRA;
      payload.data.nameCourse = `(${this.course.CODCURSO}) ${this.course.CURSO}`;
      payload.data.requestText = `RA: ${studentRA}\nALUNO: ${studentName}\nE-MAIL DO ALUNO: ${
        this.email
      }\nE-MAIL DO RESPONSÁVEL FINANCEIRO: ${
        this.emailFinancialResponsible
      }\nTELEFONE: ${
        this.telephone
      }\nCURSO: ${courseHistoric}\nESCOLA: ${filialHistoric}\n\nDISCIPLINA(S) REPROVADA(S): ${
        this.selectedDisciplines
      }\nFORMA DE PAGAMENTO: Boleto\nDATA DE VENCIMENTO: ${convertDate(
        this.dueDate
      )}`;

      this.$vs.loading({ text: "Gerando Atendimento 🏷️" });
      new Management()
        .getProtocolInProgress()
        .then(({ data }) => {
          this.protocolInProgress = (
            Array.isArray(data) ? data : [data]
          ).filter(
            (a) =>
              a.ASSUNTOOC == payload.data.subject &&
              a.CURSO == payload.data.nameCourse
          );

          if (this.protocolInProgress.length != 0) {
            this.idAttendanceDuplicate =
              this.protocolInProgress[0].CODATENDIMENTO;
            this.$vs.loading.close();
          } else {
            new Attendance()
              .createAttendance(payload.data)
              .then(({ data }) => {
                this.idAttendance = data;
                this.$vs.loading.close();
              })
              .catch(({ response }) => {
                if (response && response.data) {
                  this.errorIdAttendance = response.data;
                  console.log(response);
                }
                this.$vs.loading.close();
              });
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
          }
        });

      this.resetForm();
    },

    resetForm() {
      this.selectedDisciplines = [];
      this.dueDate = null;
    },
  },
};
</script>

<style scoped>
.card-sup {
  margin: 20px 8.5px 8.5px 8.5px;
}

legend {
  font-size: 1.05em;
}
</style>
