import { https } from './apiIntranet';
export default class {
    constructor() {
        this.instance = https('portal/person')
    }

    // PORTAL.11
    getBirthday = (subjectId) => {
        return this.instance ({ 
            method: 'GET',
            url: `/birthday/${subjectId}`,
            timeout: 30000
        });
    }

    // PORTAL.05
    getPersonalData = () => {
        return this.instance ({ 
            method: 'GET',
            url: ``,
            timeout: 30000
        });
    }

    // PORTAL.05
    regainAccess = (user) => {
        return this.instance ({ 
            method: 'GET',
            url: `/${user}/access`,
            timeout: 30000
        });
    }
}