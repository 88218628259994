import { https } from './apiIntranet'

export default class {
  constructor() {
    this.instance = https('auth')
  }

  password = (data) => {
    return this.instance({
      method: 'POST',
      url: '/password',
      data,
    })
  }
}
