import { https } from './apiHash'

export default class {
  constructor() {
    this.instance = https('financeiro')
  }

  getHash = (colligate, idlan) => {
    return this.instance({
      method: 'GET',
      url: `/gerarhash/${colligate};${idlan}`,
      timeout: 30000,
    })
  }
}
