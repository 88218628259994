<template>
  <div>
    <Navbar />
    <div class="columns">
      <SideBar class="menu" />
      <section class="container">
        <Header class="header" />
        <div class="card-sup">
          <vs-col>
            <div class="card-info">
              <h3>
                <font-awesome-icon
                  style="margin-right: 3.5px"
                  :icon="['fas', 'calendar']"
                />
                Agendamento de Prova
              </h3>
              <vs-divider />
              <vs-row v-if="schedulings[0] != ''">
                <vs-card
                  style="box-shadow: 0px 0px 2px 1px #888888; padding: 5px"
                  v-for="(schedule, index) in schedulings"
                  :key="index"
                >
                  <div>
                    <h2>
                      {{ schedule.DISCIPLINA }}
                    </h2>

                    <p style="font-size: 1.15em; margin-top: 6.5px">
                      <font-awesome-icon :icon="['fas', 'calendar-day']" />
                      {{ schedule.DTINICIAL }} a {{ schedule.DTFINAL }}
                    </p>

                    <vs-divider />

                    <div>
                      <h3>Agendamento de Prova</h3>

                      <fieldset
                        style="margin-top: 10px"
                        v-if="schedule.AGENDADOPROVA == '1'"
                      >
                        <div>
                          <p style="margin-bottom: 5px">
                            {{ schedule.HORARIOPROVA }}
                            <br />
                            {{ schedule.ENDERECOPROVA }}
                            <br />
                            Sala: {{ schedule.SALAPROVA }}
                          </p>

                          <a
                            href="#"
                            @click="cancelScheduling(schedule, 1)"
                            v-if="schedule.PODECANCELARPROVA == '1'"
                          >
                            <font-awesome-icon
                              :icon="['fas', 'times-circle']"
                            />
                            Cancelar Agendamento
                          </a>
                        </div>
                      </fieldset>

                      <div v-else>
                        <vs-button
                          class="button-scheduling"
                          @click="openPopup(schedule, 9)"
                          type="gradient"
                        >
                          Agendar Prova
                        </vs-button>
                      </div>

                      <h3 style="margin-top: 15px">
                        Agendamento de Substituição
                      </h3>

                      <fieldset
                        style="margin-top: 10px"
                        v-if="schedule.AGENDADOSUBSTITUICAO == '1'"
                      >
                        <div>
                          <p style="margin-bottom: 5px">
                            {{ schedule.HORARIOSUB }}
                            <br />
                            {{ schedule.ENDERECOSUB }}
                            <br />
                            Sala: {{ schedule.SALASUB }}
                          </p>

                          <a
                            href="#"
                            @click="cancelScheduling(schedule, 2)"
                            v-if="schedule.PODECANCELARSUB == '1'"
                          >
                            <font-awesome-icon
                              :icon="['fas', 'times-circle']"
                            />
                            Cancelar Agendamento
                          </a>
                        </div>
                      </fieldset>

                      <div v-else>
                        <vs-button
                          class="button-scheduling"
                          @click="openPopup(schedule, 10)"
                          type="gradient"
                        >
                          Agendar Substituição
                        </vs-button>
                      </div>

                      <!--<h3 style="margin-top: 15px">Agendamento de Exame</h3>

                      <fieldset
                        style="margin-top: 10px"
                        v-if="schedule.AGENDADOEXAME == '1'"
                      >
                        <div>
                          <p style="margin-bottom: 5px">
                            {{ schedule.HORARIOEXAME }}
                            <br />
                            {{ schedule.ENDERECOEXAME }}
                            <br />
                            Sala: {{ schedule.SALAEXAME }}
                          </p>

                          <a
                            href="#"
                            @click="cancelScheduling(schedule, 3)"
                            v-if="schedule.PODECANCELAREXAME == '1'"
                          >
                            <font-awesome-icon :icon="['fas', 'times-circle']" />
                            Cancelar Agendamento
                          </a>
                        </div>
                      </fieldset>

                      <div v-else>
                        <vs-button
                          class="button-scheduling"
                          @click="openPopup(schedule, 11)"
                          type="gradient"
                        >
                          Agendar Exame
                        </vs-button>
                      </div>-->
                    </div>
                  </div>

                  <vs-popup :title="testSchedule" :active.sync="open">
                    <vs-row>
                      <vs-col v-if="hasOptions">
                        <fieldset>
                          <vs-select
                            class="selectTime"
                            v-model="optionProvaSelected"
                            v-if="scheduleSelected"
                            placeholder="Selecione o horário da avaliação"
                          >
                            <vs-select-item
                              v-for="(item, index) in scheduleSelected.options"
                              :key="index"
                              :value="item"
                              :text="item.HORARIO"
                            />
                          </vs-select>

                          <div style="text-align: center">
                            <vs-button
                              style="margin-top: 2.5%"
                              @click="chooseScheduling()"
                              type="gradient"
                              :disabled="!optionProvaSelected"
                            >
                              <font-awesome-icon
                                :icon="['fas', 'calendar-day']"
                              />
                              Agendar
                            </vs-button>
                          </div>
                        </fieldset>
                      </vs-col>

                      <vs-col v-else>
                        <div class="error-info">
                          <b>
                            <font-awesome-icon
                              style="color: red; margin-right: 3.5px"
                              :icon="['fas', 'exclamation-triangle']"
                            />
                            Sem horários disponíveis para agendamento
                          </b>
                        </div>
                      </vs-col>
                    </vs-row>
                  </vs-popup>
                </vs-card>
              </vs-row>

              <vs-row v-else>
                <div class="error-info">
                  <b>
                    <font-awesome-icon
                      style="color: red; margin-right: 3.5px"
                      :icon="['fas', 'exclamation-triangle']"
                    />
                    Nenhuma disciplina encontrada
                  </b>
                </div>
              </vs-row>
            </div>
          </vs-col>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import Person from "../../services/person";
import Scheduling from "../../services/scheduling";

export default {
  name: "AgendamentoProvasEAD",
  data: () => ({
    schedulings: [],
    personalData: [],
    error: null,
    testSchedule: null,
    scheduleSelected: null,
    optionProvaSelected: null,
    hasOptions: false,
    open: false,
  }),

  mounted() {
    this.$vs.loading({ text: "Procurando Agendamento 📆" });
    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];

        this.getSchedulings(this.personalData[0].RA);
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });
  },

  methods: {
    async getSchedulings(ra) {
      this.schedulings = [];

      await new Scheduling()
        .getSchedulings(ra)
        .then(({ data }) => {
          this.schedulings = Array.isArray(data) ? data : [data];
        })
        .catch(({ error }) => {
          this.error = error;
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    async openPopup(schedule, type) {
      this.optionProvaSelected = null;
      this.scheduleSelected = schedule;
      this.$vs.loading({ text: "Agendando Avaliação 🧾" });

      await new Scheduling()
        .getSchedulingOptions(
          schedule.IDHABILITACAOFILIAL,
          schedule.CODCAMPUS,
          type,
          schedule.IDTURMADISC
        )
        .then(({ data }) => {
          switch (type) {
            case 9:
              this.testSchedule = "Agendamento de Prova";
              break;
            case 10:
              this.testSchedule = "Agendamento de Substituição";
              break;
            default:
              this.testSchedule = "Agendamento de Exame";
              break;
          }
          this.scheduleSelected.options = data;
          if (this.scheduleSelected.options.length > 0) {
            this.hasOptions = true;
          } else {
            this.hasOptions = false;
          }
          this.$vs.loading.close();
        })
        .catch(({ error }) => {
          this.error = error;
          this.$vs.loading.close();
        });

      this.open = true;
    },

    async cancelScheduling(scheduling, type) {
      let payload = {};
      switch (type) {
        case 1:
          payload = {
            colligateId: scheduling.CODCOLIGADA,
            idAtividade: scheduling.IDATIVIDADEPROVA,
            idAtividadeSub: "",
            idAtividadeExame: "",
            idTurmaDisc: scheduling.IDTURMADISC,
            idOferta: scheduling.IDOFERTAPROVA,
            idOfertaSub: "",
            idOfertaExam: "",
            ra: scheduling.RA,
          };
          break;
        case 2:
          payload = {
            colligateId: scheduling.CODCOLIGADA,
            idAtividade: "",
            idAtividadeSub: scheduling.IDATIVIDADESUB,
            idAtividadeExame: "",
            idTurmaDisc: scheduling.IDTURMADISC,
            idOferta: "",
            idOfertaSub: scheduling.IDOFERTASUB,
            idOfertaExam: "",
            ra: scheduling.RA,
          };
          break;
        default:
          payload = {
            colligateId: scheduling.CODCOLIGADA,
            idAtividade: "",
            idAtividadeSub: "",
            idAtividadeExame: scheduling.IDATIVIDADEEXAME,
            idTurmaDisc: scheduling.IDTURMADISC,
            idOferta: "",
            idOfertaSub: "",
            idOfertaExam: scheduling.IDOFERTAEXAME,
            ra: scheduling.RA,
          };
          break;
      }

      this.$vs.loading({ text: "Cancelando Avaliação ❌" });

      await new Scheduling()
        .unsetScheduling(payload)
        .then(({ data }) => {
          this.success = data;
          this.$vs.loading.close();
        })
        .catch(({ error }) => {
          this.error = error;
          this.$vs.loading.close();
        });

      await this.getSchedulings(this.personalData[0].RA);
    },

    async chooseScheduling() {
      this.open = false;
      let scheduling = this.optionProvaSelected;
      const payload = {
        colligateId: scheduling.CODCOLIGADA,
        idHabilitacaoFilial: this.scheduleSelected.IDHABILITACAOFILIAL,
        ra: this.scheduleSelected.RA,
        idTurmaDisc: this.scheduleSelected.IDTURMADISC,
        idOferta: scheduling.CODMODALIDADE == "9" ? scheduling.IDOFERTA : "",
        idOfertaSub:
          scheduling.CODMODALIDADE == "10" ? scheduling.IDOFERTA : "",
        idOfertaExam:
          scheduling.CODMODALIDADE == "11" ? scheduling.IDOFERTA : "",
        idPerLet: this.scheduleSelected.IDPERLET,
        codComponente: scheduling.CODCOMPONENTE,
        codModalidade: scheduling.CODMODALIDADE,
        dtInicial: this.scheduleSelected.DTINICIAL,
        dtFinal: scheduling.DTFINAL,
        codFilial: scheduling.CODFILIAL,
        codPredio: scheduling.CODPREDIO,
        codBloco: scheduling.CODBLOCO,
        codSala: scheduling.SALA,
      };

      await new Scheduling()
        .setScheduling(payload)
        .then(({ data }) => {
          console.log(data);
        })
        .catch(({ error }) => {
          this.error = error;
        });

      await this.getSchedulings(this.personalData[0].RA);
    },
  },
};
</script>

<style scoped>
.card-sup {
  margin-left: 8.5px;
  margin-right: 8.5px;
  margin-bottom: 8.5px;
}

.button-scheduling {
  margin: 10px 5px 5px 5px;
}

.selectTime {
  width: 100%;
}
</style>