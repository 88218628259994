import Vue from "vue";
import VueRouter from "vue-router";

import Login from "../pages/Login.vue";
import Home from "../pages/Home.vue";
import Perfil from "../pages/Perfil.vue";

import Acontecimentos from "../pages/academico/Acontecimentos.vue";
import HistoricoEscolar from "../pages/academico/HistoricoEscolar.vue";
import SalaDeAulaVirtual from "../pages/academico/SalaDeAulaVirtual.vue";
import QuadroDeHorario from "../pages/academico/QuadroDeHorario.vue";
import AgendamentoProvasEAD from "../pages/academico/AgendamentoProvasEAD.vue";
import DocumentosAcademicos from "../pages/academico/DocumentosAcademicos.vue";

import DocumentosFinanceiros from "../pages/financeiro/DocumentoFinanceiro.vue";
import MeusContratos from "../pages/financeiro/MeusContratos.vue";
import ExtratoFinanceiro from "../pages/financeiro/ExtratoFinanceiro.vue";
import AlterarVencimento from "../pages/financeiro/AlterarVencimento.vue";

import ArquivosDaInstituicao from "../pages/materiais/ArquivosDaInstituicao.vue";

import Acompanhamento from "../pages/protocolos/Acompanhamento.vue";
import Solicitacao from "../pages/protocolos/Solicitacao.vue";

import { clearStepsInformation, isLogged } from "../helpers/storage";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Login,
      meta: {
        isLogin: true,
        rule: "public"
      },
    },
    {
      path: "/home",
      component: Home,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/perfil",
      component: Perfil,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/academico/acontecimentos",
      component: Acontecimentos,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/academico/historico-escolar",
      component: HistoricoEscolar,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/academico/salas-de-aulas-virtuais",
      component: SalaDeAulaVirtual,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/academico/quadro-de-horario",
      component: QuadroDeHorario,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/academico/agendamento-de-prova",
      component: AgendamentoProvasEAD,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/academico/documentos-academicos",
      component: DocumentosAcademicos,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/financeiro/contrato",
      component: MeusContratos,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/financeiro/extrato-financeiro",
      component: ExtratoFinanceiro,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/financeiro/documento-financeiro",
      component: DocumentosFinanceiros,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/financeiro/alterar-vencimento",
      component: AlterarVencimento,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/materiais/arquivos-da-instituicao",
      component: ArquivosDaInstituicao,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/protocolos/solicitacao",
      component: Solicitacao,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/protocolos/acompanhamento",
      component: Acompanhamento,
      meta: {        
        rule: "isLogged"
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
    if (to.meta.clearStepsInformation) {
      clearStepsInformation();
    }
  
    function navigateToNextPageOrFallback(condition, nextFallbackParams) {
        if (condition) {
            next();
        } else {
            next({ ...nextFallbackParams });
        }
    }

    const { isLogin } = to.meta;

    if (isLogin) {
        navigateToNextPageOrFallback(!isLogged(), {
            path: "/home",
        });
    } else {
        navigateToNextPageOrFallback(isLogged(), {
            path: "/",
            params: { nextUrl: to.fullPath },
        });
    }
});

export default router;