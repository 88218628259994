<template>
  <div>
    <Navbar />
    <div class="columns">
      <SideBar class="menu" />
      <section class="container">
        <Header class="header" />
        <div class="card-sup">
          <vs-col>
            <div class="card-info">
              <h3>
                <font-awesome-icon
                  style="margin-right: 3.5px"
                  :icon="['fas', 'medal']"
                />
                Histórico Escolar
              </h3>
              <vs-divider />
              <vs-row class="pd-t">
                <vs-col>
                  <div v-if="courses[0]">
                    <fieldset>
                      <legend>Curso <b style="color: red">*</b></legend>
                      <ValidationProvider name="Curso" rules="required">
                        <div>
                          <vs-select
                            class="selectExample"
                            placeholder="Selecione um Curso"
                            v-model="courseSelected"
                            @change="getObject()"
                          >
                            <vs-select-item
                              :key="index"
                              :value="item"
                              :text="'(' + item.STATUS + ') - ' + item.CURSO"
                              v-for="(item, index) in courses"
                            />
                          </vs-select>
                          <!--<p style="margin-top: 8.5px; font-size: 0.85em">
                            Problemas com sua nota? Solicite a
                            <a href="/protocolos/solicitacao">
                              Verificação de Notas
                            </a>
                            da disciplina
                          </p>-->
                        </div>
                      </ValidationProvider>
                    </fieldset>
                  </div>
                  <div v-else>
                    <div class="error-info">
                      <b>
                        <font-awesome-icon
                          style="color: red; margin-right: 3.5px"
                          :icon="['fas', 'exclamation-triangle']"
                        />
                        Nenhum curso encontrado
                      </b>
                    </div>
                  </div>
                </vs-col>
              </vs-row>

              <vs-row class="pd-table" v-if="courseHistory != null">
                <div v-if="courseHistory == ''" style="width: 100%">
                  <div class="error-info">
                    <b>
                      <font-awesome-icon
                        style="color: red; margin-right: 3.5px"
                        :icon="['fas', 'exclamation-triangle']"
                      />
                      Nenhuma informação a ser mostrada
                    </b>
                  </div>
                </div>

                <vs-col v-else>
                  <vs-divider />
                  <vs-row>
                    <vs-col v-for="(disciplinas, ciclo) in ciclos" :key="ciclo">
                      <fieldset style="margin-bottom: 30px">
                        <legend
                          style="
                            color: black;
                            width: 100%;
                            text-align: center;
                            border: 1px solid;
                            border-radius: 50px;
                            padding: 5px;
                          "
                        >
                          {{ ciclo != "ELETIVA" ? ciclo + "º CICLO" : ciclo }}
                        </legend>
                        <vs-table search :data="disciplinas" hoverFlat>
                          <template slot="thead">
                            <vs-th width="55%" sort-key="DISCIPLINA"
                              >DISCIPLINA</vs-th
                            >
                            <vs-th width="15%" sort-key="MEDIA FINAL"
                              >MÉDIA FINAL</vs-th
                            >
                            <vs-th width="15%" sort-key="STATUS">STATUS</vs-th>
                            <vs-th></vs-th>
                          </template>
                          <template slot-scope="{ data }">
                            <vs-tr :key="index" v-for="(tr, index) in data">
                              <vs-td>
                                <p>{{ tr.DISCIPLINA }}</p>
                                <p style="margin-top: 3.5px">
                                  <font-awesome-icon
                                    :icon="['fas', 'stopwatch']"
                                  />
                                  {{ tr.CARGAHORARIA * 1 }}h
                                </p>
                                <p
                                  v-if="tr.FALTA * 1 != 0"
                                  style="margin-top: 5px"
                                >
                                  <font-awesome-icon
                                    style="color: red"
                                    :icon="['fas', 'times-circle']"
                                  />
                                  Falta(s):
                                  <b style="color: red">
                                    {{ tr.FALTA * 1 }}
                                  </b>
                                </p>
                              </vs-td>
                              <vs-td>
                                <div v-if="tr.NOTA">
                                  {{ Number(tr.NOTA).toFixed(2) }}
                                </div>
                                <div v-else>-</div>
                              </vs-td>
                              <vs-td :class="statusFormate(tr.STATUS)">{{
                                tr.STATUS == "*Equiv." || !tr.STATUS
                                  ? "-"
                                  : tr.STATUS
                              }}</vs-td>
                              <vs-td>
                                <vs-button
                                  style="width: 100%"
                                  @click="
                                    note(tr.DISCIPLINA, tr.IDTURMADISC, tr.RA)
                                  "
                                >
                                  <font-awesome-icon
                                    style="margin-right: 3.5px"
                                    :icon="['fas', 'sign-in-alt']"
                                  />
                                  Ver Notas
                                </vs-button>
                              </vs-td>
                            </vs-tr>
                          </template>
                        </vs-table>
                      </fieldset>
                    </vs-col>
                  </vs-row>

                  <vs-popup
                    title="Desempenho Escolar"
                    :active.sync="popupHistoric"
                  >
                    <vs-row class="pd-t">
                      <vs-col>
                        <vs-card>
                          <vs-list>
                            <h2 style="text-align: center">{{ nameMatter }}</h2>

                            <vs-divider />

                            <vs-table
                              class="table_intranet"
                              :data="noteDescription"
                              hoverFlat
                            >
                              <template slot="thead">
                                <vs-th>ETAPA</vs-th>
                                <vs-th>RESULTADO</vs-th>
                              </template>

                              <template slot-scope="{ data }">
                                <vs-tr v-for="(tr, index) in data" :key="index">
                                  <vs-td>
                                    {{
                                      data[index].ETAPA
                                        ? data[index].ETAPA
                                        : "-"
                                    }}
                                  </vs-td>

                                  <vs-td>
                                    {{
                                      data[index].NOTA
                                        ? data[index].ETAPA == "FALTAS"
                                          ? data[index].NOTA * 1
                                          : Number(data[index].NOTA).toFixed(2)
                                        : "-"
                                    }}
                                  </vs-td>
                                </vs-tr>
                              </template>
                            </vs-table>
                          </vs-list>
                        </vs-card>
                      </vs-col>
                    </vs-row>
                  </vs-popup>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Management from "../../services/management";
import Student from "../../services/students";

export default {
  name: "HistoricoEscolar",
  data: () => ({
    courses: [],
    courseHistory: null,
    courseSelected: null,
    popupHistoric: false,
    error: null,
    nameMatter: null,
    noteDescription: [],
    idCourse: null,
    idGrid: null,
    idLicence: null,
    idPeriod: null,
  }),

  computed: {
    ciclos() {
      const grouped = this.courseHistory.reduce((acc, disciplina) => {
        const ciclo = disciplina.PERIODO || "ELETIVA";
        if (!acc[ciclo]) acc[ciclo] = [];
        acc[ciclo].push(disciplina);
        return acc;
      }, {});

      return grouped;
    },
  },

  mounted() {
    this.$vs.loading({ text: "Procurando Cursos 📚" });
    new Student()
      .getCoursesByRA()
      .then(({ data }) => {
        this.courses = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    getObject() {
      this.idCourse = this.courseSelected.CODCURSO;
      this.idGrid = this.courseSelected.CODGRADE;
      this.idLicence = this.courseSelected.CODHABILITACAO;
      this.idPeriod = this.courseSelected.CODPERIODO;

      if (this.courseSelected.ATUALIZANALISECURRICULAR == "S") {
        const payloadAnalysis = {
          colligateId: this.courseSelected.CODCOLIGADA,
          codCourse: this.courseSelected.CODCURSO,
          ra: this.courseSelected.RA,
        };

        this.$vs.loading({ text: "Carregando sua Análise Curricular 📚" });
        new Management()
          .updateCurriculumAnalysis(payloadAnalysis)
          .then(({ data }) => {
            console.log(data);
          })
          .catch(({ response }) => {
            console.log(response);
          })
          .finally(() => {
            this.$vs.loading.close();

            setTimeout(() => {
              this.$vs.loading({ text: "Buscando seu Histórico Escolar 💯" });
              new Student()
                .getCourseHistory(
                  this.idCourse,
                  this.idGrid,
                  this.idLicence,
                  this.idPeriod
                )
                .then(({ data }) => {
                  this.courseHistory = Array.isArray(data) ? data : [data];
                })
                .catch(({ response }) => {
                  if (response && response.data) {
                    this.error = response.data;
                    console.log(response);
                  }
                })
                .finally(() => {
                  this.$vs.loading.close();
                });
            }, 135);
          });
      } else {
        this.$vs.loading({ text: "Buscando seu Histórico Escolar 💯" });
        new Student()
          .getCourseHistory(
            this.idCourse,
            this.idGrid,
            this.idLicence,
            this.idPeriod
          )
          .then(({ data }) => {
            this.courseHistory = Array.isArray(data) ? data : [data];
          })
          .catch(({ response }) => {
            if (response && response.data) {
              this.error = response.data;
              console.log(response);
            }
          })
          .finally(() => {
            this.$vs.loading.close();
          });
      }
    },

    note(discipline, turmaDisc, ra) {
      this.popupHistoric = true;
      this.noteDescription = [];

      this.nameMatter = discipline;

      this.$vs.loading({ text: "Carregando suas Notas 🎓" });
      new Student()
        .getNote(turmaDisc ? turmaDisc : 0, ra)
        .then(({ data }) => {
          this.noteDescription = Array.isArray(data) ? data : [data];
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    statusFormate(status) {
      switch (status) {
        case "APROVADO":
        case "APROVADO/APROVEITAMENTO EQUIVALENTE":
          return "aprovado";
        case "MATRICULADO":
          return "matriculado";
        case "REPROVADO":
        case "REPROVADO POR FALTA":
          return "reprovado";
        default:
          return "pendente";
      }
    },
  },
};
</script>

<style scoped>
tr:nth-child(even) {
  background-color: #d4d4d4;
}

legend {
  font-size: 1.05em;
}

.card-sup {
  margin-left: 8.5px;
  margin-right: 8.5px;
  margin-bottom: 8.5px;
}

.flex {
  display: grid;
  row-gap: 0.5em;
}

.figure-flex {
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.7em;
  margin: 0.25em 0.285em;
}

.itens {
  display: flex;
}

.texto-legenda {
  font-size: 0.85em;
}

.aprovado,
.matriculado,
.pendente,
.reprovado {
  font-size: 1.085em;
  font-weight: bold;
}

.aprovado {
  color: #008000;
}

.matriculado {
  color: #00aeff;
}

.pendente {
  color: #ff8800;
}

.reprovado {
  color: #e40000;
}

@media (max-width: 500px) {
  .carga-horaria {
    display: none;
  }
}
</style>