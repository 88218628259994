import { https } from './apiFatura';

export default class {
    constructor() {
        this.instance = https('dados')
    }

    getValue = (hash) => {
        return this.instance({
            method: 'GET',
            url: `/${hash}/valor`,
            timeout: 30000
        });
    }
}