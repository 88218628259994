<template>
  <div>
    <vs-divider />
    <div class="card-sup">
      <vs-col>
        <div class="card-info">
          <h3>
            <font-awesome-icon
              style="margin-right: 3.5px"
              :icon="['fas', 'book']"
            />
            Aproveitamento de Estudos
          </h3>
          <vs-divider />
          <ValidationObserver ref="observer">
            <div class="con-select-example">
              <vs-row>
                <vs-col>
                  <Message
                    :msg="idAttendance"
                    :error="errorIdAttendance"
                    :errorDuplicate="idAttendanceDuplicate"
                    v-if="
                      idAttendance || errorIdAttendance || idAttendanceDuplicate
                    "
                  />
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend style="font-size: 0.95em">
                      Histórico <b style="color: red">*</b>
                    </legend>
                    <ValidationProvider name="Histórico" ref="provider">
                      <input
                        class="selectExample"
                        ref="historic"
                        type="file"
                        @change="onFileUploadHistoric"
                      />
                    </ValidationProvider>
                  </fieldset>
                </vs-col>

                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend style="font-size: 0.95em">
                      Ementas <b style="color: red">*</b>
                    </legend>
                    <ValidationProvider name="Ementa" ref="provider">
                      <input
                        class="selectExample"
                        ref="summary"
                        type="file"
                        @change="onFileUploadSummary"
                      />
                    </ValidationProvider>
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col style="text-align: center">
                  <vs-button
                    color="danger"
                    type="gradient"
                    class="button-protocol"
                    @click="resetForm()"
                    :disabled="!visibleButtonHistory && !visibleButtonSummary"
                  >
                    <font-awesome-icon
                      style="margin-right: 3.5px"
                      :icon="['fas', 'times-circle']"
                    />
                    Cancelar
                  </vs-button>

                  <vs-button
                    class="button-protocol"
                    color="success"
                    type="gradient"
                    @click="createAttendance()"
                    :disabled="
                      !visibleButtonHistory ||
                      !visibleButtonSummary ||
                      idAttendanceDuplicate
                    "
                  >
                    <font-awesome-icon
                      style="margin-right: 3.5px"
                      :icon="['fas', 'check-circle']"
                    />
                    Gerar
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
          </ValidationObserver>
        </div>
      </vs-col>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import Person from "../../../services/person";
import Management from "../../../services/management";
import ProtocolModel from "../../../models/menus/protocols/protocolTypes";
import Attendance from "../../../services/attendance";

export default {
  name: "AproveitamentoEstudos",
  props: {
    course: Object,
    filial: String,
  },
  data: () => ({
    filesHistoric: null,
    filesSummary: null,
    base64Historic: null,
    base64Summary: null,
    visibleButtonHistory: false,
    visibleButtonSummary: false,
    idAttendance: null,
    errorIdAttendance: null,
    idAttendanceDuplicate: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando Dados Acadêmicos 🪪" });
    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];
        
        this.nome = this.personalData[0].NOME;
        this.ra = this.personalData[0].RA;
        this.email =
          this.personalData[0].EMAIL == null ? "-" : this.personalData[0].EMAIL;
        this.emailFinancialResponsible =
          this.personalData[0].EMAILRESPONSAVELFINANCEIRO == null
            ? "-"
            : this.personalData[0].EMAILRESPONSAVELFINANCEIRO;
        this.telephone =
          this.personalData[0].TELEFONE1 == null
            ? "-"
            : `(${this.personalData[0].TELEFONE1.slice(
                0,
                2
              )}) ${this.personalData[0].TELEFONE1.slice(2, 12)}`;
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });

    new Management()
      .getProtocolInProgress()
      .then(({ data }) => {
        this.protocolInProgress = (Array.isArray(data) ? data : [data]).filter(
          (a) =>
            a.ASSUNTOOC == "APROVEITAMENTO DE ESTUDOS - PORTAL DO ALUNO" &&
            a.CURSO == `(${this.course.CODCURSO}) ${this.course.CURSO}`
        );

        if (this.protocolInProgress.length != 0) {
          this.idAttendanceDuplicate =
            this.protocolInProgress[0].CODATENDIMENTO;
          this.$vs.loading.close();
        }
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });
  },

  methods: {
    async onFileUploadHistoric(event) {
      this.filesHistoric = event.target.files[0];
      const { valid } = await this.$refs.provider.validate(this.filesHistoric);
      if (valid) {
        this.createHistoric(this.filesHistoric);
        this.visibleButtonHistory = true;
      }
    },

    async onFileUploadSummary(event) {
      this.filesSummary = event.target.files[0];
      const { valid } = await this.$refs.provider.validate(this.filesSummary);
      if (valid) {
        this.createSummary(this.filesSummary);
        this.visibleButtonSummary = true;
      }
    },

    createHistoric(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;
        this.base64Historic = window.btoa(bynaryData);
      };
      reader.readAsBinaryString(file);
    },

    createSummary(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;
        this.base64Summary = window.btoa(bynaryData);
      };
      reader.readAsBinaryString(file);
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      this.idAttendanceDuplicate = null;

      const studentName = this.nome;
      const studentRA = this.ra;

      /* INFORMAÇÕES EXTERNAS */
      const courseSolicitacion = this.course.CURSO;
      const filialSolicitacion = this.filial;

      const payload = new ProtocolModel().getDataByAttendanceId("P18");
      payload.data.locationId = this.course.POLO;
      payload.data.ra = studentRA;
      payload.data.attachmentHistoric = this.base64Historic;
      payload.data.fileNameHistoric = this.filesHistoric.name;
      payload.data.attachmentSummary = this.base64Summary;
      payload.data.fileNameSummary = this.filesSummary.name;
      payload.data.nameCourse = `(${this.course.CODCURSO}) ${this.course.CURSO}`;
      payload.data.requestText = `RA: ${studentRA}\nALUNO: ${studentName}\nE-MAIL DO ALUNO: ${this.email}\nE-MAIL DO RESPONSÁVEL FINANCEIRO: ${this.emailFinancialResponsible}\nTELEFONE: ${this.telephone}\nCURSO: ${courseSolicitacion}\nESCOLA: ${filialSolicitacion}`;

      this.$vs.loading({ text: "Gerando Atendimento 🏷️" });
      new Management()
        .getProtocolInProgress()
        .then(({ data }) => {
          this.protocolInProgress = (
            Array.isArray(data) ? data : [data]
          ).filter(
            (a) =>
              a.ASSUNTOOC == payload.data.subject &&
              a.CURSO == payload.data.nameCourse
          );

          if (this.protocolInProgress.length != 0) {
            this.idAttendanceDuplicate =
              this.protocolInProgress[0].CODATENDIMENTO;
            this.$vs.loading.close();
          } else {
            new Attendance()
              .createAttendance(payload.data)
              .then(({ data }) => {
                this.idAttendance = data;
                this.$vs.loading.close();
              })
              .catch(({ response }) => {
                if (response && response.data) {
                  this.errorIdAttendance = response.data;
                  console.log(response);
                }
                this.$vs.loading.close();
              });
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
          }
        });

      this.resetForm();
    },

    resetForm() {
      this.base64Historic = null;
      this.filesHistoric = null;
      this.base64Summary = null;
      this.filesSummary = null;
      this.visibleButtonHistory = false;
      this.visibleButtonSummary = false;

      this.$refs.historic.value = null;
      this.$refs.summary.value = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>

<style scoped>
.card-sup {
  margin: 20px 8.5px 8.5px 8.5px;
}
</style>