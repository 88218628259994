<template>
  <div>
    <Navbar />
    <div class="columns">
      <SideBar class="menu" />
      <section class="container">
        <Header class="header" />
        <div class="card-sup">
          <vs-col>
            <div class="card-info">
              <h3>
                <font-awesome-icon
                  style="margin-right: 3.5px"
                  :icon="['fas', 'folder-open']"
                />
                Arquivos da Instituição
              </h3>
              <vs-divider />
              <vs-row class="pd-table">
                <vs-col>
                  <vs-table class="table_intranet" hoverFlat :data="files">
                    <template slot="thead">
                      <vs-th> NOME DO ARQUIVO </vs-th>
                      <vs-th width="15%"> AÇÕES </vs-th>
                    </template>

                    <template slot-scope="{ data }">
                      <vs-tr :key="index" v-for="(i, index) in data">
                        <vs-td>
                          {{ data[index].name }}
                        </vs-td>

                        <vs-td>
                          <vs-button
                            class="button-table"
                            type="gradient"
                            @click="openInNewTab(data[index].link)"
                          >
                            <font-awesome-icon
                              color="white"
                              style="margin-right: 3.5px"
                              :icon="['fas', 'file-pdf']"
                            />
                            .PDF
                          </vs-button>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArquivosDaInstituicao",
  data: () => ({
    files: [
      {
        id: 1,
        name: "Regimento SITE QI",
        link: "/arquivos/Regimento SITE QI.pdf",
      },
      {
        id: 2,
        name: "Projeto Político Pedagógico",
        link: "/arquivos/Projeto Político Pedagógico Revisado.pdf",
      },
      {
        id: 3,
        name: "Informativo do Aluno - Graduações Tecnológicas",
        link: "/arquivos/Anexo 001 Norma 029 - Informativo do Aluno Graduações Tecnológicas.pdf",
      },
      {
        id: 4,
        name: "Regimento Geral",
        link: "/arquivos/REGIMENTO_GERAL.pdf",
      },
      {
        id: 5,
        name: "Regulamento Biblioteca FAQI",
        link: "/arquivos/REGULAMENTO_BIBLIOTECA.pdf",
      },
      {
        id: 6,
        name: "Regulamento de Laboratórios",
        link: "/arquivos/FAQI-LABORATORIOS.pdf",
      },
    ],
  }),
  methods: {
    openInNewTab(url) {
      const absoluteUrl = window.location.origin + url;
      const newWindow = window.open(absoluteUrl, "_blank");
      if (newWindow) newWindow.focus();
    },
  },
};
</script>

<style scoped>
tr:nth-child(even) {
  background-color: #d4d4d4;
}

.card-sup {
  margin-left: 8.5px;
  margin-right: 8.5px;
  margin-bottom: 8.5px;
}
</style>
