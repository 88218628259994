import { https } from '../apiPayment';
export default class {
    constructor() {
      this.instance = https('service')
    }

    // WS.GETPARCELAS
    getPaymentSlip = (ra) => {
      return this.instance ({ 
        method: 'GET',
        url: `/invoices/open/${ra}`,
        timeout: 30000
      });
    }

    // TRVEN.LISTAPAR
    getInvoices = (ra) => {
      return this.instance({
        method: 'GET',
        url: `/invoices/${ra}`,
        timeout: 30000
      })
    }
  
    updateDueDate = (data) => {
      return this.instance({
        method: 'PUT',
        url: `/invoices/duedate`,
        data
      })
    }
}