<template>
  <div>
    <Navbar />
    <div class="columns">
      <SideBar class="menu" />
      <section class="container">
        <Header class="header" />
        <div class="card-sup">
          <vs-col>
            <div class="card-info">
              <h3>
                <font-awesome-icon
                  style="margin-right: 3.5px"
                  :icon="['fas', 'clock']"
                />
                Quadro de Horário
              </h3>
              <vs-divider />
              <div v-if="subjects[0]">
                <vs-row>
                  <vs-col>
                    <vs-table :data="subjects" hoverFlat>
                      <template slot-scope="{ data }">
                        <vs-tr :key="index" v-for="(i, index) in data">
                          <vs-td>
                            <fieldset>
                              <legend
                                style="
                                  color: black;
                                  width: 100%;
                                  text-align: center;
                                  border: 1px solid;
                                  border-radius: 50px;
                                  padding: 5px;
                                "
                              >
                                {{ data[index].DISCIPLINA }}
                              </legend>
                              <vs-col>
                                <vs-list>
                                  <vs-list-item
                                    title="CURSO"
                                    :subtitle="data[index].CURSO"
                                  ></vs-list-item>
                                  <vs-list-item
                                    title="POLO / SALA"
                                    :subtitle="
                                      data[index].NOMEPOLO == null
                                        ? '-'
                                        : data[index].SALA == null
                                        ? '-'
                                        : data[index].NOMEPOLO +
                                          ' / ' +
                                          data[index].SALA
                                    "
                                  ></vs-list-item>
                                  <vs-list-item
                                    title="PERÍODO"
                                    :subtitle="
                                      data[index].DTINICIAL ==
                                      data[index].DTFINAL
                                        ? data[index].DTINICIAL
                                        : data[index].DTINICIAL +
                                          ' - ' +
                                          data[index].DTFINAL
                                    "
                                  ></vs-list-item>
                                </vs-list>

                                <fieldset
                                  v-if="
                                    data[index].SEGUNDA != '-' ||
                                    data[index].TERCA != '-' ||
                                    data[index].QUARTA != '-' ||
                                    data[index].QUINTA != '-' ||
                                    data[index].SEXTA != '-' ||
                                    data[index].SABADO != '-'
                                  "
                                  style="margin-top: 10px"
                                >
                                  <vs-list>
                                    <vs-list-item
                                      v-if="data[index].SEGUNDA != '-'"
                                      title="SEGUNDA"
                                      :subtitle="data[index].SEGUNDA"
                                    ></vs-list-item>
                                    <vs-list-item
                                      v-if="data[index].TERCA != '-'"
                                      title="TERÇA"
                                      :subtitle="data[index].TERCA"
                                    ></vs-list-item>
                                    <vs-list-item
                                      v-if="data[index].QUARTA != '-'"
                                      title="QUARTA"
                                      :subtitle="data[index].QUARTA"
                                    ></vs-list-item>
                                    <vs-list-item
                                      v-if="data[index].QUINTA != '-'"
                                      title="QUINTA"
                                      :subtitle="data[index].QUINTA"
                                    ></vs-list-item>
                                    <vs-list-item
                                      v-if="data[index].SEXTA != '-'"
                                      title="SEXTA"
                                      :subtitle="data[index].SEXTA"
                                    ></vs-list-item>
                                    <vs-list-item
                                      v-if="data[index].SABADO != '-'"
                                      title="SÁBADO"
                                      :subtitle="data[index].SABADO"
                                    ></vs-list-item>
                                  </vs-list>
                                </fieldset>
                              </vs-col>
                            </fieldset>
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </vs-col>
                </vs-row>
              </div>

              <div v-else>
                <div class="error-info">
                  <b>
                    <font-awesome-icon
                      style="color: red; margin-right: 3.5px"
                      :icon="['fas', 'exclamation-triangle']"
                    />
                    Nenhuma disciplina encontrada
                  </b>
                </div>
              </div>
            </div>
          </vs-col>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Student from "../../services/students";
export default {
  name: "QuadroDeHorario",
  data: () => ({
    subjects: [],
  }),

  mounted() {
    this.getTimetable();
  },

  methods: {
    getTimetable() {
      this.$vs.loading({ text: "Qual será sua próxima disciplina❓" });
      new Student()
        .getMatterByRA()
        .then(({ data }) => {
          this.subjects = Array.isArray(data) ? data : [data];
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
  },
};
</script>

<style scoped>
td {
  border: none !important;
}

tr:nth-child(even) {
  background-color: white;
}

.card-sup {
  margin-left: 8.5px;
  margin-right: 8.5px;
  margin-bottom: 8.5px;
}
</style>