import jwtDecode from 'jwt-decode'

const STORAGE_KEY = 'qi-storage-key'
const INFO_KEY = 'qi-steps-key'

export const saveDataInStorage = (data) => {
  try {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(data))
    return true
  } catch (error) {
    return false
  }
}

export const getProfiles = () => {
  try {
    const { profiles } = JSON.parse(sessionStorage.getItem(STORAGE_KEY));
    return profiles;
  } catch {
    return false;
  }
}

export const getLoginDataFromStorage = () => {
  try {
    const data = JSON.parse(sessionStorage.getItem(STORAGE_KEY))
    // const userData = jwtDecode(token)

    return {
      ...data,
      errorMessage: null,
    }
  } catch (err) {
    return {
      errorMessage: 'Falha na autenticação, verifique seus dados e tente novamente',
    }
  }
}

export const getStepsInformation = () => {
  try {
    const data = JSON.parse(sessionStorage.getItem(INFO_KEY))
    return data || {}
  } catch {
    return {
      error: true,
    }
  }
}

export const updateStepsInformation = (newData) => {
  try {
    const oldData = JSON.parse(sessionStorage.getItem(INFO_KEY))
    sessionStorage.setItem(INFO_KEY, JSON.stringify({
      ...oldData,
      ...newData,
    }))
    return true
  } catch (error) {
    return false
  }
}

export const clearStepsInformation = () => {
  try {
    sessionStorage.removeItem(INFO_KEY)
    return true
  } catch {
    return false
  }
}

export const logoffUserAndEraseData = () => {
  try {
    sessionStorage.removeItem(STORAGE_KEY)
    if (!clearStepsInformation()) {
      throw new Error()
    }
    return true
  } catch {
    return false
  }
}

export const isLogged = () => {
  try {
    const loginData = JSON.parse(sessionStorage.getItem(STORAGE_KEY))
    if (loginData.token) {
      const token = jwtDecode(loginData.token);
      const date = new Date().getTime() / 1000;
      if (token.exp < date) {
        logoffUserAndEraseData()
        return false
      }
      return true
    }
    return false
  } catch {
    return false
  }
}

export const titleCase = text => {
  return text.toLowerCase()
    .split(' ')
    .map((word) => {
      return word[0].toUpperCase() + word.slice(1);
    }).join(' ')
}

export const nameFormat = (name) => {
  const nameArray = name.split(' ')
  return `${titleCase(nameArray[0])} ${titleCase(nameArray[nameArray.length - 1])}`
}

export const firstName = (name) => {
  const nameArray = name.split(' ')
  return `${titleCase(nameArray[0])}`
}

export const routerPages = (path) => {
  return this.$router.push(path);
}

export const parseNewLine = (text) => {
  if (text) {
    return text.replace(/\n/g, '<br/>');
  }
  return ` `
}

export const mensagem = (attendance) => {
  let codigoAtend = attendance.split(';')
  return attendance = `${codigoAtend[2]}`;
}
export const poloTextFormat = (key, inputArray) => {
  for (let i = 0; i < inputArray.length; i++) {
    if (inputArray[i].CODFILIAL === key) {
      return inputArray[i].POLO
    }
  }
}

export const searchNamePolo = (key, inputArray) => {
  for (let i = 0; i < inputArray.length; i++) {
    if (inputArray[i].CODCURSO === key) {
      return inputArray[i].NOMEPOLO
    }
  }
}

export const searchConcluded = (key, inputArray) => {
  for (let i = 0; i < inputArray.length; i++) {
    if (inputArray[i].CODCURSO === key) {
      return inputArray[i].CURSO_CONCLUIDO
    }
  }
}

export const convertDate = (dateInput) => {
  let data = new Date(dateInput)
  return data.toLocaleDateString('pt-BR', {timeZone: 'UTC'});
}