<template>
  <div>
    <vs-divider />
    <div class="card-sup">
      <vs-col>
        <div class="card-info">
          <h3>
            <font-awesome-icon
              style="margin-right: 3.5px"
              :icon="['fas', 'book']"
            />
            Atividade Complementar
          </h3>
          <vs-divider />
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <div class="con-select-example">
              <vs-row>
                <vs-col>
                  <Message
                    :msg="idAttendance"
                    :error="errorIdAttendance"
                    v-if="idAttendance || errorIdAttendance"
                  />
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col>
                  <fieldset>
                    <legend style="font-size: 0.95em">
                      Documento <b style="color: red">*</b>
                    </legend>
                    <ValidationProvider name="Documento" ref="provider">
                      <input
                        class="selectExample"
                        ref="documents"
                        type="file"
                        @change="onFileUploadDocument"
                      />
                    </ValidationProvider>
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col>
                  <ValidationProvider
                    name="Observação"
                    rules="required|max: 500"
                  >
                    <vs-textarea
                      rows="11"
                      label="Observação *"
                      class="textArea"
                      height="15em"
                      counter="500"
                      v-model="solicitation"
                    />
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col style="text-align: center">
                  <vs-button
                    color="danger"
                    type="gradient"
                    class="button-protocol"
                    @click="resetForm()"
                    :disabled="filesDocuments == null && invalid"
                  >
                    <font-awesome-icon style="margin-right: 3.5px;" :icon="['fas', 'times-circle']" />
                    Cancelar
                  </vs-button>

                  <vs-button
                    class="button-protocol"
                    color="success"
                    type="gradient"
                    @click="createAttendance()"
                    :disabled="invalid || !visibleButton"
                  >
                    <font-awesome-icon style="margin-right: 3.5px;" :icon="['fas', 'check-circle']" />
                    Gerar
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
          </ValidationObserver>
        </div>
      </vs-col>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import Person from "../../../services/person";
import ProtocolModel from "../../../models/menus/protocols/protocolTypes";
import Attendance from "../../../services/attendance";

export default {
  name: "AtividadeComplementar",
  props: {
    course: Object,
    filial: String,
  },
  data: () => ({
    filesDocuments: null,
    base64Documents: null,
    visibleButton: false,
    idAttendance: null,
    errorIdAttendance: null,
    solicitation: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando Dados Acadêmicos 🪪" });
    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];
        
        this.nome = this.personalData[0].NOME;
        this.ra = this.personalData[0].RA;
        this.email =
          this.personalData[0].EMAIL == null
            ? "-"
            : this.personalData[0].EMAIL;
        this.emailFinancialResponsible =
          this.personalData[0].EMAILRESPONSAVELFINANCEIRO == null
            ? "-"
            : this.personalData[0].EMAILRESPONSAVELFINANCEIRO;
        this.telephone =
          this.personalData[0].TELEFONE1 == null
            ? "-"
            : `(${this.personalData[0].TELEFONE1.slice(
                0,
                2
              )}) ${this.personalData[0].TELEFONE1.slice(2, 12)}`;
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    async onFileUploadDocument(event) {
      this.filesDocuments = event.target.files[0];
      const { valid } = await this.$refs.provider.validate(this.filesDocuments);
      if (valid) {
        this.createDocuments(this.filesDocuments);
        this.visibleButton = true;
      }
    },

    createDocuments(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;
        this.base64Documents = window.btoa(bynaryData);
      };
      reader.readAsBinaryString(file);
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;

      const studentName = this.nome;
      const studentRA = this.ra;

      /* INFORMAÇÕES EXTERNAS */
      const courseSolicitacion = this.course.CURSO;
      const filialSolicitacion = this.filial;

      const payload = new ProtocolModel().getDataByAttendanceId("P24");
      payload.data.locationId = this.course.POLO;
      payload.data.ra = studentRA;
      payload.data.attachment = this.base64Documents;
      payload.data.fileName = this.filesDocuments.name;
      payload.data.requestText = `RA: ${studentRA}\nALUNO: ${studentName}\nE-MAIL DO ALUNO: ${this.email}\nE-MAIL DO RESPONSÁVEL FINANCEIRO: ${this.emailFinancialResponsible}\nTELEFONE: ${this.telephone}\nCURSO: ${courseSolicitacion}\nESCOLA: ${filialSolicitacion}\nSOLICITAÇÃO: ${this.solicitation}`;

      this.$vs.loading({ text: "Gerando Atendimento 🏷️" });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response);
          }

          this.$vs.loading.close();
        });

      this.resetForm();
    },

    resetForm() {
      this.base64Documents = null;
      this.filesDocuments = null;
      this.solicitation = null;
      this.visibleButton = false;

      this.$refs.documents.value = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>

<style scoped>
.card-sup {
  margin: 20px 8.5px 8.5px 8.5px;
}
</style>