<template>
  <div class="carousel-container">
    <div class="carousel">
      <a :href="infoBanner[currentIndex].LINK" target="_blank">
        <div class="carousel-image-container">
          <img
            :src="'data:image/jpeg;base64,' + infoBanner[currentIndex].IMAGE64"
            :alt="infoBanner[currentIndex].TITULO"
            class="carousel-image"
          />
          <div class="carousel-caption">
            {{ infoBanner[currentIndex].TITULO }}
          </div>
        </div>
      </a>
      <button @click="prev" class="carousel-button prev">
        <font-awesome-icon :icon="['fas', 'chevron-circle-left']" />
      </button>
      <button @click="next" class="carousel-button next">
        <font-awesome-icon :icon="['fas', 'chevron-circle-right']" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    infoBanner: Array,
  },
  data() {
    return {
      currentIndex: 0,
      error: null,
    };
  },

  methods: {
    next() {
      this.currentIndex = (this.currentIndex + 1) % this.infoBanner.length;
    },
    prev() {
      this.currentIndex =
        (this.currentIndex - 1 + this.infoBanner.length) % this.infoBanner.length;
    },
    goToSlide(index) {
      this.currentIndex = index;
    },
  },
};
</script>

<style>
.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel {
  position: relative;
  width: 100%;
}

.carousel-image-container {
  position: relative;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 18.5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0.5px 5px 10px #8888889a;
  display: block;
}

.carousel-caption {
  bottom: 0;
  margin-top: 0px;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75));
  color: white;
  font-size: 1.25em;
  text-align: center;
  border-bottom-left-radius: 18.5px;
  border-bottom-right-radius: 18.5px;
}

.carousel-button {
  position: absolute;
  top: 50%;
  border-radius: 50px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 7.5px;
  cursor: pointer;
  z-index: 1;
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

.carousel-indicators {
  text-align: center;
  margin-top: 10px;
}

.carousel-indicators span {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.carousel-indicators span.active {
  background-color: #9b0e1c;
}

@media screen and (max-width: 900px) {
  .carousel-caption {
    margin-bottom: 20px;
  }

  .carousel-image {
    height: 100%;
  }
}
</style>
