import axios from 'axios' ;
import '../helpers/storage';
import { getLoginDataFromStorage } from '../helpers/storage'

export const https = (baseURL = '') => {
    const instance = axios.create({
      baseURL: `https://api-intranet.qi.edu.br/${baseURL}`,
      //baseURL: `http://localhost:9095/${baseURL}`,
      headers: {
        'x-access-token': getLoginDataFromStorage().token,
      },
    })
    return instance
}