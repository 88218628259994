<template>
  <div>
    <vs-divider />
    <div class="card-sup">
      <vs-col>
        <div class="card-info">
          <h3>
            <font-awesome-icon
              style="margin-right: 3.5px"
              :icon="['fas', 'calendar-check']"
            />
            Prazo de Provas e Atividades (Moodle)
          </h3>
          <vs-divider />
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <div class="con-select-example">
              <vs-row>
                <vs-col>
                  <Message
                    :msg="idAttendance"
                    :error="errorIdAttendance"
                    v-if="idAttendance || errorIdAttendance"
                  />
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col>
                  <ValidationProvider
                    name="Disciplinas"
                    rules="required"
                  >
                    <fieldset>
                      <legend>
                        Disciplinas <b style="color: red">*</b>
                      </legend>
                      <div class="discipline discipline-info">
                        <div
                          style="padding: 5px"
                          v-if="courseHistory == ''"
                        >
                          <span
                            style="
                              border: none !important;
                              background-color: #e8f6fc;
                            "
                          >
                            <font-awesome-icon
                              style="color: red; margin-right: 6.5px"
                              :icon="['fas', 'times']"
                            />
                            <b>Nenhuma disciplina encontrada</b>
                          </span>
                        </div>
                        <div v-else>
                          <span
                            v-for="(i, index) in courseHistory"
                            :key="index"
                            class="search"
                          >
                            <input
                              type="checkbox"
                              class="checkboxTurn"
                              v-model="selectedDisciplines"
                              :value="i.DISCIPLINA"
                            />
                            {{ i.DISCIPLINA }}
                          </span>
                        </div>
                      </div>
                    </fieldset>
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col>
                  <ValidationProvider
                    name="Observação"
                    rules="required|max: 500"
                  >
                    <vs-textarea
                      rows="11"
                      label="Observação *"
                      class="textArea"
                      height="15em"
                      counter="500"
                      v-model="solicitation"
                    />
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col style="text-align: center">
                  <vs-button
                    class="button-protocol"
                    color="success"
                    type="gradient"
                    @click="createAttendance()"
                    :disabled="invalid || selectedDisciplines == ''"
                  >
                    <font-awesome-icon style="margin-right: 3.5px;" :icon="['fas', 'check-circle']" />
                    Gerar
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
          </ValidationObserver>
        </div>
      </vs-col>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import Student from "../../../services/students";
import Person from "../../../services/person";
import ProtocolModel from "../../../models/menus/protocols/protocolTypes";
import Attendance from "../../../services/attendance";

export default {
  name: "PrazoProvasAtividades",
  props: {
    course: Object,
    filial: String,
  },
  data: () => ({
    idAttendance: null,
    errorIdAttendance: null,
    selectedDisciplines: [],
    personalData: [],
    courseHistory: [],
    solicitation: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando Dados Acadêmicos 🪪" });
    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];
        
        this.nome = this.personalData[0].NOME;
        this.ra = this.personalData[0].RA;
        this.email =
          this.personalData[0].EMAIL == null
            ? "-"
            : this.personalData[0].EMAIL;
        this.emailFinancialResponsible =
          this.personalData[0].EMAILRESPONSAVELFINANCEIRO == null
            ? "-"
            : this.personalData[0].EMAILRESPONSAVELFINANCEIRO;
        this.telephone =
          this.personalData[0].TELEFONE1 == null
            ? "-"
            : `(${this.personalData[0].TELEFONE1.slice(
                0,
                2
              )}) ${this.personalData[0].TELEFONE1.slice(2, 12)}`;
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      });

    this.$vs.loading({ text: "Carregando Dados Acadêmicos 🪪" });
    new Student()
      .getCourseHistory(
        this.course.CODCURSO,
        this.course.CODGRADE,
        this.course.CODHABILITACAO,
        this.course.CODPERIODO
      )
      .then(({ data }) => {
        this.courseHistory = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;

      const studentName = this.nome;
      const studentRA = this.ra;

      /* INFORMAÇÕES EXTERNAS */
      const courseSolicitacion = this.course.CURSO;
      const filialSolicitacion = this.filial;

      const payload = new ProtocolModel().getDataByAttendanceId("P20");
      payload.data.locationId = this.course.POLO;
      payload.data.ra = studentRA;
      payload.data.requestText = `RA: ${studentRA}\nALUNO: ${studentName}\nE-MAIL DO ALUNO: ${
        this.email
      }\nE-MAIL DO RESPONSÁVEL FINANCEIRO: ${
        this.emailFinancialResponsible
      }\nTELEFONE: ${
        this.telephone
      }\nCURSO: ${courseSolicitacion}\nDISCIPLINA(S): ${this.selectedDisciplines}\nESCOLA: ${filialSolicitacion}\nSOLICITAÇÃO: ${
        this.solicitation
      }`;

      this.$vs.loading({ text: "Gerando Atendimento 🏷️" });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response);
          }
          this.$vs.loading.close();
        });

      this.resetForm();
    },

    resetForm() {
      this.selectedDisciplines = [];
      this.solicitation = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>

<style scoped>
.card-sup {
  margin: 20px 8.5px 8.5px 8.5px;
}

legend {
  font-size: 1.05em;
}
</style>