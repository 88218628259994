<template>
  <div>
    <vs-divider />
    <div class="card-sup">
      <vs-col>
        <div class="card-info">
          <h3>
            <font-awesome-icon
              style="margin-right: 3.5px"
              :icon="['fas', 'random']"
            />
            Transferência de Turno e Turma
          </h3>
          <vs-divider />
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <div class="con-select-example">
              <vs-row>
                <vs-col>
                  <Message
                    :msg="idAttendance"
                    :error="errorIdAttendance"
                    :errorDuplicate="idAttendanceDuplicate"
                    v-if="
                      idAttendance || errorIdAttendance || idAttendanceDuplicate
                    "
                  />
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                  <ValidationProvider name="Turno" rules="required">
                    <fieldset>
                      <legend>Turno <b style="color: red">*</b></legend>
                      <div class="boxTurn">
                        <span
                          v-for="(shifts, index) in selectedShift"
                          :key="index"
                          class="search"
                        >
                          <input
                            type="checkbox"
                            class="checkboxTurn"
                            :id="index"
                            v-model="shift"
                            :value="shifts.text"
                          />{{ shifts.text }}
                        </span>
                      </div>
                    </fieldset>
                  </ValidationProvider>
                </vs-col>

                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend>Telefone</legend>
                    <vs-input
                      class="selectExampleDouble"
                      :placeholder="telephone"
                      disabled
                    />
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col>
                  <ValidationProvider
                    name="Justificativa"
                    rules="required|max: 500"
                  >
                    <vs-textarea
                      rows="11"
                      label="Justificativa *"
                      class="textArea"
                      height="15em"
                      counter="500"
                      v-model="solicitation"
                    />
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col style="text-align: center">
                  <vs-button
                    color="danger"
                    type="gradient"
                    class="button-protocol"
                    @click="resetForm()"
                    :disabled="shift == '' && solicitation == null"
                  >
                    <font-awesome-icon
                      style="margin-right: 3.5px"
                      :icon="['fas', 'times-circle']"
                    />
                    Cancelar
                  </vs-button>

                  <vs-button
                    class="button-protocol"
                    color="success"
                    type="gradient"
                    @click="createAttendance()"
                    :disabled="invalid || idAttendanceDuplicate"
                  >
                    <font-awesome-icon
                      style="margin-right: 3.5px"
                      :icon="['fas', 'check-circle']"
                    />
                    Gerar
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
          </ValidationObserver>
        </div>
      </vs-col>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import Person from "../../../services/person";
import Management from "../../../services/management";
import ProtocolModel from "../../../models/menus/protocols/protocolTypes";
import Attendance from "../../../services/attendance";

export default {
  name: "TransferenciaTurmaTurno",
  props: {
    course: Object,
    filial: String,
  },
  data: () => ({
    idAttendance: null,
    errorIdAttendance: null,
    idAttendanceDuplicate: null,
    personalData: [],
    telephone: null,
    selectedShift: [
      { text: "Manhã", value: "Manhã" },
      { text: "Tarde", value: "Tarde" },
      { text: "Noite", value: "Noite" },
    ],
    shift: [],
    solicitation: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando Dados Acadêmicos 🪪" });
    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];
        
        this.nome = this.personalData[0].NOME;
        this.ra = this.personalData[0].RA;
        this.email =
          this.personalData[0].EMAIL == null ? "-" : this.personalData[0].EMAIL;
        this.emailFinancialResponsible =
          this.personalData[0].EMAILRESPONSAVELFINANCEIRO == null
            ? "-"
            : this.personalData[0].EMAILRESPONSAVELFINANCEIRO;
        this.telephone =
          this.personalData[0].TELEFONE1 == null
            ? "-"
            : `(${this.personalData[0].TELEFONE1.slice(
                0,
                2
              )}) ${this.personalData[0].TELEFONE1.slice(2, 12)}`;
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });

    new Management()
      .getProtocolInProgress()
      .then(({ data }) => {
        this.protocolInProgress = (Array.isArray(data) ? data : [data]).filter(
          (a) =>
            a.ASSUNTOOC == "TRANSFERÊNCIA DE TURNO E TURMA - PORTAL DO ALUNO" &&
            a.CURSO == `(${this.course.CODCURSO}) ${this.course.CURSO}`
        );

        if (this.protocolInProgress.length != 0) {
          this.idAttendanceDuplicate =
            this.protocolInProgress[0].CODATENDIMENTO;
          this.$vs.loading.close();
        }
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });
  },

  methods: {
    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      this.idAttendanceDuplicate = null;

      const studentName = this.nome;
      const studentRA = this.ra;

      /* INFORMAÇÕES EXTERNAS */
      const courseTransferClass = this.course.CURSO;
      const filialTransferClass = this.filial;

      const payload = new ProtocolModel().getDataByAttendanceId("P10");
      payload.data.locationId = this.course.POLO;
      payload.data.ra = studentRA;
      payload.data.nameCourse = `(${this.course.CODCURSO}) ${this.course.CURSO}`;
      payload.data.requestText = `RA: ${studentRA}\nALUNO: ${studentName}\nE-MAIL DO ALUNO: ${this.email}\nE-MAIL DO RESPONSÁVEL FINANCEIRO: ${this.emailFinancialResponsible}\nTELEFONE: ${this.telephone}\nCURSO: ${courseTransferClass}\nESCOLA: ${filialTransferClass}\nTURNO(S) DISPONÍVEL: ${this.shift}`;

      this.$vs.loading({ text: "Gerando Atendimento 🏷️" });
      new Management()
        .getProtocolInProgress()
        .then(({ data }) => {
          this.protocolInProgress = (
            Array.isArray(data) ? data : [data]
          ).filter(
            (a) =>
              a.ASSUNTOOC == payload.data.subject &&
              a.CURSO == payload.data.nameCourse
          );

          if (this.protocolInProgress.length != 0) {
            this.idAttendanceDuplicate =
              this.protocolInProgress[0].CODATENDIMENTO;
            this.$vs.loading.close();
          } else {
            new Attendance()
              .createAttendance(payload.data)
              .then(({ data }) => {
                this.idAttendance = data;
                this.$vs.loading.close();
              })
              .catch(({ response }) => {
                if (response && response.data) {
                  this.errorIdAttendance = response.data;
                  console.log(response);
                }
                this.$vs.loading.close();
              });
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
          }
        });

      this.resetForm();
    },

    resetForm() {
      this.shift = [];
      this.solicitation = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>

<style scoped>
.card-sup {
  margin: 20px 8.5px 8.5px 8.5px;
}

legend {
  font-size: 1.05em;
}

@media (max-width: 400px) {
  .checkboxTurn {
    width: 2.6em;
  }

  .boxTurn {
    flex-direction: column;
  }
}
</style>