<template>
  <div class="centerx">
    <div class="attendance note-info" v-if="error">
      <h3>
        <font-awesome-icon color="#9B0E1C" :icon="['fas', 'radiation']" />
        Ops! Algo deu errado...
      </h3>
      <br>
      <p style="font-size: 1.185em;">
        Entre em contato com a Secretaria.<br><br>
        <b>
          <font-awesome-icon :icon="['fas', 'comments']" />
          WhatsApp: 
          <a href="https://api.whatsapp.com/send?phone=5551995413830" target="_blank">
            (51) 995413830
          </a>
        </b>
        <br><br>
        <b>
          <font-awesome-icon :icon="['fas', 'phone']" />
          Telefone: 
          <a href="tel:0800-0000-301" target="_blank">
            0800 000 0301
          </a>
        </b>
        <br>
        <b>
          <font-awesome-icon :icon="['fas', 'envelope']" />
          E-mail: 
          <a href="mailto:ouvidoria@qi.edu.br" target="_blank">
            ouvidoria@qi.edu.br
          </a>
        </b>
        <br><br>
        QI Faculdade & Escola Técnica<br/>O mercado valoriza, reconhece e contrata quem faz QI!
      </p>
    </div>

    <div class="attendance note-info" v-if="errorDuplicate">
      <h3>
        <font-awesome-icon color="#9B0E1C" :icon="['fas', 'radiation']" />
        Ops! Algo deu errado...
      </h3>
      <br>
      <p style="font-size: 1.185em; color: black;">
        Prezado aluno, você já possui um protocolo aberto com essa solicitação,<br/>
        Seu número de protocolo é <strong style="color: #9B0E1C;">{{ errorDuplicate }}</strong>,<br/><br>
        <a style="color: black;" href="/protocolos/acompanhamento">Clique aqui</a> para acompanhar o status de sua solicitação. <br/><br/>
        QI Faculdade & Escola Técnica<br/>O mercado valoriza, reconhece e contrata quem faz QI!
      </p>
    </div>
    
    <div class="sucess sucess-info" v-if="msg">
      <h3>
        <font-awesome-icon color="#139B0E" :icon="['fas', 'check-circle']" />
        Solicitação aberta com sucesso
      </h3>
      <br>
      <p style="font-size: 1.185em; color: black;">
        Prezado aluno, sua solicitação foi aberta com sucesso,<br/>
        Seu número de protocolo é <strong style="color: #139B0E;">{{ formatedValue }}</strong>,<br/><br>
        Aguarde o setor responsável entrar em contato no prazo de até <b>3 dias úteis</b>.<br/><br/>
        <a style="color: black;" href="/protocolos/acompanhamento">Clique aqui</a> para acompanhar o status de sua solicitação. <br/><br/>
        QI Faculdade & Escola Técnica<br/>O mercado valoriza, reconhece e contrata quem faz QI!
      </p>
    </div>
  </div>
</template>

<script>
export default {
    name: "Message",
    props: {
      msg: String,
      error: Object,
      errorDuplicate: String
    },  
    computed: {
      formatedValue: function() {
        let attendanceNumber = this.msg.split(";");
        return attendanceNumber[2];
      }
    }
 
}
</script>

<style scoped>
.note {
  margin: 0;
}
</style>