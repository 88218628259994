import { https } from './apiIntranet';

export default class {
    constructor() {
        this.instance = https()
    }

    createAttendance = (data) => {
        return this.instance({
            method: 'POST',
            url:'/attendance',            
            data,
        })
    }
}