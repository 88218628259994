import { https } from './apiMatricula';

export default class {
    constructor() {
        this.instance = https('scheduling')
    }    

    // AGPROVA.TURMAS
    getSchedulings = (ra) => {
        return this.instance({
            method: 'GET',
            url: `/${ra}`,
            timeout: 30000
        })
    }

    // AGPROVA.ATIVI
    getSchedulingOptions = (qualificationId, campusId, type, idTurmaDisc) => {
        return this.instance({
            method: 'GET',
            url: `/options/${qualificationId}/${campusId}/${type}/${idTurmaDisc}`,
            timeout: 30000
        })
    }

    setScheduling = (data) => {
        return this.instance({
            method: 'POST',
            url: '/',
            data
        })
    }

    unsetScheduling = (data) => {
        return this.instance({
            method: 'DELETE',
            url: '/',
            data
        })
    }
}