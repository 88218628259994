<template>
  <div>
    <Navbar />
    <div class="columns">
      <SideBar class="menu" />
      <section class="container">
        <Header class="header" />
        <div class="card-sup">
          <vs-col>
            <div class="card-info">
              <h3>
                <font-awesome-icon
                  style="margin-right: 3.5px"
                  :icon="['fas', 'file-signature']"
                />
                Alterar Vencimento
              </h3>
              <vs-divider />
              <div>
                <p class="orientation">
                  Para cada contrato é possível prorrogar até duas vezes a data
                  de vencimento, tendo no mínimo um intervalo de 6 meses entre
                  cada prorrogação. A data limite para prorrogação é até o dia
                  10 do mês subsequente à data de vencimento da primeira parcela
                  apta para troca. A antecipação de data de vencimento pode ser
                  feita quantas vezes forem necessárias, sendo bloqueado para
                  realizar o processo uma vez só no mesmo dia. Não é possível
                  alterar o vencimento de parcelas já vencidas, parcelas
                  pertencentes a acordos financeiros ou que contenham boletos
                  registrados. A alteração da data de vencimento valerá para
                  todas as parcelas restantes do contrato que estejam aptas a
                  alterar o vencimento. Observamos também que caso o vencimento
                  da parcela caia em feriado ou final de semana, o vencimento
                  ficará para o próximo dia útil. Os dias 29, 30 e 31 são
                  vetados como escolha de data de vencimento. Para quem utiliza
                  forma de pagamento em boleto, os mesmos são registrados no
                  banco para pagamento até 15 dias da data atual. Informamos
                  também que não é possível alterar vencimento de taxa de
                  matrícula, nem da primeira parcela do contrato.
                </p>
              </div>
              <div v-if="invoices">
                <vs-col style="display: flex; justify-content: center">
                  <vs-select
                    placeholder="Selecione um contrato"
                    v-model="selectedContract"
                    @change="filterContract()"
                    autocomplete
                  >
                    <vs-select-item
                      :key="index"
                      :value="contratc.CODCONTRATO"
                      :text="contratc.CODCONTRATO"
                      v-for="(contratc, index) in contracts"
                    />
                  </vs-select>

                  <vs-alert
                    :color="color"
                    title="Resultado do processo"
                    :active="alert"
                  >
                    <p>{{ result }}</p>
                  </vs-alert>
                </vs-col>
              </div>

              <div v-else>
                <div class="error-info">
                  <b>
                    <font-awesome-icon
                      style="color: red; margin-right: 3.5px"
                      :icon="['fas', 'exclamation-triangle']"
                    />
                    Nenhum contrato encontrado
                  </b>
                </div>
              </div>
            </div>
          </vs-col>
        </div>

        <vs-row class="simulation" v-if="selectedContract">
          <vs-col vs-lg="4" vs-sm="12" vs-xs="12" vs-align="top">
            <p v-if="nextValidDueDate">
              Selecione nova data de vencimento desejada, a partir da parcela
              com data de vencimento para {{ nextValidDueDate.VENCIMENTO }}:
            </p>

            <div class="date-simulate">
              <vs-input
                class="input-date"
                type="date"
                v-model="newDueDate"
              ></vs-input>
              <span v-if="errorNewDueDate" class="error">{{
                errorNewDueDate
              }}</span>

              <div>
                <vs-button class="button-simulate" @click="checkNewDueDate()">
                  <font-awesome-icon :icon="['fas', 'calendar-check']" />
                  Simular
                </vs-button>
              </div>
            </div>
          </vs-col>

          <vs-col vs-lg="8" vs-sm="12" vs-xs="12" vs-align="top">
            <vs-table
              style="margin: 0 4.5% 0 2.5%"
              hoverFlat
              :data="contratcInvoices"
            >
              <template slot="thead">
                <vs-th> PARCELA </vs-th>
                <vs-th> VALOR COM BOLSA </vs-th>
                <vs-th> VENCIMENTO </vs-th>
                <vs-th> OBSERVAÇÃO </vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <b>{{ data[indextr].PARCELA }}</b>
                  </vs-td>

                  <vs-td>
                    {{ formatValue(data[indextr]) }}
                  </vs-td>

                  <vs-td>
                    <b>{{ data[indextr].VENCIMENTO }}</b>
                  </vs-td>

                  <vs-td>
                    {{ getMessage(data[indextr]) }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-col>
        </vs-row>

        <vs-popup :active.sync="viewSimulation" title="Troca de Vencimento">
          <vs-table hoverFlat :data="simulationDates">
            <template slot="thead">
              <vs-th> Parcela </vs-th>
              <vs-th> Valor com Bolsa</vs-th>
              <vs-th> Vencimento </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <b>{{ data[indextr].PARCELA }}</b>
                </vs-td>

                <vs-td>
                  {{ formatValue(data[indextr]) }}
                </vs-td>

                <vs-td>
                  <b>{{ data[indextr].VENCIMENTO }}</b>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>

          <div class="date-change">
            <vs-button
              color="success"
              type="gradient"
              @click="confirm()"
              class="button-change"
            >
              <font-awesome-icon :icon="['fas', 'check']" />
              Confirmar
            </vs-button>

            <vs-button type="gradient" @click="viewSimulation = false">
              <font-awesome-icon :icon="['fas', 'times']" />
              Cancelar
            </vs-button>
          </div>
        </vs-popup>
      </section>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import Person from "../../services/person";
import ChangeDueDate from "../../services/payment/payment";

export default {
  name: "AlterarDataVencimento",
  data: () => ({
    invoices: [],
    name: null,
    error: null,
    contracts: [],
    personalData: [],
    selectedContract: null,
    contratcInvoices: [],
    nextValidDueDate: null,
    newDueDate: null,
    maxDate: null,
    errorNewDueDate: null,
    viewSimulation: false,
    simulationDates: [],
    result: "",
    alert: false,
    color: "success",
  }),

  mounted() {
    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];

        this.ra = this.personalData[0].RA;
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      });

    this.getInvoices(this.ra);
  },

  methods: {
    filterContract() {
      this.newDueDate = null;
      this.errorNewDueDate = null;
      this.nextValidDueDate = null;

      this.contratcInvoices = [];
      this.invoices.forEach((invoice) => {
        if (
          invoice.CODCONTRATO == this.selectedContract &&
          invoice.APTOTROCA == "1"
        ) {
          this.contratcInvoices.push(invoice);
          if (invoice.PARCELA == "1") invoice.APTOTROCA = "0";
          if (!this.nextValidDueDate && invoice.APTOTROCA == "1")
            this.nextValidDueDate = invoice;
        }
      });

      if (this.nextValidDueDate) {
        const baseDate = new Date(this.nextValidDueDate.DATAVENCIMENTO);
        if (this.nextValidDueDate.TRAVATROCAP == "1") {
          this.maxDate = new Date(this.nextValidDueDate.DATAVENCIMENTO);
          return;
        }
        if (baseDate.getMonth() == 12)
          this.maxDate = new Date(baseDate.getFullYear() + 1, 1, 10, 0, 0, 0);
        else
          this.maxDate = new Date(
            baseDate.getFullYear(),
            baseDate.getMonth() + 1,
            10,
            0,
            0,
            0
          );
      }
    },

    getMessage(invoice) {
      let message = "";
      if (invoice.ACORDO == "1") message = "Acordo financeiro";

      if (invoice.VENCIDOPARATROCA == "1")
        if (message == "") message = "Parcela vencida";
        else message = message + ", parcela vencida";
      if (invoice.BOLETOREGISTRADO == "1")
        if (message == "") message = "Boleto registrado";
        else message = message + ", boleto registrado";
      if (invoice.PARCELA == "1")
        if (message == "") message = "Primeira parcela bloqueada para troca";
        else message = message + ", primeira parcela bloqueada para troca";

      if (invoice.APTOTROCA == "1")
        if (message == "") message = "Parcela apta para troca de vencimento";
        else message = message + ", Parcela apta para troca de vencimento";
      if (invoice.TROCANODIA == "1")
        if (message == "")
          message = "Processo de alteração de vencimento já realizado hoje";
        else
          message =
            message + ", processo de alteração de vencimento já realizado hoje";
      if (invoice.TRAVATROCAP == "1" && invoice.APTOTROCA == "1")
        if (message == "")
          message = "Liberado para antecipação de vencimento somente";
        else
          message =
            message + ", liberado para antecipação de vencimento somente";
      //REVISAR AQUI
      return message;
    },

    formatValue(value) {
      const total =
        parseFloat(value.VALORORIGINAL) - parseFloat(value.VALORBOLSA);
      return "R$ " + total.toFixed(2);
    },

    checkNewDueDate() {
      /* precisa ser maior ou igual a hoje ou menor do que dia 10 do próximo mês*/
      this.errorNewDueDate = null;
      let dueDate = "";
      const minDate = new Date();
      if (this.newDueDate) {
        dueDate = this.newDueDate.toString() + " 00:00:00";
      } else {
        return;
      }
      dueDate = new Date(dueDate);
      const baseDate = new Date(this.nextValidDueDate.DATAVENCIMENTO);

      if (!(dueDate instanceof Date) || isNaN(dueDate))
        this.errorNewDueDate = "A data selecionada é inválida.";

      if (dueDate.getTime() == baseDate.getTime())
        this.errorNewDueDate =
          "A data precisa ser diferente da data de vencimento";

      if (dueDate < minDate)
        this.errorNewDueDate =
          "A data de vencimento precisa ser igual ou maior que a data de hoje.";

      const day = dueDate.getDate();
      if (day >= 29 && day <= 31)
        this.errorNewDueDate =
          "Não é possível escolher entre os dias 29 e 31 como opção de vencimento.";

      if (dueDate.getTime() > this.maxDate.getTime()) {
        if (this.nextValidDueDate.TRAVATROCAP == "1")
          this.errorNewDueDate =
            "Prorrogação de vencimento de parcela já realizada nos últimos 180 dias. Selecione uma data anterior a " +
            this.nextValidDueDate.VENCIMENTO;
        else
          this.errorNewDueDate =
            "Só é permitido prorrogar parcelas até o dia 10 do mês seguinte ao vencimento";
      }

      if (!this.errorNewDueDate) {
        //se data válida, prossegue
        let firstDate = null;
        let anterior = null;
        this.simulationDates = JSON.parse(
          JSON.stringify(this.contratcInvoices)
        );
        this.simulationDates.forEach((invoice) => {
          if (invoice.APTOTROCA != "0")
            if (!firstDate) {
              invoice.DATAVENCIMENTO = dueDate;
              invoice.VENCIMENTO =
                dueDate.getDate().toString() +
                "/" +
                (dueDate.getMonth() + 1).toString() +
                "/" +
                dueDate.getFullYear().toString();
              anterior = invoice.DATAVENCIMENTO;
              firstDate = true;
            } else {
              let day = anterior.getDate();
              let month =
                anterior.getMonth() == 11 ? 0 : anterior.getMonth() + 1;
              let year =
                anterior.getMonth() == 11
                  ? anterior.getFullYear() + 1
                  : anterior.getFullYear();

              invoice.DATAVENCIMENTO = new Date(year, month, day, 0, 0, 0);
              invoice.VENCIMENTO = day + "/" + (month + 1) + "/" + year;
              anterior = invoice.DATAVENCIMENTO;
            }
        });
        this.viewSimulation = true;
      }
    },

    async confirm() {
      let data = [];
      this.simulationDates.forEach((invoice) => {
        if (invoice.APTOTROCA == "1") {
          let date = new Date(invoice.DATAVENCIMENTO);
          let month = date.getMonth() + 1;
          let day = date.getDate();
          let formatDate =
            date.getFullYear() +
            "-" +
            (month < 10 ? "0" + month : month) +
            "-" +
            (day < 10 ? "0" + day : day);
          let obj = {
            colligateId: invoice.CODCOLIGADA,
            lanId: invoice.IDLAN,
            entryId: invoice.IDPARCELA,
            contractId: invoice.CODCONTRATO,
            newDueDate: formatDate,
            ra: invoice.RA,
          };
          data.push(obj);
        }
      });

      this.$vs.loading({ text: "Alterando as Datas de Vencimento 📆" });
      this.result = "";
      this.alert = false;
      await new ChangeDueDate()
        .updateDueDate(data)
        .then(({ data }) => {
          this.result = "Alteração de datas realizada com sucesso!";
          console.log(data);
          this.color = "success";
        })
        .catch(({ response }) => {
          this.result =
            "Houve um erro na tentativa de atualizar a data de vencimento. Por favor, procure a secretaria da sua Unidade/Polo QI.";
          console.log(response.data.error);
          this.color = "danger";
        })
        .finally(() => {
          this.$vs.loading.close();
        });

      this.nextValidDueDate = null;
      await this.getInvoices(this.ra);
      this.filterContract();
      this.viewSimulation = false;
      this.alert = true;
    },

    async getInvoices(ra) {
      this.invoices = [];
      this.$vs.loading({ text: "Procurando Contrato 🧾" });

      await new ChangeDueDate()
        .getInvoices(ra)
        .then(({ data }) => {
          this.invoices = Array.isArray(data) ? data : [data];
          const unico = (arr) =>
            Array.from(
              arr
                .reduce(
                  (map, each) => map.set(each.CODCONTRATO, each),
                  new Map()
                )
                .values()
            );
          this.contracts = unico(this.invoices);
        })
        .catch(({ error }) => {
          this.error = error;
        })
        .finally(() => {
          this.$vs.loading.close();
        });
      this.contratcInvoices = [];
    },
  },
};
</script>

<style scoped>
.vs-table td {
  border: 0 !important;
}

.vs-table th {
  border: 0 !important;
}

.card-sup {
  margin-left: 8.5px;
  margin-right: 8.5px;
  margin-bottom: 10px;
}

.simulation {
  margin-top: 0;
}

.orientation {
  color: #555555;
  text-align: justify;
}

.input-date {
  margin: auto;
  margin-top: 2.5%;
  margin-bottom: 1.5%;
  width: 100%;
}

.button-change {
  margin-top: 2.5%;
  margin-right: 5%;
}

.error {
  font-weight: bold;
  color: red;
  font-size: 0.85em;
}

.con-select {
  width: 18.5em;
  margin-bottom: 1.25%;
}

.button-simulate {
  margin-top: 1.75%;
  margin-bottom: 2.5%;
  width: 100%;
}

.date-simulate,
.date-change {
  text-align: center;
}

@media screen and (max-width: 900px) {
  .orientation {
    margin: 2.75%;
  }

  .simulation {
    margin: 0;
    padding: 1.25%;
  }

  .input-date {
    width: 80%;
  }

  .button-simulate {
    width: 60%;
  }
}

@media screen and (max-width: 690px) {
  .orientation {
    font-size: 0.915em;
  }
}
</style>