<template>
  <div>
    <Navbar />
    <div class="columns">
      <SideBar class="menu" />
      <section class="container">
        <Header class="header" />
        <div class="card-sup">
          <vs-col>
            <div class="card-info">
              <h3>
                <font-awesome-icon
                  style="margin-right: 1.5px"
                  :icon="['fas', 'user-cog']"
                />
                Perfil
              </h3>
              <vs-divider />
              <div v-if="personalData[0]">
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    margin-bottom: 10px;
                  "
                >
                  <a
                    style="
                      border: 2.15px solid;
                      border-radius: 7.5px;
                      padding: 6.15px;
                    "
                    @click="passwordPopup()"
                  >
                    <font-awesome-icon
                      style="margin-right: 3.5px"
                      :icon="['fas', 'lock']"
                    />
                    Trocar Senha
                  </a>
                </div>
                <vs-row>
                  <vs-col class="table_intranet">
                    <vs-table :data="personalData" hoverFlat>
                      <template slot-scope="{ data }">
                        <tbody v-for="(i, index) in data" :key="index.id">
                          <vs-tr>
                            <vs-td class="title-data">RA</vs-td>
                            <vs-td class="information-data">{{ i.RA }}</vs-td>
                          </vs-tr>
                          <vs-tr>
                            <vs-td class="title-data">Nome</vs-td>
                            <vs-td class="information-data">{{ i.NOME }}</vs-td>
                          </vs-tr>
                          <vs-tr>
                            <vs-td class="title-data">Data de Nascimento</vs-td>
                            <vs-td class="information-data">{{
                              i.DTNASCIMENTO
                            }}</vs-td>
                          </vs-tr>
                          <vs-tr>
                            <vs-td class="title-data">Naturalidade</vs-td>
                            <vs-td class="information-data">{{
                              i.NATURALIDADE
                            }}</vs-td>
                          </vs-tr>
                          <vs-tr>
                            <vs-td class="title-data">Endereço</vs-td>
                            <vs-td class="information-data">{{
                              i.RUA == null || i.NUMERO == null
                                ? "-"
                                : `${i.RUA}` +
                                  ", " +
                                  `${i.NUMERO}` +
                                  `${
                                    i.BAIRRO == null
                                      ? ""
                                      : " (" + i.BAIRRO + ") "
                                  }` +
                                  " - " +
                                  `${
                                    i.CIDADE == null && i.ESTADO == null
                                      ? "-"
                                      : i.CIDADE == null
                                      ? "- /" + `${i.ESTADO}`
                                      : i.ESTADO == null
                                      ? `${i.CIDADE}` + "/ -"
                                      : `${i.CIDADE}/${i.ESTADO}`
                                  }`
                            }}</vs-td>
                          </vs-tr>
                          <vs-tr>
                            <vs-td class="title-data">País</vs-td>
                            <vs-td class="information-data">{{ i.PAIS }}</vs-td>
                          </vs-tr>
                          <vs-tr>
                            <vs-td class="title-data">Email</vs-td>
                            <vs-td class="information-data">{{
                              i.EMAIL
                            }}</vs-td>
                          </vs-tr>
                          <vs-tr>
                            <vs-td class="title-data">Telefone(s)</vs-td>
                            <vs-td class="information-data"
                              >{{
                                i.TELEFONE1 == null
                                  ? ""
                                  : `(${i.TELEFONE1.slice(
                                      0,
                                      2
                                    )}) ${i.TELEFONE1.slice(2, 12)}`
                              }}
                              -
                              {{
                                i.TELEFONE2 == null
                                  ? ""
                                  : `(${i.TELEFONE2.slice(
                                      0,
                                      2
                                    )}) ${i.TELEFONE2.slice(2, 12)}`
                              }}</vs-td
                            >
                          </vs-tr>
                          <vs-tr>
                            <vs-td class="title-data">Nome da Mãe</vs-td>
                            <vs-td class="information-data">{{
                              i.NOMEMAE ? i.NOMEMAE : "-"
                            }}</vs-td>
                          </vs-tr>
                          <vs-tr>
                            <vs-td class="title-data">Nome do Pai</vs-td>
                            <vs-td class="information-data">{{
                              i.NOMEPAI ? i.NOMEPAI : "-"
                            }}</vs-td>
                          </vs-tr>
                        </tbody>
                      </template>
                    </vs-table>
                  </vs-col>
                  <vs-col class="list-data">
                    <vs-list>
                      <vs-list-item title="RA" :subtitle="ra"></vs-list-item>
                      <vs-list-item
                        title="Nome"
                        :subtitle="name"
                      ></vs-list-item>
                      <vs-list-item
                        title="Data de Nascimento"
                        :subtitle="birthDate"
                      ></vs-list-item>
                      <vs-list-item
                        title="Naturalidade"
                        :subtitle="naturalness"
                      ></vs-list-item>
                      <vs-list-item
                        title="Endereço"
                        :subtitle="address"
                      ></vs-list-item>
                      <vs-list-item
                        title="País"
                        :subtitle="country"
                      ></vs-list-item>
                      <vs-list-item
                        title="E-mail"
                        :subtitle="email"
                      ></vs-list-item>
                      <vs-list-item
                        title="Telefone(s)"
                        :subtitle="phones"
                      ></vs-list-item>
                      <vs-list-item
                        title="Nome da Mãe"
                        :subtitle="momName"
                      ></vs-list-item>
                      <vs-list-item
                        title="Nome do Pai"
                        :subtitle="dadName"
                      ></vs-list-item>
                    </vs-list>
                  </vs-col>
                </vs-row>
              </div>

              <div v-else>
                <div class="error-info">
                  <b>
                    <font-awesome-icon
                      style="color: red; margin-right: 3.5px"
                      :icon="['fas', 'exclamation-triangle']"
                    />
                    Nenhuma informação encontrada
                  </b>
                </div>
              </div>

              <vs-popup title="Trocar Senha" :active.sync="popupPassword">
                <vs-card>
                  <div v-if="changePassword == false">
                    <div
                      style="color: black; width: 100%; text-align: center"
                      class="discipline discipline-info"
                    >
                      <b class="error-title">
                        <font-awesome-icon
                          style="margin-right: 1.5px"
                          :icon="['fas', 'exclamation-triangle']"
                        />
                        ATENÇÃO
                      </b>
                      <p class="error-alert">
                        Um email foi enviado com um token de autenticação.
                      </p>
                      <p
                        class="error-alert"
                        style="
                          border: 1px solid;
                          border-radius: 5px;
                          padding: 6.15px;
                          margin-bottom: 8.5px;
                        "
                      >
                        <font-awesome-icon
                          style="margin-right: 1.5px"
                          :icon="['fas', 'at']"
                        />
                        Email:
                        <b>{{
                          this.personalData[0] ? this.personalData[0].EMAIL : ""
                        }}</b>
                      </p>
                      <div style="display: flex; justify-content: space-around">
                        <a
                          class="button-token"
                          :style="isButtonToken ? 'color: gray' : 'color: black'"
                          @click="isButtonToken ? '' : resendEmail()"
                        >
                          <font-awesome-icon
                            style="margin-right: 3.5px"
                            :icon="['fas', 'sync']"
                          />
                          {{ isButtonToken ? formatTime() : "Reenviar Token" }}
                        </a>
                        <a
                          class="button-token"
                          href="https://api.whatsapp.com/send?phone=5551995413830&text=Olá, gostaria de troca meu email para acesso ao portal. %23TrocarSenhaPortal"
                          target="_blank"
                        >
                          <font-awesome-icon
                            style="margin-right: 1.5px"
                            :icon="['fas', 'reply-all']"
                          />
                          Trocar Email
                        </a>
                      </div>
                    </div>

                    <div
                      v-if="newToken"
                      style="
                        color: black;
                        margin-top: 10px;
                        margin-bottom: 0 !important;
                        width: 100%;
                        text-align: center;
                      "
                      class="sucess sucess-info"
                    >
                      <b class="error-title">
                        <font-awesome-icon
                          style="color: green; margin-right: 1.5px"
                          :icon="['fas', 'key']"
                        />
                        TOKEN GERADO!
                      </b>
                    </div>

                    <vs-divider />

                    <div style="display: flex">
                      <b style="margin-right: 5px">Token de Autenticação</b>
                      <vs-tooltip
                        text="Token de Autenticação (6 dígitos)"
                        position="right"
                      >
                        <font-awesome-icon
                          style="color: #9b0e1c"
                          :icon="['fas', 'question-circle']"
                        />
                      </vs-tooltip>
                    </div>
                    <vs-col class="login-input">
                      <span class="icon-password">
                        <font-awesome-icon
                          v-if="temporaryToken == 'password'"
                          :icon="['fas', 'lock']"
                        />
                        <font-awesome-icon
                          v-else
                          :icon="['fas', 'unlock-alt']"
                        />
                      </span>
                      <vs-input
                        class="password"
                        v-model="temporaryPassword"
                        :type="temporaryToken"
                        placeholder="Digite o token de autenticação"
                      />

                      <a
                        v-if="temporaryToken == 'password'"
                        class="show-password"
                        @click="showToken()"
                      >
                        <font-awesome-icon :icon="['fas', 'eye']" />
                      </a>

                      <a v-else class="show-password" @click="showToken()">
                        <font-awesome-icon :icon="['fas', 'eye-slash']" />
                      </a>
                    </vs-col>

                    <div
                      v-if="errorTemporary"
                      style="
                        color: black;
                        margin-top: 43.5px;
                        margin-bottom: 0 !important;
                        width: 100%;
                        text-align: center;
                      "
                      class="error-info"
                    >
                      <b class="error-title">
                        <font-awesome-icon
                          style="color: red; margin-right: 1.5px"
                          :icon="['fas', 'times-circle']"
                        />
                        {{ errorTemporary }}
                      </b>
                      <p style="margin-top: 5px; font-size: 0.795em">
                        Verifique se não há um token mais recente no seu email.
                      </p>
                    </div>

                    <div
                      v-if="successValidate"
                      style="
                        color: black;
                        margin-top: 43.5px;
                        margin-bottom: 0 !important;
                        width: 100%;
                        text-align: center;
                      "
                      class="sucess sucess-info"
                    >
                      <b class="error-title">
                        <font-awesome-icon
                          style="color: green; margin-right: 1.5px"
                          :icon="['fas', 'check-circle']"
                        />
                        Token Validado!
                      </b>
                    </div>

                    <div style="width: 100%">
                      <vs-button
                        class="button-login"
                        :disabled="
                          !temporaryPassword || temporaryPassword.length != 6
                        "
                        @click="validateToken(temporaryPassword)"
                      >
                        <font-awesome-icon
                          style="margin-right: 3.5px"
                          :icon="['fas', 'check-circle']"
                        />
                        Validar
                      </vs-button>
                    </div>
                  </div>
                  <div v-if="changePassword">
                    <div style="display: flex">
                      <b style="margin-right: 5px">Nova Senha</b>
                      <vs-tooltip text="Digite sua nova senha" position="right">
                        <font-awesome-icon
                          style="color: #9b0e1c"
                          :icon="['fas', 'question-circle']"
                        />
                      </vs-tooltip>
                    </div>

                    <vs-col class="login-input" style="margin-bottom: 10px">
                      <span class="icon-password">
                        <font-awesome-icon
                          v-if="newPasswordType == 'password'"
                          :icon="['fas', 'lock']"
                        />
                        <font-awesome-icon
                          v-else
                          :icon="['fas', 'unlock-alt']"
                        />
                      </span>
                      <vs-input
                        class="password"
                        v-model="newPassword"
                        :type="newPasswordType"
                        placeholder="Digite sua nova senha"
                      />

                      <a
                        v-if="newPasswordType == 'password'"
                        class="show-password"
                        @click="showNewPassword()"
                      >
                        <font-awesome-icon :icon="['fas', 'eye']" />
                      </a>

                      <a
                        v-else
                        class="show-password"
                        @click="showNewPassword()"
                      >
                        <font-awesome-icon :icon="['fas', 'eye-slash']" />
                      </a>
                    </vs-col>

                    <vs-divider style="margin: 5px; width: 0 !important" />

                    <div style="display: flex">
                      <b style="margin-right: 5px">Confirme a Nova Senha</b>
                      <vs-tooltip text="Confirme a nova senha" position="right">
                        <font-awesome-icon
                          style="color: #9b0e1c"
                          :icon="['fas', 'question-circle']"
                        />
                      </vs-tooltip>
                    </div>

                    <vs-col class="login-input">
                      <span class="icon-password">
                        <font-awesome-icon
                          v-if="confirmNewPasswordType == 'password'"
                          :icon="['fas', 'lock']"
                        />
                        <font-awesome-icon
                          v-else
                          :icon="['fas', 'unlock-alt']"
                        />
                      </span>
                      <vs-input
                        class="password"
                        v-model="confirmNewPassword"
                        :type="confirmNewPasswordType"
                        placeholder="Confirme a nova senha"
                      />

                      <a
                        v-if="confirmNewPasswordType == 'password'"
                        class="show-password"
                        @click="showConfirmNewPassword()"
                      >
                        <font-awesome-icon :icon="['fas', 'eye']" />
                      </a>

                      <a
                        v-else
                        class="show-password"
                        @click="showConfirmNewPassword()"
                      >
                        <font-awesome-icon :icon="['fas', 'eye-slash']" />
                      </a>
                    </vs-col>

                    <vs-col class="security-password">
                      <fieldset>
                        <p class="password-criterion">
                          A senha deve seguir os critérios abaixo:
                        </p>
                        <p :class="formateAmount()">
                          ° Sua senha deve conter no mínimo 6 caracteres.
                        </p>
                        <p v-if="formateEqualityPassword()" class="reprovado">
                          ° A nova senha deve ser diferente do token.
                        </p>
                        <p :class="formateEquality()">
                          ° A nova senha e a confirmação devem ser iguais.
                        </p>
                      </fieldset>
                    </vs-col>

                    <div v-if="errorPassword">
                      <vs-divider style="width: 0" />
                      <div
                        style="
                          color: black;
                          margin-bottom: 0 !important;
                          width: 100%;
                          text-align: center;
                        "
                        class="error-info"
                      >
                        <b class="error-title">
                          <font-awesome-icon
                            style="color: red; margin-right: 1.5px"
                            :icon="['fas', 'times-circle']"
                          />
                          {{ errorPassword }}
                        </b>
                      </div>
                    </div>

                    <div v-if="passwordNew">
                      <vs-divider style="width: 0" />
                      <div
                        style="
                          color: black;
                          margin-bottom: 0 !important;
                          width: 100%;
                          text-align: center;
                        "
                        class="sucess sucess-info"
                      >
                        <b class="error-title">
                          <font-awesome-icon
                            style="color: green; margin-right: 3.5px"
                            :icon="['fas', 'check-circle']"
                          />
                          Senha Alterada!
                        </b>
                      </div>
                    </div>

                    <div style="width: 100%">
                      <vs-button
                        class="button-login"
                        :disabled="
                          formateAmount() == 'reprovado' ||
                          formateEqualityPassword() ||
                          formateEquality() == 'reprovado'
                        "
                        @click="passwordChange()"
                      >
                        <font-awesome-icon
                          style="margin-right: 3.5px"
                          :icon="['fas', 'check-circle']"
                        />
                        Confirmar Alteração de Senha
                      </vs-button>
                    </div>
                  </div>
                </vs-card>
              </vs-popup>
            </div>
          </vs-col>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import {
  getLoginDataFromStorage,
  logoffUserAndEraseData,
  isLogged as isLoggedHelper,
} from "../helpers/storage";
import LoginService from "../services/auth";
import Access from "../services/access";
import Email from "../services/email";
import Person from "../services/person";
export default {
  name: "MeuPerfil",
  data: () => ({
    personalData: [],
    error: null,
    popupPassword: false,
    errorPassword: null,
    newToken: false,
    newPassword: null,
    changePassword: false,
    confirmNewPassword: null,
    newPasswordType: "password",
    confirmNewPasswordType: "password",
    temporaryToken: "password",
    errorTemporary: null,
    ra: null,
    name: null,
    birthDate: null,
    isButtonToken: false,
    timer: 0,
    passwordNew: false,
    naturalness: null,
    randomPassword: null,
    temporaryPassword: null,
    address: null,
    complement: null,
    district: null,
    cityState: null,
    successValidate: false,
    country: null,
    email: null,
    phones: null,
    momName: null,
    dadName: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando Dados Acadêmicos 🪪" });
    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];

        this.momName =
          this.personalData[0].NOMEMAE == null
            ? "-"
            : this.personalData[0].NOMEMAE;
        this.dadName =
          this.personalData[0].NOMEPAI == null
            ? "-"
            : this.personalData[0].NOMEPAI;
        this.ra =
          this.personalData[0].RA == null ? "-" : this.personalData[0].RA;
        this.name =
          this.personalData[0].NOME == null ? "-" : this.personalData[0].NOME;
        this.birthDate =
          this.personalData[0].DTNASCIMENTO == null
            ? "-"
            : this.personalData[0].DTNASCIMENTO;
        this.naturalness =
          this.personalData[0].NATURALIDADE == null
            ? "-"
            : this.personalData[0].NATURALIDADE;
        this.address =
          this.personalData[0].RUA == null ||
          this.personalData[0].NUMERO == null
            ? "-"
            : `${this.personalData[0].RUA}` +
              ", " +
              `${this.personalData[0].NUMERO}` +
              `${
                this.personalData[0].BAIRRO == null
                  ? ""
                  : " (" + this.personalData[0].BAIRRO + ") "
              }` +
              " - " +
              `${
                this.personalData[0].CIDADE == null &&
                this.personalData[0].ESTADO == null
                  ? "-"
                  : this.personalData[0].CIDADE == null
                  ? "- /" + `${this.personalData[0].ESTADO}`
                  : this.personalData[0].ESTADO == null
                  ? `${this.personalData[0].CIDADE}` + "/ -"
                  : `${this.personalData[0].CIDADE}/${this.personalData[0].ESTADO}`
              }`;
        this.country =
          this.personalData[0].PAIS == null ? "-" : this.personalData[0].PAIS;
        this.email =
          this.personalData[0].EMAIL == null ? "-" : this.personalData[0].EMAIL;
        this.phones =
          this.personalData[0].TELEFONE1 == null &&
          this.personalData[0].TELEFONE2 == null
            ? "-"
            : this.personalData[0].TELEFONE1 == null
            ? `(${this.personalData[0].TELEFONE2.slice(
                0,
                2
              )}) ${this.personalData[0].TELEFONE2.slice(2, 12)}`
            : this.personalData[0].TELEFONE2 == null
            ? `(${this.personalData[0].TELEFONE1.slice(
                0,
                2
              )}) ${this.personalData[0].TELEFONE1.slice(2, 12)}`
            : `(${this.personalData[0].TELEFONE1.slice(
                0,
                2
              )}) ${this.personalData[0].TELEFONE1.slice(
                2,
                12
              )} - (${this.personalData[0].TELEFONE2.slice(
                0,
                2
              )}) ${this.personalData[0].TELEFONE2.slice(2, 12)}`;
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  computed: {
    isPassword() {
      return this.passwordType == "password";
    },
    isNewPasswordType() {
      return this.newPasswordType == "password";
    },
    isConfirmNewPassword() {
      return this.confirmNewPasswordType == "password";
    },
    isConfirmToken() {
      return this.temporaryToken == "password";
    },
  },

  methods: {
    passwordPopup() {
      this.changePassword = false;

      this.$vs.loading({ text: "Verificando Usuário 🎓" });
      this.nome = this.personalData[0].NOME;
      this.email = this.personalData[0].EMAIL;

      new Email()
        .temporaryPassword({
          name: this.personalData[0].NOME,
          email: this.personalData[0].EMAIL,
        })
        .then((res) => {
          this.temporaryPassword = null;
          this.errorTemporary = null;
          this.popupPassword = true;
          this.randomPassword = this.decryptInfo(res.data.token);

          new Access()
            .access({
              codPessoa: Number(this.personalData[0].CODIGO),
              token: this.randomPassword,
            })
            .then(() => {
              this.$vs.loading.close();
            })
            .catch((error) => {
              const { response } = error;
              this.error =
                response?.data?.error || "Tente novamente mais tarde.";
              this.$vs.loading.close();
            });
        })
        .catch((error) => {
          const { response } = error;
          this.error = response?.data?.error || "Tente novamente mais tarde.";
          this.$vs.loading.close();
        });
    },

    decryptInfo(token) {
      const secretKey = "QI-System-@@";
      const simpleCrypto = new SimpleCrypto(secretKey);
      const returnToken = simpleCrypto.decrypt(token);
      return returnToken;
    },

    resendEmail() {
      this.newToken = false;
      this.$vs.loading({ text: "Reenviando Token 📩" });
      new Email()
        .temporaryPassword({
          name: this.personalData[0].NOME,
          email: this.personalData[0].EMAIL,
        })
        .then((res) => {
          this.randomPassword = this.decryptInfo(res.data.token);

          new Access()
            .access({
              codPessoa: Number(this.personalData[0].CODIGO),
              token: this.randomPassword,
            })
            .then(() => {
              this.newToken = true;
              setTimeout(() => {
                this.newToken = false;
              }, 850);
              this.$vs.loading.close();

              this.isButtonToken = true;
              this.timer = 30;

              const interval = setInterval(() => {
                this.timer--;
                if (this.timer <= 0) {
                  clearInterval(interval);
                  this.isButtonToken = false;
                }
              }, 1000);
            })
            .catch((error) => {
              const { response } = error;
              this.error =
                response?.data?.error || "Tente novamente mais tarde.";
              this.$vs.loading.close();
            });
        })
        .catch((error) => {
          const { response } = error;
          this.error = response?.data?.error || "Tente novamente mais tarde.";
          this.$vs.loading.close();
        });
    },

    formatTime() {
      const seconds = this.timer.toString().padStart(2, "0");
      return `00:${seconds}`;
    },

    validateToken(tempPassword) {
      this.errorTemporary = null;
      this.newPassword = null;
      this.confirmNewPassword = null;

      this.$vs.loading({ text: "Validando Token de Autenticação 🔒" });

      setTimeout(() => {
        this.$vs.loading.close();
        if (tempPassword == this.randomPassword) {
          this.successValidate = true;
          setTimeout(() => {
            this.changePassword = true;
            this.successValidate = false;
          }, 1100);
        } else {
          this.errorTemporary = "Token incorreto!";
        }
      }, 1500);
    },

    passwordChange() {
      const { ...user } = getLoginDataFromStorage();

      this.$vs.loading({ text: "Alterando Senha 🔐" });
      new LoginService()
        .accessPassword({
          codUser: user.coduser,
          newPassword: this.newPassword,
          newPasswordConfirmation: this.confirmNewPassword,
          changePasswordToken: this.randomPassword,
        })
        .then(() => {
          this.passwordNew = true;

          setTimeout(() => {
            this.$vs.loading({ text: "Deslogando do Portal 💻" });
            setTimeout(() => {
              this.$vs.loading.close();
              setTimeout(() => {
                logoffUserAndEraseData();
                this.isLogged = isLoggedHelper();
                this.steps = [];
                this.$router.push("/");
              }, 500);
            }, 1500);
          }, 850);
        })
        .catch((error) => {
          const { response } = error;
          this.errorPassword =
            response?.data?.error || "Erro ao trocar de senha.";
          this.$vs.loading.close();
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    resetPopupAccess() {
      this.popupPassword = false;
    },

    showPassword() {
      this.passwordType = this.isPassword ? "text" : "password";
    },

    showToken() {
      this.temporaryToken = this.isConfirmToken ? "text" : "password";
    },

    showNewPassword() {
      this.newPasswordType = this.isNewPasswordType ? "text" : "password";
    },

    showConfirmNewPassword() {
      this.confirmNewPasswordType = this.isConfirmNewPassword
        ? "text"
        : "password";
    },

    formateAmount() {
      if (this.newPassword && this.confirmNewPassword) {
        if (
          this.newPassword.length >= 6 &&
          this.confirmNewPassword.length >= 6
        ) {
          return "aprovado";
        } else {
          return "reprovado";
        }
      } else {
        return "reprovado";
      }
    },

    formateEqualityPassword() {
      if (this.newPassword) {
        if (this.temporaryPassword == this.newPassword) {
          return true;
        } else {
          return false;
        }
      }
    },

    formateEquality() {
      if (this.newPassword && this.confirmNewPassword) {
        if (this.newPassword == this.confirmNewPassword) {
          return "aprovado";
        } else {
          return "reprovado";
        }
      } else {
        return "reprovado";
      }
    },
  },
};
</script>
    
<style scoped>
td {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

legend {
  font-size: 1.05em;
}

tr:nth-child(even) {
  background-color: #d4d4d4;
}

.list-data {
  display: none;
}

.button-token {
  color: black;
  width: 45%;
  border: 2.15px solid;
  border-radius: 7.5px;
  padding: 6.15px;
}

.error-title {
  font-size: 0.985em;
}

.error-info,
.sucess-info {
  font-size: 13px;
}

.error-alert {
  margin-top: 5px;
  font-size: 1em;
}

.title-data,
.information-data {
  font-size: 1.2em;
  padding: 7.5px;
}

.title-data {
  font-weight: bold;
}

.information-data {
  align-items: justify;
  text-align: right;
}

.card-sup {
  margin-left: 8.5px;
  margin-right: 8.5px;
  margin-bottom: 8.5px;
}

.login-input {
  display: flex;
}

.label-password {
  margin-top: 2.75%;
}

.login-input a {
  margin: auto;
}

.security-password {
  margin-top: 18.5px;
}

.security-password p:nth-child(2) {
  margin-top: 1.25%;
}

.button-login {
  background: #571515;
  border-radius: 20px;
  margin: 3.5% 2.5% auto 2.5%;
  width: 95%;
}

.aprovado,
.reprovado {
  font-weight: bold;
}

.password {
  width: 100%;
}

.password-criterion {
  font-size: 1.15em;
}

.aprovado {
  color: green;
}

.reprovado {
  color: red;
}

.icon-password {
  margin: auto;
  padding: 7.5px 8.5px 7.15px 8.5px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.show-password {
  padding: 7.5px 8.5px 7.15px 8.5px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #571515;
}

@media screen and (max-width: 950px) {
  .table_intranet {
    display: none;
  }

  .list-data {
    display: flex;
  }
}

@media screen and (max-width: 410px) {
  .button-token {
    width: auto !important;
  }

  .security-password p:nth-child(1) {
    font-size: 0.95em;
  }

  .security-password p:nth-child(2),
  .security-password p:nth-child(3),
  .security-password p:nth-child(4) {
    font-size: 0.885em;
  }
}

@media screen and (max-width: 370px) {
  .security-password p:nth-child(1) {
    font-size: 0.885em;
  }

  .security-password p:nth-child(2),
  .security-password p:nth-child(3),
  .security-password p:nth-child(4) {
    font-size: 0.75em;
  }
}
</style>