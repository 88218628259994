import { https } from './apiPortal'

export default class {
  constructor() {
    this.instance = https('auth')
  }

  studentLogin = (data) => {
    return this.instance({
      method: 'POST',
      url: '/studentLogin',
      data,
    })
  }

  accessPassword = (data) => {
    return this.instance({
      method: 'POST',
      url: '/resetPassword',
      data,
    })
  }
}
